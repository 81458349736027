import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const CCIcon: React.FunctionComponent<IIcon> = ({
  width = 20,
  height = 18,
  fill = '#fff',
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0H2.25C1.00125 0 0 1.0125 0 2.25V15.75C0 16.9875 1.00125 18 2.25 18H18C19.2375 18 20.25 16.9875 20.25 15.75V2.25C20.25 1.0125 19.2375 0 18 0ZM8.99909 7.87508H7.31159V7.31258H5.06159V10.6876H7.31159V10.1251H8.99909V11.2501C8.99909 11.8688 8.49284 12.3751 7.87409 12.3751H4.49909C3.88034 12.3751 3.37409 11.8688 3.37409 11.2501V6.75008C3.37409 6.13133 3.88034 5.62508 4.49909 5.62508H7.87409C8.49284 5.62508 8.99909 6.13133 8.99909 6.75008V7.87508ZM15.1881 7.87508H16.8756V6.75008C16.8756 6.13133 16.3694 5.62508 15.7506 5.62508H12.3756C11.7569 5.62508 11.2506 6.13133 11.2506 6.75008V11.2501C11.2506 11.8688 11.7569 12.3751 12.3756 12.3751H15.7506C16.3694 12.3751 16.8756 11.8688 16.8756 11.2501V10.1251H15.1881V10.6876H12.9381V7.31258H15.1881V7.87508Z"
      fill={fill}
    />
  </svg>
);

export default React.memo(CCIcon);
