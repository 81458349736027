/* eslint-disable no-restricted-syntax, no-param-reassign */
import clsx from 'clsx';
/**
 * Generates BEM structure based on props.
 * @param  {string} str Base class.
 * @param  {Object<string, any>} props Component props.
 * @return {string} BEM class string.
 */
export const createBemClass = (str, props) => {
  const originalStr = str;

  for (const key in props) {
    if (props[key] === true) {
      str += ` ${originalStr}--${key}`;
    } else if (key === 'className') {
      str += ` ${props[key]}`;
    }
  }

  return str;
};

/**
 * Create all required BEM classes for a block element modifier combination.
 * @deprecated Use descriptive names together with CSS modules.
 *
 * @example
 * bem('Widget') // returns 'Widget',
 * bem('Widget', null, 'red') // returns 'Widget--red',
 * bem('Widget', 'msg') // returns 'Widget__msg',
 * bem('Widget', 'msg', 'red') // returns 'Widget__msg Widget__msg--red'
 */
export const bem = (
  block: string,
  element?: string,
  modifier?: string,
): string =>
  clsx({
    [`${block}`]: !element && !modifier,
    [`${block}__${element}`]: element,
    [`${block}--${modifier}`]: !element && modifier,
    [`${block}__${element}--${modifier}`]: element && modifier,
  });
