import {
  SET_CHANNEL_ITEM_HOVER_INDEX,
  ISetChannelItemHoverIndexAction,
} from './types';

export const setChannelItemHoverIndex = (
  currentChannelItemHoverIndex: string,
): ISetChannelItemHoverIndexAction => {
  return {
    type: SET_CHANNEL_ITEM_HOVER_INDEX,
    payload: currentChannelItemHoverIndex,
  };
};
