import { DEFAULT_MARKET_DATA } from '##/utils/constants';
import {
  getJsonValueFromLocalStorage,
  setJsonValueInLocalStorage,
} from '##/utils/storage';
import { IMarket } from '##/interfaces/IMarket';
import { logError } from '##/utils/trackJs';

export const MARKET_DATA_KEY = 'marketData';

const {
  endpoints: {
    geolocation_ip: IP_ENDPOINT,
    geolocation_latLong: COORDS_ENDPOINT,
  },
  marketIdExpirationSeconds: MARKET_ID_EXPIRATION,
} = window.swm.config;

const LAT_LONG_PRECISION = 4;
const GEO_SOURCE_KEY = 'geolocation';
const IP_SOURCE_KEY = 'ip';

const interpolateCoordsInEndpoint = ({ latitude, longitude }) =>
  COORDS_ENDPOINT.replace(
    '{latitude}',
    latitude.toFixed(LAT_LONG_PRECISION),
  ).replace('{longitude}', longitude.toFixed(LAT_LONG_PRECISION));

const getCurrentPosition = (options = undefined): Promise<Position> =>
  new Promise((resolve, reject) => {
    window.navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });

const saveMarketData = (data: IMarket) => {
  //Setting the expiry time of market data
  data.timestamp = new Date().getTime() + MARKET_ID_EXPIRATION * 1000;

  setJsonValueInLocalStorage(MARKET_DATA_KEY, data);
};

const isMarketData = (obj: any): obj is IMarket =>
  obj && obj.timestamp > Date.now();

export const loadMarketData = () => {
  const marketData = getJsonValueFromLocalStorage(MARKET_DATA_KEY);

  if (isMarketData(marketData)) {
    return marketData;
  }
};

export const fetchMarketData = async (endpoint, source) => {
  const response = await fetch(endpoint, {
    method: 'GET',
  });

  if (response.status === 404) {
    saveMarketData(DEFAULT_MARKET_DATA);

    return DEFAULT_MARKET_DATA;
  }

  const data = await response.json();
  const marketData = { ...data, source };

  saveMarketData(marketData);

  return marketData;
};

export const fetchMarketByIp = () =>
  fetchMarketData(IP_ENDPOINT, IP_SOURCE_KEY);

export const fetchMarketByGeo = async () => {
  const { coords } = await getCurrentPosition();
  const endpoint = interpolateCoordsInEndpoint(coords);

  return fetchMarketData(endpoint, GEO_SOURCE_KEY);
};

export const getMarketData = () =>
  new Promise((resolve) => {
    const marketData = loadMarketData();

    if (marketData && marketData.timestamp) {
      return resolve(marketData);
    }

    return fetchMarketByIp()
      .then(resolve)
      .catch((error) => {
        logError('Failed to get market data', error);
        resolve(DEFAULT_MARKET_DATA);
      });
  });
