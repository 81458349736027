import { loadScriptAsync } from '##/utils/scripts';
import { logError } from '##/utils/trackJs';
import getExtractionUrl from './helpers/getExtractionUrl';

const CLIENT_ID = '11006';
const LOTAME_PROFILE_KEY = 'Lotame';
const SCRIPT_ID = `LOTCC_${CLIENT_ID}`;
const DATA_COLLECTION_URL = `https://tags.crwdcntrl.net/c/${CLIENT_ID}/cc_ajax.js?ns=_cc${CLIENT_ID}`;
const AUDIENCE_EXTRACTION_URL = getExtractionUrl(
  CLIENT_ID,
  LOTAME_PROFILE_KEY,
  null,
);
const getLotameSdk = (clientId) => window[`_cc${clientId}`];

const replacePpidWithId = (url, profileId) => url.replace(/{ppId}/g, profileId);

export const getPpid = () => {
  const { Lotame } = window;

  if (Lotame && Lotame.Profile) {
    return Lotame.Profile.pid;
  }

  return null;
};

export const urlWithPpid = (url) => {
  const ppid = getPpid();

  if (ppid) {
    return replacePpidWithId(url, ppid);
  }

  return url;
};

const initSdk = (tpid) => {
  const lotame = getLotameSdk(CLIENT_ID);
  lotame.add('tp', 'SWMG');
  lotame.add('tpid', tpid);
  lotame.bcp();
};

export const init = (tpid = '') => {
  try {
    return loadScriptAsync({ src: DATA_COLLECTION_URL, id: SCRIPT_ID })
      .then(() => {
        initSdk(tpid);
        return loadScriptAsync({ src: AUDIENCE_EXTRACTION_URL });
      })
      .catch((error) => {
        logError(
          'lomate.init.loadScriptAsync: Failed to initialise Lotame',
          error,
        );
      });
  } catch (error) {
    logError('lomate.init: Failed to initialise Lotame', error);

    return Promise.resolve();
  }
};
