import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from './ButtonLink.pcss';

export const hasProtocol = (url) => /^(?:[a-z]+:)?\/\//.test(url);

interface IProps {
  ariaLabel?: string;
  background?:
    | 'blue'
    | 'green'
    | 'red-gradient'
    | 'white'
    | 'dt-red'
    | 'dt-grey'
    | 'transparent'
    | string;
  children: JSX.Element | string;
  disabled?: boolean;
  className?: string;
  compact?: boolean;
  onClick?(): void;
  url: string;
}

export const ButtonLink: React.FC<IProps> = ({
  ariaLabel = '',
  background = 'red-gradient',
  children,
  disabled = false,
  className,
  compact = false,
  onClick = () => {},
  url,
}) => {
  const linkClassName = clsx(styles.buttonLink, {
    [styles.buttonLinkWhite]: background === 'white',
    [styles.buttonLinkBlue]: background === 'blue',
    [styles.buttonLinkGreen]: background === 'green',
    [styles.buttonLinkRedGradient]: background === 'red-gradient',
    [styles.buttonLinkDtRed]: background === 'dt-red',
    [styles.buttonLinkDtGrey]: background === 'dt-grey',
    [styles.buttonLinkCompact]: compact,
    [`${className}`]: className,
  });

  if (hasProtocol(url) || url === '/') {
    return (
      <a
        aria-label={ariaLabel}
        className={linkClassName}
        href={disabled ? '' : url}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      aria-label={ariaLabel}
      className={linkClassName}
      to={url}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
