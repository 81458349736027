export const displayAdName = 'DISPLAY_AD';
export const REFRESH_DISPLAY_AD = 'DISPLAY_AD/REFRESH_DISPLAY_AD';

export interface IRefreshDisplayAdAction {
  type: typeof REFRESH_DISPLAY_AD;
  payload: boolean;
}
export interface IDisplayAdReducerState {
  refreshAd: boolean;
}
