import React from 'react';
import clsx from 'clsx';

import sectionStyles from '##/components/_Section.pcss';
import styles from './LiveSection.pcss';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export const LiveSection: React.FC<IProps> = ({ children }) => {
  return (
    <section
      className={clsx(
        styles.liveSection,
        sectionStyles.sectionPage,
        'globalLivePage',
      )}
    >
      {children}
    </section>
  );
};
