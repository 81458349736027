import SHA256 from 'crypto-js/sha256';

const aamTracker = {
  init: ():void => {
    window.aamDataLayer = {
      swmV2: null,
      ppid: null
    };
  },
  isIdSet: ():boolean => !!(window.aamDataLayer?.ppid),
  setId: (id: string):void => {
    if (!id || !window.aamDataLayer || !window._satellite || !window._satellite.track || !window.aamDataLayer) return;
    if (window.aamDataLayer.ppid && window.aamDataLayer.ppid.length === 64) return;
    window.aamDataLayer.ppid = SHA256(id).toString();
    window._satellite.track('idSync');
  },
  routeChange: ():void => {
    if (window._satellite?.track) {
      window._satellite.track('spa_link');
    }
  }
};

// Adobe Audience Manager
export default aamTracker;