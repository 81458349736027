import { GIGYA_SDK_ID } from '##/utils/auth/authManager';
import { loadDynamicScript } from '##/utils/loadDynamicScript';
import { logError } from '##/utils/trackJs';

import { ERROR_CODE_SUCCESS, ERROR_SDK_NOT_LOADED } from './constants';

// some calls return '0', others 0 eg getJWT vs socialLogin
const getErrorCode = (resp) => parseInt(resp.errorCode, 10);

const asPromise = (fn, params): Promise<any> =>
  new Promise((res, rej) => {
    const callback = (resp) => {
      const errorCode = getErrorCode(resp);
      const modifiedResp = { ...resp, errorCode };
      const doesEmailExist =
        resp.validationErrors &&
        resp.validationErrors.some((item) => item.errorCode === 400003);
      /* istanbul ignore next */
      if (errorCode === ERROR_CODE_SUCCESS) {
        res(modifiedResp);
      } else if (doesEmailExist) {
        const errorObj = {
          errorCode,
          validationErrors: resp.validationErrors,
          errorDetails: resp.errorDetails,
        };
        rej(errorObj);
      } else {
        rej(new Error(`Gigya error: ${resp.errorMessage} code: ${errorCode}`));
      }
    };

    const paramsWithCallback = { ...params, callback };
    fn(paramsWithCallback);
  });

const getGigyaMethod = async (name) => {
  let gigyaMethod = window.gigya ? window.gigya.accounts[name] : null;

  if (!window.gigya) {
    gigyaMethod = await new Promise((resolve) => {
      // loadDynamicScript loads Gigya script in if it hasn't been used yet
      loadDynamicScript(GIGYA_SDK_ID, window.swm.gigyaSdkUrl, () => {
        // setTimeout is used to wait for the gigya script to initialize
        // before actually logging the user out
        setTimeout(() => {
          resolve(window.gigya.accounts[name]);
        });
      });
    });
  }

  if (!gigyaMethod) {
    logError(
      `Failed to call Gigya accounts method! name: [${name}] SDK Loaded: [${!!window.gigya}]`,
    );

    gigyaMethod = ({ callback }) =>
      callback({ errorCode: ERROR_SDK_NOT_LOADED });
  }

  return gigyaMethod;
};

export const runGigyaMethod = async (name, params) => {
  const gigyaMethod = await getGigyaMethod(name);
  return asPromise(gigyaMethod, params);
};
