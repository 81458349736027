import { useEffect, useState } from 'react';

import {
  getDynamicDataSourceData,
  organizeDataSets,
} from '##/utils/dynamicDataSource';

export const useMultipleDynamicDataSources = (
  dataSet,
  getToken,
  propertyInDDSResponse,
  idPropertyName,
) => {
  const [items, setItems] = useState(
    organizeDataSets(dataSet, propertyInDDSResponse, idPropertyName),
  );

  let intervalArray = [];
  dataSet.forEach((item) => {
    if (item.source?.validFor) {
      intervalArray.push(item.source.validFor);
    }
  });

  // find shortest poll interval in dataset
  const shortestInterval = intervalArray.reduce((previous, current) => {
    return previous <= current ? previous : current;
  });

  const requestMultipleDynamicDataSources = async (multipleData, signal) => {
    let dynamicData = [];

    await Promise.all(
      multipleData.map(async (data) => {
        if (data.source?.url) {
          const result = await getDynamicDataSourceData(
            data.source,
            getToken,
            signal,
          );
          dynamicData.push(result);
        }
      }),
    );

    setItems(organizeDataSets(dynamicData, 'mediaItems', idPropertyName));
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    requestMultipleDynamicDataSources(dataSet, signal);

    if (shortestInterval && shortestInterval > 0) {
      const pollInterval = setInterval(() => {
        requestMultipleDynamicDataSources(dataSet, signal);
      }, shortestInterval * 1000);

      return () => {
        clearInterval(pollInterval);
        abortController.abort();
      };
    }

    return () => {
      abortController.abort();
    };
  }, []);

  return { data: items, setItems };
};
