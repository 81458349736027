import { connect } from 'react-redux';
import { userName } from '##/state/user/types';
import { BasicInformation } from './BasicInformation';

export const mapStateToProps = (state) => ({
  birthYear: state[userName].birthYear,
  email: state[userName].email,
  firstName: state[userName].firstName,
  gender: state[userName].gender,
  lastName: state[userName].lastName,
  postcode: state[userName].postcode,
});

export const BasicInfomationContainer = connect(
  mapStateToProps,
  null,
)(BasicInformation);
export default BasicInfomationContainer;
