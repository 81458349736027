import { Dispatch } from 'redux';
import { History } from 'history';

import { isOperating } from '##/state/registration/actions';
import { logout as utilsLogout } from '##/utils/gigya/login';
import {
  redirectWithLogoutQuery,
  redirectWithoutQueryStrings,
} from '##/utils/queries';
import { LOG_OUT } from '##/utils/route';
import { trackSignInRegisterEvent } from '##/utils/tracking/helpers';
import {
  removeLoginDataFromLocalStorage,
  removeJwtFromLocalStorage,
} from '##/utils/auth/authLocalStorage';

import {
  clearUserInfo,
  setError,
  setProfileIncomplete,
  setSignOutSuccess,
  setCloseAccountSuccess,
} from '../actions';
import { updateTrackerUser } from './user';

/**
 * Send UX event data to inferno analytics tracker
 */
const trackLogoutEvent = (provider) => () => {
  trackSignInRegisterEvent({
    action: 'signed out',
    descriptor: 'successful sign-out',
    type: 'sign-in',
    value: provider,
  });
};

/**
 * Reset setProfileIncomplete
 * removes data from local storage and clears the history object from react withRouter
 * updates redux with cleared data
 * clears the query string and removes overlay
 * @params {object} history react router history Object
 * @params {function} dispatch redux dispatch action
 */
export const logout = (
  history: History,
  dispatch: Dispatch,
  provider = 'Email',
  checkEmailToVerifyLogout = false,
  isCloseAccount = false,
) => {
  dispatch(isOperating(true));
  dispatch(setProfileIncomplete(false));

  return utilsLogout()
    .then(removeLoginDataFromLocalStorage)
    .then(updateTrackerUser)
    .then(() => {
      //first we should redirect, so alert will not shown before redirection to homepage is complete
      history.push('/');
      dispatch(clearUserInfo());
      if (isCloseAccount) {
        dispatch(setCloseAccountSuccess(true));
      }
      dispatch(setSignOutSuccess(true));
    })
    .then(trackLogoutEvent(provider))
    .catch((response) => dispatch(setError(response)))
    .finally(() => {
      removeJwtFromLocalStorage();
      /* istanbul ignore next */
      if (checkEmailToVerifyLogout) {
        redirectWithoutQueryStrings([LOG_OUT], history);
      } else {
        redirectWithLogoutQuery(history);
      }

      return dispatch(isOperating(false));
    });
};

/**
 * clearUsersLoggedInState
 * Clears the users state/data in redux and in local storage
 * without tracking and redirects
 */
export const clearUsersLoggedInState = (dispatch: Dispatch) => {
  return utilsLogout()
    .catch((response) => dispatch(setError(response)))
    .finally(() => {
      removeLoginDataFromLocalStorage();
      dispatch(clearUserInfo());
      removeJwtFromLocalStorage();
    });
};
