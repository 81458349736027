import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';

import { trimValue } from '##/utils/formValidation';
import { Button } from '##/components/Button';
import { FormInput } from '##/components/FormInput';
import { TextElement } from '##/components/TextElement';
import { Spinner } from '##/components/Spinner';
import { IProps } from './constants';
import { PASSWORD_INVALID_ERROR_MESSAGE } from '##/utils/formValidation';

import styles from './ChangePasswordForm.pcss';

const STATUS_CODE_SUCCESS = 'OK';

export const ChangePasswordForm: FC<IProps> = ({
  errors,
  touched,
  setFieldTouched,
  handleSubmit,
  handleChange,
  values,
  isSubmitting,
  cancelChangePasswordForm,
  statusCode,
  setStatusCode,
  errorCode,
  setErrorCode,
}) => {
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
  const [isFocus, setFocus] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const onFocus = (name, focus) => {
    setFocus((prevState) => ({
      ...prevState,
      [name]: focus,
    }));
    setFieldTouched(name, focus);
  };

  const onBlur = (name, blur) => {
    setFocus((prevState) => ({
      ...prevState,
      [name]: false,
    }));
    setFieldTouched(name, blur);
  };

  useEffect(() => {
    setStatusCode(null);
  }, []);

  useEffect(() => {
    if (errorCode === 400006 || errorCode === 403042) {
      setErrorCode({ errorCode: null });
    }
  }, [values.newPassword, values.oldPassword, values.confirmPassword]);

  useEffect(() => {
    if (statusCode === STATUS_CODE_SUCCESS && !isSubmitting) {
      cancelChangePasswordForm(true);
    }
  }, [isSubmitting, statusCode]);

  useEffect(() => {
    if (
      values.newPassword.length > 0 &&
      values.confirmPassword &&
      values.newPassword !== values.confirmPassword
    ) {
      setConfirmPasswordError('Password does not match');
    } else setConfirmPasswordError('');
  }, [values.newPassword, values.confirmPassword]);

  /* istanbul ignore next */
  const checkErrorValidation = (error, value, focus, touched) => {
    if (errorCode === 400006) {
      return 'New password cannot be the same as old password';
    }
    if (error && value.length > 0 && !focus) {
      return error;
    } else if (
      (touched && focus && value.length === 0) ||
      (touched && value.length === 0 && !focus) ||
      (touched && error && value.length > 0 && focus)
    ) {
      return PASSWORD_INVALID_ERROR_MESSAGE;
    }
    return '';
  };

  const getIsSubmitButtonDisabled = () => {
    return (
      values.oldPassword?.length === 0 ||
      values.newPassword?.length === 0 ||
      values.confirmPassword?.length === 0 ||
      errors.newPassword?.length > 0 ||
      errors.oldPassword?.length > 0 ||
      errors.confirmPassword?.length > 0 ||
      confirmPasswordError?.length > 0
    )
  };

  return (
    <div className={styles.changePasswordContainer}>
      <TextElement
        className={styles.title}
        textType="title"
        cssClasses={['body1', 'white', 'fw600']}
      >
        Change Password
      </TextElement>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        className={styles.formWrapper}
      >
        <FormInput
          label="Old password"
          name="oldPassword"
          type="password"
          id="oldPasswordFormInput"
          value={values.oldPassword}
          onChange={trimValue(handleChange)}
          onBlur={setFieldTouched}
          error={errorCode === 403042 && 'Incorrect password, try again'}
          touched={touched.oldPassword}
          className={
            errorCode === 403042
              ? styles.redErrorPasswordInput
              : styles.changePasswordInput
          }
          iconEye
          placeholderTranslation
        />
        <FormInput
          label="New password"
          name="newPassword"
          type="password"
          id="newPasswordFormInput"
          value={values.newPassword}
          onChange={trimValue(handleChange)}
          error={checkErrorValidation(
            errors.newPassword,
            values.newPassword,
            isFocus.newPassword,
            touched.newPassword,
          )}
          touched={touched.newPassword}
          onBlur={(name, blur) => onBlur(name, blur)}
          onFocus={(name, focus) => onFocus(name, focus)}
          className={clsx(styles.changePasswordInput, {
            [styles.redErrorPasswordInput]:
              errorCode === 400006 ||
              (errors.newPassword &&
                values.newPassword.length > 0 &&
                !isFocus.newPassword),
            [styles.hintTextInput]:
              (touched.newPassword &&
                isFocus.newPassword &&
                values.newPassword.length === 0) ||
              (touched.newPassword &&
                !isFocus.newPassword &&
                values.newPassword.length === 0) ||
              (touched.newPassword &&
                errors.newPassword &&
                values.newPassword.length > 0 &&
                isFocus.newPassword),
          })}
          iconEye
          placeholderTranslation
        />
        <FormInput
          label="Confirm password"
          name="confirmPassword"
          type="password"
          id="confirmPasswordFormInput"
          value={values.confirmPassword}
          onChange={trimValue(handleChange)}
          onBlur={(name, blur) => onBlur(name, blur)}
          onFocus={(name, focus) => onFocus(name, focus)}
          error={confirmPasswordError.length > 0 ? confirmPasswordError : ''}
          touched={touched.confirmPassword}
          className={clsx(styles.changePasswordInput, {
            [styles.redErrorPasswordInput]:
              confirmPasswordError.length > 0 &&
              values.confirmPassword.length > 0 &&
              !isFocus.confirmPassword,
            [styles.hintTextInput]:
              (touched.confirmPassword &&
                isFocus.confirmPassword &&
                values.confirmPassword.length === 0) ||
              (touched.confirmPassword &&
                !isFocus.confirmPassword &&
                values.confirmPassword.length === 0) ||
              (touched.confirmPassword &&
                confirmPasswordError.length > 0 &&
                values.confirmPassword.length > 0 &&
                isFocus.confirmPassword),
          })}
          iconEye
          placeholderTranslation
        />
        <div className={styles.btnWrapper}>
          <div className={styles.cancelButtonWrapper}>
            <Button
              className={styles.cancelButton}
              message="Cancel"
              onClick={() => cancelChangePasswordForm(true)}
              cssClasses={[
                'borderRadius1',
                'textRed',
                'biggerFontSize',
                'transparentBorder',
                'borderRadius1',
              ]}
            />
          </div>
          <div
            className={clsx(styles.submitWrapper, {
              [styles.submitingSubmitWrapper]: isSubmitting,
            })}
          >
            {isSubmitting ? (
              <Spinner />
            ) : (
              <Button
                type="submit"
                message="Update password"
                cssClasses={
                  getIsSubmitButtonDisabled()
                    ? [
                        'dtRed',
                        'opacity7',
                        'biggerFontSize',
                        'opacityBorder',
                        'borderRadius1',
                      ]
                    : [
                        'dtRed',
                        'biggerFontSize',
                        'transparentBorder',
                        'borderRadius1',
                      ]
                }
                disabled={getIsSubmitButtonDisabled()}
                className={styles.changePasswordButton}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
