import { generateActionName } from '##/utils/redux';

export const SCOPE = 'CURRENT_MEDIA';

// TODO: stop using 'generateActionName' and do as `page` types/actions
//       for more details/info, please read explanation in src/state/page/types.ts
export const CHANGE_ID = generateActionName(SCOPE, 'CHANGE_ID');

export const SET_CURRENT_MEDIA_COLOR = generateActionName(
  SCOPE,
  'SET_CURRENT_MEDIA_COLOR',
);
