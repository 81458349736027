import {
  CookieNoticeActionType,
  ICookieReducerState,
  SET_IS_GDPR_COUNTRY,
  TOGGLE_COOKIE_NOTICE,
} from './types';
import { setInitialCookieState } from '##/utils/cookie';

const initialState: ICookieReducerState = {
  ...setInitialCookieState(),
  isGDPRCountry: false,
};

export function cookieReducer(
  state = initialState,
  action: CookieNoticeActionType,
): ICookieReducerState {
  switch (action.type) {
    case TOGGLE_COOKIE_NOTICE: {
      return {
        ...state,
        displayCookieNotice: !state.displayCookieNotice,
      };
    }
    case SET_IS_GDPR_COUNTRY: {
      return {
        ...state,
        isGDPRCountry: action.payload,
      };
    }
    default:
      return state;
  }
}
