export const channelItemHoverIndexName = 'CHANNEL_ITEM_HOVER_INDEX';
export const SET_CHANNEL_ITEM_HOVER_INDEX =
  'CHANNEL_ITEM_HOVER_INDEX/SET_CHANNEL_ITEM_HOVER_INDEX';

export interface ISetChannelItemHoverIndexAction {
  type: typeof SET_CHANNEL_ITEM_HOVER_INDEX;
  payload: string;
}

export type ChannelItemHoverIndexActionType = ISetChannelItemHoverIndexAction;

export interface IChannelItemHoverIndexReducerState {
  channelItemHoverIndex: string;
}
