import {
  getJsonValueFromLocalStorage,
  removeItemInLocalStorage,
  setJsonValueInLocalStorage,
} from '##/utils/storage';

import { IJwtData, IUserData } from './authManager';

/** Local storage key for user JWT */
const USER_JWT_KEY = 'userJWT';
/** Local storage key for user login */
const LOGIN_KEY = 'login';

/**
 * Sets the jwt to local storage
 */
export const setJwtToLocalStorage = (jwt: IJwtData) => {
  setJsonValueInLocalStorage(USER_JWT_KEY, jwt);
};

/**
 * Sets the login object to local storage
 */
export const setLoginDataToLocalStorage = (login: IUserData) => {
  setJsonValueInLocalStorage(LOGIN_KEY, login);
};

/**
 * Returns the jwt from local storage
 */
export const getJwtFromLocalStorage = () =>
  (getJsonValueFromLocalStorage(USER_JWT_KEY) || {}) as IJwtData;

/**
 * Returns the login object from local storage
 */
export const getLoginDataFromLocalStorage = () =>
  (getJsonValueFromLocalStorage(LOGIN_KEY) || {}) as IUserData;

/**
 * Removes the jwt to local storage
 */
export const removeJwtFromLocalStorage = () =>
  removeItemInLocalStorage(USER_JWT_KEY);

/**
 * Removes the jwt to local storage
 */
export const removeLoginDataFromLocalStorage = () =>
  removeItemInLocalStorage(LOGIN_KEY);
