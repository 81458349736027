/* eslint-disable import/prefer-default-export */ // TODO: Remove this if adding new export

/**
 * Returns the inline styles for a specific component and theme
 * when configured in the config file
 *
 * @param {string} component The component name as registered in CAPI
 * @param {string} theme The theme name to match what's configured in config
 * @param {object} config The config data (from public/external config file)
 *
 * @return {string} The inline styles.
 */
export const getInlineStylesBasedOnTheme = (component, theme = '', config) => {
  const { componentThemes } = config;
  const lowerCaseTheme = theme?.toLowerCase();

  if (
    !theme
    // if theme does not exist in componentThemes[component][theme]
    || !(
      componentThemes?.[component]?.[lowerCaseTheme]
    )
  ) {
    // return no inline styles
    return {};
  }

  return componentThemes[component][lowerCaseTheme];
};
