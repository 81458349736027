// TODO: Add fade in/out animation

import clsx from 'clsx';
import React from 'react';

import './Backdrop.pcss';

export const BackdropClasses = {
  BLOCK: 'Backdrop',
  MODIFIER_FULLSCREEN: 'Backdrop--fullscreen',
};

export interface IBackdropProps {
  /** An additional CSS class. */
  className?: string;
  /** When `false` will be bound to the next parent with relevant `position` CSS property. */
  fullscreen?: boolean;
  /** Set `true` to show or `false` to hide the component. */
  isOpen?: boolean;
  /** Set CSS z-index property. Default is `100` in the component styles. */
  zIndex?: number | string;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
}

/**
 * Backdrop React component.
 * @description Covers a portion of the page with a black, semi-transparent
 * overlay and prevents clicks/interactions with the content beneath. By default
 * it will be bound to the next parent with CSS `position` relative, absolute,
 * fixed, or sticky. To set the bounds to a specific area make sure the area has
 * the correct CSS `position` property set.
 */
export const Backdrop: React.SFC<IBackdropProps> = ({
  className,
  fullscreen = false,
  isOpen = true,
  onClick,
  zIndex,
}) => {
  const backdropClass = clsx(
    BackdropClasses.BLOCK,
    { [BackdropClasses.MODIFIER_FULLSCREEN]: fullscreen },
    className,
  );

  const styles = zIndex ? ({ zIndex } as React.CSSProperties) : undefined;

  return !isOpen ? null : (
    <div
      className={backdropClass}
      style={styles}
      role="presentation"
      onClick={onClick}
    />
  );
};
