import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const RedCloseIcon: React.FunctionComponent<IIcon> = ({
  width = 9,
  height = 9,
  fill = '#E6001E',
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M1 1L9 9"
      stroke={fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9 1L1 9" stroke={fill} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default React.memo(RedCloseIcon);
