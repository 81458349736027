import React from 'react';
import { Lozenge } from '##/components/Lozenge';
import { Spacing } from '##/components/Spacing';

export type IErrorLozengeProps = {
  errorStatus: number;
};

export const ErrorLozenge: React.FC<IErrorLozengeProps> = ({ errorStatus }) => (
  <Spacing className="Spacing--sm-bottom" top bottom>
    <Lozenge
      lozengeLabel={`This is a ${errorStatus} page`}
      cssClasses={['solid']}
    />
  </Spacing>
);
