import {
  IJwtData,
  updateProfileInLocalStorage,
  IUserData,
} from '##/utils/auth/authManager';
import {
  CLEAR_USER_INFO,
  IErrorCode,
  IS_OPERATING,
  IUserReducerState,
  SET_AUTH_JWT,
  SET_ERROR,
  SET_IS_LOGGED_IN,
  SET_LOGIN_ERROR_CODE,
  SET_PROFILE_INCOMPLETE,
  SET_USER_INFO,
  SET_STATUS_CODE,
  SET_EMAIL_VERIFICATION_ERROR,
  SET_RESET_PASSWORD_SUCCESS,
  UPDATE_STORE_FROM_LOCALSTORAGE,
  UPDATE_USER_INFO,
  UserActionTypes,
  CHANGE_PASSWORD,
  SET_SIGN_IN_SUCCESS,
  SET_SIGN_OUT_SUCCESS,
  SAVE_INVALID_CREDENTIALS,
  CLEAR_NOTIFICATION_PROMPT,
  SET_CHECK_EMAIL_TO_VERIFY,
  SET_CLOSE_ACCOUNT_SUCCESS,
} from './types';

export function setError({ errorCode }: IErrorCode): UserActionTypes {
  return {
    payload: errorCode,
    type: SET_ERROR,
  };
}

export function setAuthResult(jwt: IJwtData): UserActionTypes {
  return {
    payload: jwt,
    type: SET_AUTH_JWT,
  };
}

export function clearNotificationPrompt(): UserActionTypes {
  return {
    payload: null,
    type: CLEAR_NOTIFICATION_PROMPT,
  };
}

export function setSignOutSuccess(
  signOutSuccess: boolean | null,
): UserActionTypes {
  return {
    payload: signOutSuccess,
    type: SET_SIGN_OUT_SUCCESS,
  };
}

export function setSignInSuccess(
  signInSuccess: boolean | null,
): UserActionTypes {
  return {
    payload: signInSuccess,
    type: SET_SIGN_IN_SUCCESS,
  };
}

export function setEmailVerificationError(
  isError: boolean | null,
): UserActionTypes {
  return {
    payload: isError,
    type: SET_EMAIL_VERIFICATION_ERROR,
  };
}

export function setResetPasswordSuccess(
  resetPassword: boolean | null,
): UserActionTypes {
  return {
    payload: resetPassword,
    type: SET_RESET_PASSWORD_SUCCESS,
  };
}

export function setLoginError(errorCode: number | null): UserActionTypes {
  return {
    payload: errorCode,
    type: SET_LOGIN_ERROR_CODE,
  };
}

export function saveInvalidCredentials(payload): UserActionTypes {
  return {
    payload,
    type: SAVE_INVALID_CREDENTIALS,
  };
}

export function setStatusCode(statusCode: number | null): UserActionTypes {
  return {
    payload: statusCode,
    type: SET_STATUS_CODE,
  };
}

export function setUserInfo(
  user: IUserReducerState | IUserData,
): UserActionTypes {
  return {
    payload: user as IUserReducerState,
    type: SET_USER_INFO,
  };
}

export function clearUserInfo(): UserActionTypes {
  return {
    payload: null,
    type: CLEAR_USER_INFO,
  };
}

export function setCloseAccountSuccess(
  closeAccount: boolean | null,
): UserActionTypes {
  return {
    payload: closeAccount,
    type: SET_CLOSE_ACCOUNT_SUCCESS,
  };
}

export function updateProfile(data: Partial<IUserData>): UserActionTypes {
  updateProfileInLocalStorage(data);

  return {
    payload: data,
    type: UPDATE_USER_INFO,
  };
}

export function setProfileIncomplete(
  profileIncomplete: boolean | null,
): UserActionTypes {
  updateProfileInLocalStorage({ profileIncomplete });

  return {
    payload: profileIncomplete,
    type: SET_PROFILE_INCOMPLETE,
  };
}

export function isOperating(operating: boolean): UserActionTypes {
  return {
    payload: operating,
    type: IS_OPERATING,
  };
}

export function setIsLoggedIn(isLoggedIn): UserActionTypes {
  return {
    payload: isLoggedIn,
    type: SET_IS_LOGGED_IN,
  };
}

export function updateStoreFromLocalstorage(): UserActionTypes {
  return {
    type: UPDATE_STORE_FROM_LOCALSTORAGE,
  };
}

export function setChangePassword(
  changePassword: boolean | null,
): UserActionTypes {
  return {
    payload: changePassword,
    type: CHANGE_PASSWORD,
  };
}

export function setCheckYourEmailToVerify(
  checkYourEmail: boolean | null,
): UserActionTypes {
  return {
    payload: checkYourEmail,
    type: SET_CHECK_EMAIL_TO_VERIFY,
  };
}
