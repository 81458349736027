import {
  SET_ACTIVE_TAB,
  SET_DEFAULT_ACTIVE_TAB,
  SET_ACTIVE_TAB_NAME,
  ISetActiveTabAction,
  ISetDefaultActiveTabAction,
  ISetActiveTabNameAction,
} from './types';

export const setActiveTabIndex = (
  currentTabIndex: number,
): ISetActiveTabAction => {
  return {
    type: SET_ACTIVE_TAB,
    payload: currentTabIndex,
  };
};

export const setDefaultTabIndex = (
  currentTabIndex: number,
): ISetDefaultActiveTabAction => {
  return {
    type: SET_DEFAULT_ACTIVE_TAB,
    payload: currentTabIndex,
  };
};

export const setActiveTabName = (
  currentTabName: string,
): ISetActiveTabNameAction => {
  return {
    type: SET_ACTIVE_TAB_NAME,
    payload: currentTabName,
  };
};
