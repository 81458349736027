/**
 * @param {string}  userAgentString - Browser provided description of user agent
 * @param {Boolean} hasMSStream     - Used to distinguish iOS from IE
 */

interface IGetDeviceInformationType {
  ios: boolean;
  android: boolean;
}

export const getDeviceInformation = (
  userAgentString = '',
  hasMSStream = false,
): IGetDeviceInformationType => ({
  ios: /iPad|iPhone|iPod/.test(userAgentString) && !hasMSStream,
  android: /Android/.test(userAgentString),
});

export const device = getDeviceInformation(
  window.navigator.userAgent,
  !!window.MSStream,
);

/**
 * Browser detection
 */
/* istanbul ignore next */
export const browser = {
  ie11: !!window.MSInputMethodContext && !!document.documentMode,
  chrome: !!window.chrome,
  safari:
    /Safari/.test(window.navigator.userAgent) &&
    /Apple Computer/.test(window.navigator.vendor),
};

export const generateAndroidIntent = (uri: string, packageId: string): string =>
  `intent://${uri}#Intent;scheme=seven;package=${packageId};end`;
