import { generateActionNames } from '##/utils/redux';

export const SCOPE = 'MARKET';

// TODO: stop using 'generateActionName' and do as `page` types/actions
//       for more details/info, please read explanation in src/state/page/types.ts
export const [
  ERROR,
  LOADING,
  RECEIVE_DATA,
  SET_MARKET_DATA,
] = generateActionNames(
  SCOPE,
  'ERROR',
  'LOADING',
  'RECEIVE_DATA',
  'SET_MARKET_DATA',
);
