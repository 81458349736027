import { useEffect, useState } from 'react';

/* istanbul ignore next */
export const useIntersection = (ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!window.IntersectionObserver) return;

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    });

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isVisible;
};
