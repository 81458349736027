import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

import { clearNotificationPrompt } from '##/state/user/actions';
import { CloseProgressRing } from '##/components/CloseProgressRing';
import { ContentPrompt } from './ContentPrompt';
import { setDeviceRegistrationMessage } from '##/state/registration/actions';
import {
  ACCOUNT_IS_CLOSED,
  CHANGE_PASSWORD_TEXT,
  CHECK_TO_VERIFY_TEXT,
  SIGN_IN_TEXT,
  SIGN_OUT_TEXT,
  TV_SUCCESSFULLY_CONNECTED,
  UNABLE_TO_VERIFY_EMAIL,
} from './ContentPrompt/ContentPrompt';

import styles from './NotificationPrompt.pcss';

export interface INotificationPromptProps {
  statusCode?: number | string | null;
  editProfile?: boolean;
  changePassword?: boolean;
  openEdit?: boolean;
  openChangePassword?: boolean;
  resetPasswordSuccess?: boolean;
  showConnectYourTvPrompt?: boolean;
  isError?: boolean;
  signInSuccess?: boolean;
  signOutSuccess?: boolean;
  showCheckYourEmailPrompt?: boolean;
  registerSuccess?: boolean;
  verificationRedirectURL?: string;
  closeAccount?: boolean;
}

let interval;
let closeTimeout;

export const NotificationPrompt: React.FC<INotificationPromptProps> = ({
  statusCode,
  editProfile,
  changePassword,
  openEdit,
  openChangePassword,
  isError,
  resetPasswordSuccess,
  showConnectYourTvPrompt,
  signInSuccess,
  signOutSuccess,
  showCheckYourEmailPrompt,
  registerSuccess,
  verificationRedirectURL,
  closeAccount,
}) => {
  const dispatch = useDispatch();

  let timeSec; //time in seconds how much prompt should be shown

  if (registerSuccess || showCheckYourEmailPrompt) {
    timeSec = 20;
  } else if (resetPasswordSuccess) {
    timeSec = 5;
  } else {
    timeSec = 7;
  }

  const [isShown, setShowPrompt] = useState(true);
  const [running, setRunning] = useState(false);
  const [progress, setProgress] = useState(0);

  const onHandleClick = (showing, closeWithTimeout) => {
    setShowPrompt(showing);
    setRunning(showing);
    if (closeWithTimeout) {
      //reset progress 1 second after the prompt disappears, so that the reset is not visible
      closeTimeout = setTimeout(() => {
        setProgress(0);
      }, 1000);
    } else {
      setProgress(0);
    }
    if (registerSuccess) {
      window.history.pushState({}, '', verificationRedirectURL);
    }
  };

  //if we click on the change password / edit details button while the prompt is displayed
  useEffect(() => {
    setProgress(0);
    if (progress > 0) {
      setRunning(false);
    }
  }, [openEdit, openChangePassword]);

  useEffect(() => {
    if (running) {
      setProgress(0);
      interval = setInterval(() => {
        setProgress((prev) => prev + 1);
      }, 1000);
    }
  }, [running]);

  useEffect(() => {
    if (progress === timeSec) {
      setShowPrompt(false);
      setRunning(false);
      clearInterval(interval);
    }
  }, [progress]);

  useEffect(() => {
    if (
      editProfile ||
      changePassword ||
      isError ||
      resetPasswordSuccess ||
      showConnectYourTvPrompt ||
      signInSuccess ||
      signOutSuccess ||
      showCheckYourEmailPrompt ||
      registerSuccess ||
      closeAccount
    ) {
      let timerId;
      let timeout;
      setRunning(true);
      timerId = setTimeout(() => {
        //do cleaning 2 second after the prompt disappears, so that the cleaning is not visible
        timeout = setTimeout(() => {
          setProgress(0);
          if (registerSuccess) {
            window.history.pushState({}, '', verificationRedirectURL);
          }
          dispatch(clearNotificationPrompt());
          dispatch(setDeviceRegistrationMessage(''));
        }, 2000);
      }, timeSec * 1000);

      return () => {
        setShowPrompt(true);
        clearTimeout(timerId);
        clearTimeout(timeout);
        clearTimeout(closeTimeout);
        clearInterval(interval);
      };
    }
  }, [
    editProfile,
    changePassword,
    isError,
    resetPasswordSuccess,
    showConnectYourTvPrompt,
    signOutSuccess,
    signInSuccess,
    showCheckYourEmailPrompt,
    registerSuccess,
    closeAccount,
  ]);

  const getPromptText = () => {
    if (resetPasswordSuccess) {
      return CHANGE_PASSWORD_TEXT;
    } else if (signInSuccess || registerSuccess) {
      return SIGN_IN_TEXT;
    } else if (signOutSuccess) {
      return SIGN_OUT_TEXT;
    } else if (showCheckYourEmailPrompt) {
      return CHECK_TO_VERIFY_TEXT;
    } else if (closeAccount) {
      return ACCOUNT_IS_CLOSED;
    } else if (isError) {
      return UNABLE_TO_VERIFY_EMAIL;
    } else if (showConnectYourTvPrompt) {
      return TV_SUCCESSFULLY_CONNECTED;
    }
  };

  return (
    <div
      className={clsx(styles.prompt, {
        [styles.middlePromptError]: isError,
        [styles.middlePromptSuccess]: showConnectYourTvPrompt,
        [styles.bigPrompt]:
          registerSuccess ||
          window.location.pathname === '/connect' ||
          signOutSuccess ||
          showCheckYourEmailPrompt ||
          closeAccount,
        [styles.promptFadeIn]:
          (editProfile ||
            changePassword ||
            isError ||
            resetPasswordSuccess ||
            showConnectYourTvPrompt ||
            signOutSuccess ||
            signInSuccess ||
            registerSuccess ||
            showCheckYourEmailPrompt ||
            closeAccount) &&
          isShown,
      })}
    >
      <div className={styles.promptTriangle}>
        <CloseProgressRing
          stroke={1}
          color="white"
          fillColor="transparent"
          icon="redClose"
          timeSec={timeSec}
          progress={progress}
          onClose={(showing, closeWithTimeout) =>
            onHandleClick(showing, closeWithTimeout)
          }
          alertMessage={getPromptText()}
        />
      </div>
      <ContentPrompt
        statusCode={statusCode}
        editProfile={editProfile}
        changePassword={changePassword}
        signInSuccess={signInSuccess}
        signOutSuccess={signOutSuccess}
        openEdit={openEdit}
        openChangePassword={openChangePassword}
        isError={isError}
        resetPasswordSuccess={resetPasswordSuccess}
        showCheckYourEmailPrompt={showCheckYourEmailPrompt}
        showConnectYourTvPrompt={showConnectYourTvPrompt}
        registerSuccess={registerSuccess}
        closeAccount={closeAccount}
        handleClick={(showing, closeWithTimeout) =>
          onHandleClick(showing, closeWithTimeout)
        }
      />
    </div>
  );
};
