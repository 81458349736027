import { AnyComponent } from '@swm/types-component-api';
import { NarrowBy } from '@swm/type-utils';

import {
  addQueryParamsToUrl,
  parseQueryString,
  removeQueryParamFromSearch,
} from '##/utils/queries';
import { DEFAULT_PAGE_TITLE, ENV_PRODUCTION } from '##/utils/constants';
import { getPageTitle, isHomePage } from '##/utils/page';
import { ICapiRouteOverride } from '##/interfaces';
import { ILocation } from '##/interfaces/ILocation';
import { logError } from '##/utils/trackJs';
import layouts from '##/layouts.json';
import Parser from '##/utils/parser';
import { isLoggedIn } from '##/utils/auth';

const API_VERSION = '4.9';
const HEADER_AUTHORIZATION = 'authorization';
const { versionNumber } = window.swm;

export const INFO_PANEL = 'infoPanel';
export const LAYOUT_CONTAINER = 'layoutContainer';
export const MEDIA_PLAYER = 'mediaPlayer';
export const SHELF_CONTAINER = 'shelfContainer';
export const SHOW_HEADER = 'featuredShowHeader';

export type ReduxStorableComponentsType = NarrowBy<
  AnyComponent,
  {
    type:
      | 'mediaPlayer'
      | 'infoPanel'
      | 'EpisodeInfoPanel'
      | 'EpisodePlayer'
      | 'shelfContainer'
      | 'featuredShowHeader';
  }
>;

/**
 * Determines if the component should be stored in Redux
 */
export const shouldStoreComponentInRedux = (componentType: string) =>
  [
    MEDIA_PLAYER,
    INFO_PANEL,
    SHELF_CONTAINER,
    SHOW_HEADER,
    'channelCarousel',
  ].includes(componentType);

export const filterCAPIQueries = (queries) => {
  const whiteListedQueries = [
    'episode-id',
    'channel-id',
    'video-id',
    'autoplay',
    'startAd',
  ];
  const parsedQueries = parseQueryString(queries);
  let filteredQueries = queries;

  Object.keys(parsedQueries).forEach((query) => {
    if (whiteListedQueries.indexOf(query) === -1) {
      filteredQueries = removeQueryParamFromSearch(query, filteredQueries);
    }
  });

  return filteredQueries;
};

export const getComponentServiceUrl = (
  env,
  endpoint,
  index,
  location: ILocation,
) => {
  if (location) {
    const { capi } = parseQueryString(location.search);
    let componentService = '';

    // Handle ?capi=
    if (capi && env !== ENV_PRODUCTION) {
      componentService = capi;
    } else {
      // Default capi endpoint
      componentService =
        endpoint +
        (location.pathname || index) +
        filterCAPIQueries(location.search || '');
    }

    return componentService;
  }

  return endpoint + index;
};

export const getCapiParameters = (
  config,
  marketId: number,
  signedUp: boolean,
) => {
  const params = { ...config.headers };

  params['market-id'] = marketId;
  params['platform-version'] = versionNumber;
  params['api-version'] = API_VERSION;
  if (signedUp) {
    params.signedup = signedUp;
  }

  return params;
};

type CapiHeaders = HeadersInit & {
  [HEADER_AUTHORIZATION]?: string;
};

export const getCapiHeaders = ({ token }): CapiHeaders => {
  if (token) {
    return {
      [HEADER_AUTHORIZATION]: `Bearer ${token}`,
    };
  }

  return {};
};

export const overrideHeaders = (
  overrideConfig: ICapiRouteOverride[] | ICapiRouteOverride = [],
  path: string,
  headers: CapiHeaders,
) => {
  const result = { ...headers };

  if (!Array.isArray(overrideConfig)) {
    return result;
  }

  const conf = overrideConfig.find((item) => {
    try {
      return path.match(item.regex);
    } catch (err) {
      return false;
    }
  });

  if (conf) {
    Object.keys(conf).forEach((header) => {
      const normalisedHeader = header.toLowerCase();

      if (conf[header] === false && result[normalisedHeader]) {
        delete result[normalisedHeader];
      }
    });
  }

  return result;
};

const parseCASData = (data) => {
  let parsedData = data;
  try {
    let layout;

    if (typeof layouts[data.type] !== 'undefined') {
      // using `JSON.parse(JSON.stringify(object)))` to create a deep clone which
      // is fine in this situation because we're cloing a plain JSON object so it
      // has no functions, doesn't need to preserve types, no cyclic objects etc.
      layout = JSON.parse(JSON.stringify(layouts[data.type]));
    } else {
      layout = JSON.parse(JSON.stringify(layouts.default));
    }

    const parser = new Parser(data, layout);
    parsedData = parser.parse();
  } catch (error) {
    logError('parseCASData', error);
  }

  return parsedData;
};

const getComponentIndexByType = (data, componentType) =>
  data.items.findIndex((item) => item.type === componentType);

const enrichDefaultData = (data) => {
  const mediaPlayerIndex = getComponentIndexByType(data, 'mediaPlayer');
  const enrichedData = data;

  if (mediaPlayerIndex >= 0) {
    enrichedData.items[mediaPlayerIndex].urls = data.pageMetaData.urls;
  }

  return enrichedData;
};

const getPageData = ({
  type,
  pageMetaData: metaData,
  title: shortPageTitle,
}) => {
  const title = getPageTitle(metaData, DEFAULT_PAGE_TITLE, !isHomePage(type));

  return { type, title, metaData, shortPageTitle };
};

const enrichAndParseCapiData = (data) => {
  let capiData = data;

  try {
    capiData = enrichDefaultData(capiData);
  } catch (error) {
    logError("Couldn't enrich capi data", error);
  }

  capiData = parseCASData(capiData);

  return {
    ...capiData,
    ...getPageData(data),
    listingNavigation: data.listingNavigation,
  };
};

/**
 * Get the components to render based on the given marketId and window location
 * @param {number} marketId The market id
 * @param {object} location Object that comes from React Router
 * @param {string} authJWTToken
 * @param {string} sourceUrl URL that's given by CAPI to poll component data.
 *
 * @returns The capi data (Promise)
 */
const getCapiData = async (
  marketId: number,
  location: ILocation,
  authJWTToken: string | null,
) => {
  const { config, env } = window.swm;
  const { componentService, componentIndex } = config.endpoints;
  const { capiRouteOverride } = config;
  const baseUrl: string = getComponentServiceUrl(
    env,
    componentService,
    componentIndex,
    location,
  );

  // Checks if user is logged in or not; a user is considered logged in only if they are verified
  const loggedIn = isLoggedIn();

  // Adding parameters to the CAPI url
  const urlWithParams: string = addQueryParamsToUrl(
    baseUrl,
    getCapiParameters(config, marketId, loggedIn),
  );

  // Creating headers for the CAPI Request
  const headers = overrideHeaders(
    capiRouteOverride,
    location.pathname,
    getCapiHeaders({ token: loggedIn ? authJWTToken : undefined }),
  );

  try {
    const response = await fetch(urlWithParams, {
      method: 'GET',
      headers: {
        ...headers,
      },
    });

    const jsonResponse = await response.json();

    return enrichAndParseCapiData(jsonResponse);
  } catch (error) {
    logError('getCapiData', error);

    return {
      components: [],
      items: [],
    };
  }
};

export default getCapiData;
