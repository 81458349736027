import { DEFAULT_PAGE_TITLE } from '##/utils/constants';
import {
  IPageReducerState,
  ISetPageInfoPayload,
  ISetPreventCapiReloadAction,
  ISetLiveAutoplayAction,
  PageActionType,
  SET_INFO,
  SET_PREVENT_CAPI_RELOAD,
  SET_LIVE_AUTOLOAD,
} from './types';

const initialState: IPageReducerState = {
  canonicalUrl: '',
  hasListingNavigation: false,
  preventCapiReload: false,
  liveAutoplay: false,
  title: DEFAULT_PAGE_TITLE,
  type: '',
};

export default (
  state = initialState,
  action: PageActionType,
): IPageReducerState => {
  switch (action.type) {
    case SET_INFO:
      return {
        ...state,
        ...(action.payload as ISetPageInfoPayload),
      };
    case SET_PREVENT_CAPI_RELOAD:
      return {
        ...state,
        preventCapiReload: (action as ISetPreventCapiReloadAction).payload,
      };
    case SET_LIVE_AUTOLOAD:
      return {
        ...state,
        liveAutoplay: (action as ISetLiveAutoplayAction).payload,
      };
    default:
      return state;
  }
};
