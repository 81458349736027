import { IJwtData } from '##/utils/auth/authManager';
import { getLoginDataFromLocalStorage } from '##/utils/auth/authLocalStorage';

import {
  CLEAR_USER_INFO,
  IS_OPERATING,
  IUserReducerState,
  SET_AUTH_JWT,
  SET_ERROR,
  SET_IS_LOGGED_IN,
  SET_LOGIN_ERROR_CODE,
  SET_PROFILE_INCOMPLETE,
  SET_USER_INFO,
  SET_STATUS_CODE,
  SET_CLOSE_ACCOUNT_SUCCESS,
  CHANGE_PASSWORD,
  SET_SIGN_IN_SUCCESS,
  SET_SIGN_OUT_SUCCESS,
  UPDATE_STORE_FROM_LOCALSTORAGE,
  SET_EMAIL_VERIFICATION_ERROR,
  SET_RESET_PASSWORD_SUCCESS,
  UPDATE_USER_INFO,
  UserActionTypes,
  SAVE_INVALID_CREDENTIALS,
  CLEAR_NOTIFICATION_PROMPT,
  SET_CHECK_EMAIL_TO_VERIFY,
} from './types';
import { isLoggedIn } from '##/utils/auth';

const defaultState: IUserReducerState = {
  authData: {},
  birthYear: null,
  email: null,
  emailVerificationRequired: null,
  errorCode: null,
  firstName: null,
  gender: null,
  id: null,
  imageUrl: null,
  isLoggedIn: isLoggedIn(),
  isVerified: null,
  lastName: null,
  loginErrorCode: null,
  operationInProgress: false,
  postcode: null,
  profileIncomplete: false,
  provider: null,
  hasFullProfileAccount: null,
  statusCode: null,
  isError: false,
  resetPassword: false,
  editProfile: false,
  changePassword: false,
  signInSuccess: false,
  signOutSuccess: false,
  invalidCredentials: {
    invalidEmail: '',
    invalidPassword: '',
  },
  checkYourEmail: false,
  closeAccount: false,
};

export function userReducer(
  state: IUserReducerState = defaultState,
  action: UserActionTypes,
): IUserReducerState {
  switch (action.type) {
    // TODO: IS_OPERATING is registration for user too. This should be seperated
    // should be done as part of 12176
    case IS_OPERATING:
      return {
        ...state,
        errorCode: action.payload ? null : state.errorCode,
        operationInProgress: action.payload as boolean,
      };
    case SET_AUTH_JWT:
      return {
        ...state,
        authData: action.payload as IJwtData,
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        authData: defaultState.authData,
        birthYear: defaultState.birthYear,
        email: defaultState.email,
        firstName: defaultState.firstName,
        gender: defaultState.gender,
        id: defaultState.id,
        imageUrl: defaultState.imageUrl,
        isLoggedIn: isLoggedIn(),
        isVerified: defaultState.isVerified,
        lastName: defaultState.lastName,
        postcode: defaultState.postcode,
        provider: defaultState.provider,
        hasFullProfileAccount: defaultState.hasFullProfileAccount,
        statusCode: defaultState.statusCode,
        isError: defaultState.isError,
        resetPassword: defaultState.resetPassword,
        editProfile: defaultState.editProfile,
        changePassword: defaultState.changePassword,
      };
    case SET_USER_INFO: {
      const user: IUserReducerState = action.payload as IUserReducerState;

      if (!user) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        birthYear: user.birthYear,
        email: user.email,
        firstName: user.firstName,
        gender: user.gender,
        id: user.id,
        imageUrl: user.imageUrl,
        isVerified: user.isVerified,
        lastName: user.lastName,
        postcode: user.postcode,
        provider: user.provider,
        verificationRedirectURL: user.verificationRedirectURL,
        hasFullProfileAccount: user.hasFullProfileAccount,
        // only setting it to 'true' when user.id is defined AND user is verified
        isLoggedIn: !!user.id && user.isVerified === true,
      };
    }
    case SET_PROFILE_INCOMPLETE: {
      return {
        ...state,
        profileIncomplete: action.payload as boolean,
      };
    }

    case UPDATE_USER_INFO: {
      const user: IUserReducerState = action.payload as IUserReducerState;

      const updates = Object.keys(user).reduce((acc, key) => {
        acc[key] = user[key];

        return acc;
      }, {});

      return {
        ...state,
        ...updates,
      };
    }

    case CLEAR_NOTIFICATION_PROMPT:
      return {
        ...state,
        statusCode: defaultState.statusCode,
        loginErrorCode: defaultState.loginErrorCode,
        isError: defaultState.isError,
        resetPassword: defaultState.resetPassword,
        signInSuccess: defaultState.signInSuccess,
        signOutSuccess: defaultState.signOutSuccess,
        checkYourEmail: defaultState.checkYourEmail,
        closeAccount: defaultState.closeAccount,
      };

    case SET_SIGN_IN_SUCCESS: {
      return {
        ...state,
        signInSuccess: action.payload as boolean | null,
      };
    }

    case SET_SIGN_OUT_SUCCESS: {
      return {
        ...state,
        signOutSuccess: action.payload as boolean | null,
      };
    }

    case SET_EMAIL_VERIFICATION_ERROR: {
      return {
        ...state,
        isError: action.payload as boolean | null,
      };
    }

    case SET_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        resetPassword: action.payload as boolean | null,
      };
    }
    case SET_LOGIN_ERROR_CODE: {
      return {
        ...state,
        loginErrorCode: action.payload as number | null,
      };
    }
    case SAVE_INVALID_CREDENTIALS: {
      return {
        ...state,
        invalidCredentials: {
          invalidEmail: action.payload.email as string | null,
          invalidPassword: action.payload.password as string | null,
        },
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        changePassword: action.payload as boolean | null,
      };
    }
    case SET_CHECK_EMAIL_TO_VERIFY: {
      return {
        ...state,
        checkYourEmail: action.payload as boolean | null,
      };
    }
    case SET_CLOSE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        closeAccount: action.payload as boolean | null,
      };
    }
    case SET_STATUS_CODE: {
      return {
        ...state,
        statusCode: action.payload as number | null,
      };
    }
    // merge all error codes
    case SET_ERROR:
      return {
        ...state,
        errorCode: action.payload as number | null,
      };
    case SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload as boolean,
      };
    case UPDATE_STORE_FROM_LOCALSTORAGE:
      return {
        ...state,
        ...getLoginDataFromLocalStorage(),
      };
    default:
      return state;
  }
}
