import React, { ReactNode } from 'react';

interface IProps {
  children?: JSX.Element[] | JSX.Element | ReactNode;
  subTitle?: string;
  cssClass?: string;
}

export const SubTitle: React.FunctionComponent<IProps> = ({
  children = null,
  subTitle = '',
  cssClass,
}: IProps): JSX.Element => {
  return (
    <h3 className={cssClass} data-test-id="SubTitle">
      {subTitle || children}
    </h3>
  );
};
