import {
  getJwtFromLocalStorage,
  getLoginDataFromLocalStorage,
} from './authLocalStorage';
import { isJwtValid, IJwtData } from '##/utils/auth/authManager';
import aamTracker from '##/utils/tracking/aam-tracker';

// TODO: The plan is to remove the Gigya SDK from the web app and use calls to
// core auth2 service only. At that point we probably wont need this tokenExchange
// function and should implement new node server routes for the required data and
// core auth2 service calls.

// TODO: Once we get rid of the Gigya SDK from web we should replace the Gigya
// based login flow with one based on calling the core auth2 service.

// XXX: Remember to check if user profile is complete and dispatch redux action
// the same as the Gigya login flow works at the moment

/**
 * Log user into the site.
 */
// export async function login() {}

// FIXME: Logout should:
//
// On the server:
//  1. Trigger Gigya logout (via call to auth2 service)
//  2. Send a request to the client which removes each httpOnly cookie (if any)
//
// And the web client should also:
//  1. Clear all domain related cookies (httpOnly cookies already removed via
//    server response)
//  3. Clear all storage (localStorage, sessionStorage, etc.)
//  4. Refresh the page (to get rid of any personalisations)

/**
 * Log user out of the site completely.
 */
// export async function logout() {}

/**
 * Check if the user is logged in or not.
 * @return {boolean}
 */
export function isLoggedIn() {
  const jwtFromLocalStorage: IJwtData = getJwtFromLocalStorage();
  // user must be verified with valid user ID
  const loginDataFromLocalStorage = getLoginDataFromLocalStorage();
  if (
    !loginDataFromLocalStorage ||
    !loginDataFromLocalStorage.id ||
    loginDataFromLocalStorage.isVerified !== true
  ) {
    return false;
  }

  // checking the validity of jwt at this very second
  return jwtFromLocalStorage && isJwtValid(jwtFromLocalStorage, 0);
}

export function setGigyaUIDinAdobeDataLayer() {
  if (aamTracker.isIdSet()) return;
  const loginDataFromLocalStorage = getLoginDataFromLocalStorage();

  if (loginDataFromLocalStorage?.id) {
      aamTracker.setId(loginDataFromLocalStorage.id);
    }
}