import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const eePlayIcon: React.FunctionComponent<
  IIcon & { className: string; id: string; opacity: string }
> = ({
  id,
  opacity = '0.8',
  width = 11,
  height = 19,
  stroke = '#fff',
  className,
}): JSX.Element => (
  <svg
    id={id}
    opacity={opacity}
    className={className}
    width={width}
    height={height}
    viewBox="0 0 11 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={opacity}
      d="M1 17.304V1.69598C1 1.43282 1.31467 1.29719 1.50599 1.47788L9.76907 9.2819C9.89442 9.40028 9.89442 9.59972 9.76907 9.7181L1.50599 17.5221C1.31467 17.7028 1 17.5672 1 17.304Z"
      stroke={stroke}
      strokeWidth="2"
    />
  </svg>
);

export default React.memo(eePlayIcon);
