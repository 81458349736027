import React from 'react';
import clsx from 'clsx';

import { IImageSrcSetSizes } from '##/utils/images';
import { constructSrcSet, getImageProxyUrl } from '##/utils/images';
import styles from './Image.pcss';

export interface IImageProps {
  alt?: string;
  altTag?: string;
  className?: string;
  height?: number;
  imageCard?: boolean;
  quality?: number;
  srcSetSizes?: IImageSrcSetSizes[];
  url: string;
  width?: number;
}

export const Image = ({
  alt,
  altTag,
  className,
  height,
  imageCard,
  quality,
  srcSetSizes,
  url,
  width,
}: IImageProps): JSX.Element => {
  const src: string = getImageProxyUrl({ height, quality, url, width });

  const srcSet: string =
    srcSetSizes && url ? constructSrcSet(url, srcSetSizes, quality) : '';

  return (
    <img
      className={clsx(
        { [styles.imageCard]: imageCard },
        { [`${className}`]: className },
      )}
      src={src}
      srcSet={srcSet}
      alt={altTag || alt}
    />
  );
};
