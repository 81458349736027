import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const RemoveIcon: React.FunctionComponent<IIcon> = ({
  opacity = '0.6',
  className,
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="2"
    viewBox="0 0 12 2"
    fill="none"
    className={className}
  >
    <path
      opacity={opacity}
      d="M10.615 1L1.38422 1"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default React.memo(RemoveIcon);
