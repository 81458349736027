import { makeAction } from '##/utils/redux';
import {
  HIDE_MENUS,
  TOGGLE_MENU,
  TOGGLE_MOBILE_MENU,
  SHOW_MENU,
} from './constants';

//TODO: Lets make this typescript!

// TODO: stop using 'makeAction' and type the 'player' reducer and actions
//       as 'page' reducer and actions
const hideMenusAction = () => makeAction(HIDE_MENUS, null);
const showMenuAction = () => makeAction(SHOW_MENU, null);
const toggleMenu = () => makeAction(TOGGLE_MENU, null);
const toggleMobileSidebarMenu = () => makeAction(TOGGLE_MOBILE_MENU, null);

export const hideMenus = () => hideMenusAction();
export const showSidebarMenu = () => showMenuAction();
export const toggleSidebarMenu = () => toggleMenu();
export const mobileSidebarMenu = () => toggleMobileSidebarMenu();
