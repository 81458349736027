import React from 'react';

import { Spacing } from '##/components/Spacing';
import { TextElement } from '##/components/TextElement';

export interface ITitleWithSpacingProps {
  text: string;
}

export const TitleWithSpacing: React.FC<ITitleWithSpacingProps> = ({
  text,
}) => (
  <Spacing className="Spacing--sm-bottom" top bottom>
    <TextElement textType="title" cssClasses={['h1', 'fw700', 'oversize', 'dtLightestGrey']}>
      {text}
    </TextElement>
  </Spacing>
);
