import { useReducer } from 'react';

import { ICookieSettings } from '##/interfaces/ICookieNoticeDataObject';

export enum CookieOption {
  ESSENTIAL = 'essential',
  ANALYTICAL = 'analytical',
  ADVERTISING = 'advertising',
}

interface State {
  isSettingsOpen: boolean;
  options: ICookieSettings;
}

enum ActionTypes {
  TOGGLE_OPEN_SETTINGS = 'TOGGLE_OPEN_SETTINGS',
  SET_COOKIE_SETTINGS = 'SET_COOKIE_SETTINGS',
  SET_OPTION = 'SET_OPTION',
}

interface IToggleOpenSettings {
  type: ActionTypes.TOGGLE_OPEN_SETTINGS;
  payload?: undefined;
}

interface ISetCookieSettings {
  type: ActionTypes.SET_COOKIE_SETTINGS;
  payload: ICookieSettings;
}

interface ISetOption {
  type: ActionTypes.SET_OPTION;
  payload: CookieOption;
}

type Action = IToggleOpenSettings | ISetCookieSettings | ISetOption;

const reducer = (state: State, { type, payload }: Action): State => {
  switch (type) {
    case ActionTypes.TOGGLE_OPEN_SETTINGS:
      return { ...state, isSettingsOpen: !state.isSettingsOpen };
    case ActionTypes.SET_COOKIE_SETTINGS: {
      const { ...options } = payload as ICookieSettings;
      return {
        ...state,
        options,
      };
    }
    case ActionTypes.SET_OPTION: {
      const option = payload as CookieOption;
      return {
        ...state,
        options: { ...state.options, [option]: !state.options[option] },
      };
    }
    default:
      return state;
  }
};

const toggleOpenSettings = (): IToggleOpenSettings => ({
  type: ActionTypes.TOGGLE_OPEN_SETTINGS,
});

const setCookieSettings = (
  cookieSetting: ICookieSettings,
): ISetCookieSettings => ({
  type: ActionTypes.SET_COOKIE_SETTINGS,
  payload: cookieSetting,
});

const setOption = (option: CookieOption): ISetOption => ({
  type: ActionTypes.SET_OPTION,
  payload: option,
});

export const useCookieReducer = (defaultOptions: ICookieSettings) => {
  const [{ isSettingsOpen, options }, dispatch] = useReducer(reducer, {
    isSettingsOpen: false,
    options: defaultOptions,
  });

  return {
    isSettingsOpen,
    options,
    actions: {
      toggleSettings: () => dispatch(toggleOpenSettings()),
      setCookieSettings: (options: ICookieSettings) =>
        dispatch(setCookieSettings(options)),
      toggleCookieOption: (option: CookieOption) => () =>
        dispatch(setOption(option)),
    },
  };
};
