import React, { useCallback } from 'react';
import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { PredefinedIcon } from '##/utils/icons/PredefinedIcon';
import { LogoNavWrapper } from '##/components/LogoNavWrapper';
import { trackEvent } from '##/utils/tracking/helpers';
import { ISubNavigationSectionItem } from '##/interfaces/INavigation';

import styles from './SubNavigation.pcss';

interface IDropdownItemProps {
  item: ISubNavigationSectionItem;
  itemIndex: string;
  onSubNavigationItemClick: (event) => void;
  containerTitle?: string;
  containerType?: string;
}

export const SubNavigationItem: React.FC<IDropdownItemProps> = ({
  item,
  itemIndex,
  onSubNavigationItemClick,
  containerType,
  containerTitle,
}): JSX.Element => {
  const clickHandler = useCallback((e) => {
    onSubNavigationItemClick(e);
    trackEvent(InfernoUniversalEvents.ux.interaction, {
      ux_element_action: 'selected',
      screen_path: item.contentLink,
      ux_element_type: item.type,
      ux_element_descriptor: item.title,
      ux_element_index: itemIndex,
      ux_element_value: item.contentLink,
      ux_element_container_type: containerType,
      ux_element_container_value: containerTitle,
    });
  }, []);

  if (!item.contentLink) return null;

  return (
    <Link
      to={item.contentLink}
      className={clsx(styles.subNavigationItem, 'reset-link')}
      onClick={clickHandler}
    >
      {item.predefinedIcon && (
        <div className={styles.iconContainer}>
          <PredefinedIcon
            iconName={item.predefinedIcon}
            color="grey"
            classname={styles.icon}
          />
        </div>
      )}
      {item.icon?.url && (
        <div className={styles.container}>
          <LogoNavWrapper
            alt={item.icon.name}
            url={item.icon.url}
            quality={100}
          />
        </div>
      )}
      <span className={styles.sidebarTitle}>{item.title}</span>
    </Link>
  );
};
