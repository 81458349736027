// TODO: this file needs some work done on swm-ui repo to add
// appropriate interface for capi response

interface ISection {
  id?: string;
  name?: string;
  items?: {}[];
  type?: string;
}

interface IComponent {
  type: string;
  component: IComponent | ISection;
}

/**
 * Parser - takes component service data and a layout and returns the layout 'hydrated' with
 * components ready for React to initialise.
 */

export default class Parser {
  public data: { footer?: any; items: {}[] };
  public layout: any;
  public result: {
    components: IComponent[];
    items: {
      [key: string]: {
        cName?: string;
        id?: string;
        type: string;
      };
    };
  };
  public components: { id: number }[];
  public sectionStack: {
    section: ISection;
    items: {}[];
  };

  constructor(data, layout) {
    this.data = data;
    this.layout = layout;
    this.result = {
      items: {},
      components: [],
    };
    this.components = [];
    this.sectionStack = {
      section: {},
      items: [],
    };
  }

  /**
   * Parses over the layout and hydrates it with components supplied by the
   * component service.
   * @param {*} data Component service data
   * @param {*} layout A schema for the page that defines which components should be grouped
   * @returns {*} Page data including a list of react-ready components for the page.
   */
  parse() {
    // Get the basic page data
    Object.assign(this.result, this.setupPageData(this.data));

    // Items that should appear on this page that the component service has provided
    let casItems: any[] = [];

    if (this.data.items) {
      casItems = casItems.concat(this.data.items);
    }

    if (this.data.footer != null) {
      casItems.push(this.data.footer);
    }

    // Add each item to result.components, grouping any items that were specified in the layout.
    casItems.map((item) => this.itemGrouper(item));

    // If there are items left in a section stack then we can complete the section now
    if (this.sectionStack.items.length > 0) {
      this.completeSectionStack();
    }

    // Add components to items object
    this.result.items = this.components.reduce((acc, item) => {
      acc[item.id] = item;

      return acc;
    }, {});

    return this.result;
  }

  /**
   * Loops through sections defined in layout and groups any items that are difined in a section
   * together. Otherwise creates a new section for given item.
   * @param {*} item An item taken from the component service items array
   */
  itemGrouper(item) {
    // Loop through layout sections and determine if this item needs to be grouped
    const foundSection = this.layout.sections.find((section) =>
      section.items.includes(item.type),
    );

    if (foundSection) {
      this.sectionStack.section = foundSection;
      this.sectionStack.items.push(this.initialiseComponent(item));

      return;
    }

    // If there are section stack items waiting in the stack then we can close them off now
    if (this.sectionStack.items.length > 0) {
      this.completeSectionStack();
    }

    // No matches, add a new section
    this.result.components.push({
      type: 'component',
      component: this.initialiseComponent(item),
    });
  }

  /**
   * Takes the section stack, moves it's items into the section then adds the section to the result
   */
  completeSectionStack() {
    this.sectionStack.section.items = this.sectionStack.items;

    this.result.components.push({
      type: 'section',
      component: this.sectionStack.section,
    });

    this.sectionStack = {
      section: {},
      items: [],
    };

    this.layout.sections.splice(0, 1);
  }

  /**
   * Takes a pageComponent from the component service and returns a react-ready component.
   * I.e. a component with a 'component' and 'id' property. In some cases the structure
   * of a component's data for specific components e.g. navigation's items
   * @param {*} pageComponent
   * @return {*} A 'hydrated' component
   */
  initialiseComponent(pageComponent) {
    this.components.push(pageComponent);
    return pageComponent;
  }

  /**
   * Pulls basic page information out of the components service data
   * @returns {*} Basic page information
   */
  setupPageData(data) {
    const { title, listingNavigation, validFor } = data;
    return { title, listingNavigation, validFor };
  }
}
