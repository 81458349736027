import { IHeaderIsFloatingAction, HEADER_IS_FLOATING } from './types';

export function determineIfHeaderIsFloating(
  pageType: boolean,
): IHeaderIsFloatingAction {
  return {
    payload: pageType,
    type: HEADER_IS_FLOATING,
  };
}
