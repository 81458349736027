export interface IPlayerReducerState {
  playing: boolean;
  cardClickCount: number;
  vodPlayerVisible: boolean;
  backgroundPlayerMuted: boolean;
}

export const playerName = 'PLAYER';
// NOTE: having the 'PLAYER' value repeated instead of using template literals functionality
//       and re-using the 'playerName' when creating the constants below
//       because then it would be of the type of string, instead of being
//       of this specific literal type, e.g. 'PLAYER/SET_PLAYING' instead of string
export const SET_PLAYING = 'PLAYER/SET_PLAYING';
export const INCREASE_CARD_CLICK_COUNT = 'PLAYER/INCREASE_CARD_CLICK_COUNT';
export const SET_VOD_PLAYER_VISIBLE = 'PLAYER/SET_VOD_PLAYER_VISIBLE';
export const TOGGLE_BACKGROUND_PLAYER_AUDIO =
  'PLAYER/TOGGLE_BACKGROUND_PLAYER_AUDIO';

export interface ISetPlaying {
  type: typeof SET_PLAYING;
  payload: boolean;
}

export interface ISetVodPlayerVisible {
  type: typeof SET_VOD_PLAYER_VISIBLE;
  payload: boolean;
}

export interface IIncreaseCardClickCount {
  type: typeof INCREASE_CARD_CLICK_COUNT;
  payload: null;
}

export interface IToggleBackgroundPlayerAudio {
  type: typeof TOGGLE_BACKGROUND_PLAYER_AUDIO;
  payload: null;
}

export type PlayerActionType =
  | ISetPlaying
  | IIncreaseCardClickCount
  | ISetVodPlayerVisible
  | IToggleBackgroundPlayerAudio;
