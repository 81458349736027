import React, { FC } from 'react';

import { Button } from '##/components/Button';
import { AccountManagementPageSectionTitle } from '../AccountManagementPageSectionTitle';
import HelpIcon from '##/assets/img/account-management-page/help-icon.svg';

import styles from './HelpSectionContent.pcss';

export const HelpSectionContent: FC = () => (
  <div className={styles.helpSectionContent}>
    <AccountManagementPageSectionTitle
      title="Help"
      icon={HelpIcon}
      alt="Help Icon"
      iconClassName={styles.helpIcon}
    />
    <div className={styles.buttonsContainer}>
      <div className={styles.whiteBorderedButtonWrapper}>
        <Button
          className={styles.contactUsButton}
          message="Contact us"
          url="https://support.7plus.com.au/hc/en-au/requests/new"
          cssClasses={[
            'noBackground',
            'whiteBordered',
            'smallerFontSize',
            'borderRadius1',
          ]}
          target="_blank"
        />
      </div>
      <Button
        className={styles.visitKnowledgebaseButton}
        message="Help centre"
        url="https://support.7plus.com.au/hc/en-au/articles/360060194292-Terms-and-Conditions"
        cssClasses={[
          'dtRed',
          'smallerFontSize',
          'transparentBorder',
          'borderRadius1',
        ]}
        target="_blank"
      />
    </div>
  </div>
);
