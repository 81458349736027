import React from 'react';
import clsx from 'clsx';

import styles from '##/components/_Section.pcss';

interface IProps {
  children: JSX.Element | JSX.Element;
}

export const VodSection: React.FC<IProps> = ({ children }) => {
  return (
    <section
      className={clsx(styles.sectionPage, styles.sectionVod, 'globalVodPage')}
    >
      {children}
    </section>
  );
};
