// ========================
// Analytics tracker
//
// Returns a single instance of the inferno analytics tracker
// ready to be initialised and used across the app
// ========================

import { AnalyticsTracker } from 'inferno-web-analytics-proxy';

const infernoTracker = new AnalyticsTracker();

// Exporting as a singleton
export default infernoTracker;
