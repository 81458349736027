import React from 'react';
import { compose } from 'redux';
import { Provider } from 'react-redux';
import { withRouter, BrowserRouter } from 'react-router-dom';

import state from '##/state';
import { generateStore } from '##/utils/redux';
import { AppContainerConnector } from '##/AppContainerConnector';

const AppWithRouter = compose(withRouter)(AppContainerConnector);

export const AppContainer = () => (
  <Provider store={generateStore(state)}>
    <BrowserRouter>
      <AppWithRouter />
    </BrowserRouter>
  </Provider>
);
