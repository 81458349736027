import { connect } from 'react-redux';
import { AccountManagementPage } from './AccountManagementPage';
import { userName } from '##/state/user/types';

export const mapStateToProps = (state) => ({
  isLoggedIn: state[userName].isLoggedIn,
});
export default connect(
  mapStateToProps,
  null,
)(AccountManagementPage);
