import { Image } from '##/components/Image';
import React from 'react';
import styles from './LogoNavWrapper.pcss';
import { getSrcSetSizes } from '##/utils/images';

interface ILogoNavWrapperProps {
  url: string;
  alt?: string;
  quality?: number;
}

export const LogoNavWrapper: React.FunctionComponent<ILogoNavWrapperProps> = ({
  alt,
  quality,
  url,
}: ILogoNavWrapperProps) => {
  const navLogoHeight = 18;
  const srcSetSizes = getSrcSetSizes({height: navLogoHeight});
  return (
    <div className={styles.logoWrapper}>
      <Image className={styles.logo} url={url} alt={alt} quality={quality} height={navLogoHeight} srcSetSizes={srcSetSizes} />
    </div>
  );
};
