import { History } from 'history';

/**
 * It receives the search string from location, parses it and returns an object
 * containing all query params
 * @param {string} search search term (e.g. from a URL)
 */
export const parseQueryString = (search = ''): { [key: string]: string } => {
  let queryParams = {};
  let queryString = search;

  if (queryString.startsWith('?')) {
    queryString = queryString.substr(1);
  }

  queryString.split('&').forEach((queryParam) => {
    // dont process queryParam if it is empty
    if (queryParam) {
      const queryParamParts = queryParam.split('=');
      queryParams[queryParamParts[0]] =
        queryParamParts.length > 1 ? queryParamParts[1] : true;
    }
  });

  return queryParams;
};

const makeQueryString = (
  query: { [key: string]: string },
  existingQueryString = '',
) => {
  let keyCount = 0;
  let querystring = '';

  Object.keys(query).forEach((key) => {
    keyCount += 1;
    const queryParameter = `${key}=${query[key]}`;

    if (
      !querystring.includes(queryParameter) &&
      !existingQueryString.includes(queryParameter)
    ) {
      querystring += keyCount > 1 ? `&${queryParameter}` : queryParameter;
    }
  });

  return existingQueryString.length > 0 ? querystring : `?${querystring}`;
};

/**
 * Given a search string, return the string with the new parameters.
 * @param {string} search search term from a URL
 * @param {object} params object with parameters to add
 * @returns {string} new search value
 */
export const updateQueryString = (search, params): string => {
  const pairs = search.includes('?')
    ? search.replace('?', '').split('&')
    : search.split('&');
  let paramsObject = {};

  // Add the existing query params in a object
  for (let i = 0; i < pairs.length; i += 1) {
    const value = pairs[i];
    const index = value.indexOf('=');
    if (index > -1) {
      paramsObject = {
        ...paramsObject,
        [value.slice(0, index)]: value.slice(index + 1),
      };
    }
  }

  // Put add params to the object and override any existing values
  Object.keys(params).forEach((key) => {
    paramsObject = {
      ...paramsObject,
      [key]: params[key],
    };
  });

  // Put object in a query string format
  return makeQueryString(paramsObject);
};

/**
 * Add query parameters to a URL
 * eg ('http://eg.com', { a: b }) -> 'http://eg.com?a=b'
 * @param {string} url URL to modify
 * @param {object} params Parameters to add
 * @returns {string} URL with parameters
 */
export const addQueryParamsToUrl = (url, params) => {
  const queryStartIndex = url.indexOf('?');

  if (queryStartIndex === -1) {
    return url + updateQueryString('?', params);
  }

  const queryStr = url.substr(queryStartIndex);
  return url.substr(0, queryStartIndex) + updateQueryString(queryStr, params);
};

/**
 * Given a search string, return the string with param 'overlay=sign-in'.
 * @param {string} search search term from a URL
 * @returns {string} new search value
 */
export const getSignInRoute = (search) =>
  updateQueryString(search, { overlay: 'sign-in' });

/**
 * Given a search string, return the string with param 'overlay=register'.
 * @param {string} search search term from a URL
 * @returns {string} new search value
 */
export const getRegisterRoute = (search) =>
  updateQueryString(search, { overlay: 'register' });

/**
 * Given a search string, return the string with param 'overlay=forgot-password'.
 * @param {string} search search term from a URL
 * @returns {string} new search value
 */
export const getForgotPassordRoute = (search) =>
  updateQueryString(search, { overlay: 'forgot-password' });

/**
 * replaces query string matching overlay regex with an empty string
 * @param {string} defines the string to be removed from history
 * @param {string=} search from react router history
 */
export const removeQueryParamFromSearch = (query, search = '') => {
  // regex to replace overlay
  const expression = `(\\?|&)(${query}(=[^&]*)?)`;
  const overlayRegex = new RegExp(expression);
  // recreate query string
  const modifiedSearch = search.replace(overlayRegex, '');
  // check if modifiedSearch begins with '&' and replace with '?'
  if (modifiedSearch[0] === '&') {
    return modifiedSearch.replace(modifiedSearch[0], '?');
  }
  return modifiedSearch;
};

/**
 * redirects user to current path without query string passed in as an argument
 * @param {string} defines the string to be removed from history
 * @param {object} history from react router
 */
export const redirectWithoutQueryString = (
  query: string,
  history?: History,
): void => {
  // return it if there is no query string
  if (!history || !history.location) return;

  const newSearch: string = removeQueryParamFromSearch(
    query,
    history.location.search,
  );

  history.push(`${history.location.pathname}${newSearch}`);
};

/**
 * redirects user to current path without query string passed in as an argument
 * @param {string[]} defines the list of strings to be removed from history
 * @param {object} history from react router
 */
export const redirectWithoutQueryStrings = (
  queries: string[],
  history: History,
): void => {
  // return it if there is no query string
  if (!history || !history.location) return;

  let newSearch: string = history.location.search;
  queries.forEach((query, index) => {
    newSearch = removeQueryParamFromSearch(query, newSearch);
  });

  history.push(`${history.location.pathname}${newSearch}`);
};

export const redirectWithLogoutQuery = (history: History): void => {
  return history.push(`${history.location.pathname}?logout=true`);
};
