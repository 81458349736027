import React from 'react';

import renderComponent from '##/utils/renderComponent';
import { DefaultSection } from '##/components/DefaultSection';
import { FooterSection } from '##/components/FooterSection';
import { GenericSection } from '##/components/GenericSection';
import { HeaderSection } from '##/components/HeaderSection';
import { LiveSection } from '##/components/LiveSection';
import { MediaSection } from '##/components/MediaSection';
import { PagePanelSection } from '##/components/PagePanelSection';
import { PageSection } from '##/components/PageSection';
import { VodSection } from '##/components/VodSection';
import { logError } from '##/utils/trackJs';
import { ILocation } from '##/interfaces/ILocation';
import { IState } from '##/AppTypes';

export const sections = {
  DefaultSection,
  FooterSection,
  GenericSection,
  HeaderSection,
  LiveSection,
  MediaSection,
  PagePanelSection,
  PageSection,
  VodSection,
};

export default (
  pageComponentSectionId: string,
  name = 'Default',
  state: IState,
  location: ILocation,
  items: [],
  allItems: [],
): JSX.Element => {
  const Section = sections[name];
  if (!Section) {
    logError(`Section does not exist: ${name}`);
    return null;
  }

  /** Temporary function to render Full Width
   * Info panel only on Live Page
   */
  const determineComponentType = (type: string) => {
    if (state.page.type === 'broadcastPage' && type === 'infoPanel') {
      return 'infoPanelFullWidth';
    }
    return type;
  };

  return (
    <Section key={pageComponentSectionId}>
      {items.map(({ id, type }) => {
        if (state.page.items[id]) {
          const componentProps = {
            ...state.page.items[id],
          };

          return renderComponent(
            id,
            determineComponentType(type),
            componentProps,
            allItems,
          );
        }

        logError(`Component does not exist ${type}`);

        return null;
      })}
    </Section>
  );
};
