import { IDynamicDataSource } from '@swm/types-component-api';
import { useEffect, useState } from 'react';

import {
  getDynamicDataSourceData,
  startPollingDynamicDataSource,
} from '##/utils/dynamicDataSource';
import { IJwtData } from '##/utils/auth/authManager';

export interface IUseRequestDynamicDataSource<T> {
  initialItems: T[] | null | any;
  getToken(): Promise<IJwtData | null>;
  source?: IDynamicDataSource;
  propertyInDdsResponse?: string;
  skipFirstFetch?: boolean;
  ignoreInitialItems?: boolean;
  title?: string;
  propertyInDdsResponseBYWX?: string;
}

interface IRequestDynamicDataSource {
  getToken(): Promise<IJwtData | null>;
  source: IDynamicDataSource;
  signal: AbortSignal;
}

export const useRequestDynamicDataSource = <T>({
  initialItems,
  getToken,
  source,
  propertyInDdsResponse = 'items',
  skipFirstFetch = false,
  ignoreInitialItems = false,
  title,
}: IUseRequestDynamicDataSource<T>) => {
  const [items, setItems] = useState(initialItems);
  const [dynamicTitle, setDynamicTitle] = useState(title);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems, source?.url]);

  const requestDynamicDataSource = async ({
    source,
    getToken,
    signal,
  }: IRequestDynamicDataSource) => {
    const result = await getDynamicDataSourceData(source, getToken, signal);
    setItems(result[propertyInDdsResponse]);
    setDynamicTitle(result?.title || title);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    if (source?.url) {
      // setting skipFirstFetch flag to true allows us to skip the first fetch if we
      // don't have initialItems and rely on the polling to provide the updated data
      if (!initialItems && !skipFirstFetch) {
        requestDynamicDataSource({ source, getToken, signal });
      }

      if (initialItems && ignoreInitialItems) {
        requestDynamicDataSource({ source, getToken, signal });
      }

      if (source.validFor && +source.validFor > 0) {
        const pollInterval = startPollingDynamicDataSource(
          source,
          getToken,
          (response) => {
            const itemsInDdsResponse = 'items';

            if (response.items !== undefined) {
              setItems(response[itemsInDdsResponse]);
            } else {
              setItems(response[propertyInDdsResponse]);
            }
          },
          signal,
        );
        return () => {
          abortController.abort();
          clearInterval(pollInterval);
        };
      }
    }

    return () => {
      abortController.abort();
    };
  }, [source?.url]);

  return { data: items, setItems, dynamicTitle };
};
