import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const AddIcon: React.FunctionComponent<IIcon> = ({
  opacity = '0.6',
  className,
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    className={className}
  >
    <g opacity={opacity}>
      <path
        d="M10.615 6L1.38422 6"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M5.99951 1.38452L5.99951 10.6153"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default React.memo(AddIcon);
