import {
  ISetPageInfoAction,
  ISetPageInfoPayload,
  ISetPreventCapiReloadAction,
  ISetLiveAutoplayAction,
  SET_INFO,
  SET_PREVENT_CAPI_RELOAD,
  SET_LIVE_AUTOLOAD,
} from './types';

export function setPageInfo(data: ISetPageInfoPayload): ISetPageInfoAction {
  return {
    payload: data,
    type: SET_INFO,
  };
}

export function setPreventCapiReload(
  preventCapiReload: boolean,
): ISetPreventCapiReloadAction {
  return {
    payload: preventCapiReload,
    type: SET_PREVENT_CAPI_RELOAD,
  };
}

export function setLiveAutoplay(
  liveAutoplay: boolean,
): ISetLiveAutoplayAction {
  return {
    payload: liveAutoplay,
    type: SET_LIVE_AUTOLOAD,
  };
}
