import React from 'react';
import { createBemClass } from '##/utils/bemClasses';
import './Spacing.pcss';

export type ISpacingProps = {
  children?: JSX.Element[] | JSX.Element;
  bottom?: boolean;
  top?: boolean;
  className?: string;
};

export const Spacing: React.FC<ISpacingProps> = ({
  children = null,
  ...props
}) => {
  const { top = false, bottom = false } = props;
  const cls = createBemClass('Spacing2', props);

  if (!top && !bottom) {
    return <span className={cls}>{children}</span>;
  }

  return <div className={cls}>{children}</div>;
};
