import * as Yup from 'yup';

import { getCurrentYear } from '##/utils/time';

/**
 * Gigya's email validation regular expression.
 * Also used on the node server for backend validation.
 * @see https://knowledge.gigya.com/GKB_Article?id=a6H400000015RGnEAM&source=a1Y40000007TQqzEAG
 */
export const EMAIL_REGEX_GIGYA =
  /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/;
const FOUR_DIGITS_REGEX = /^\d{4}$/;

export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=\S+$).{8,}$/;

//string contains only letters (latin and cyrillic alphabet),
//allows white space in the middle and disallows white space at the beginning and end
export const ONLY_LETTER_REGEX = /^[A-Za-zА-Яа-я]+(?:\s[A-Za-zА-Яа-я]+)*$/;

export const MIN_PASSWORD_LENGTH = 8;

export const EMAIL_INVALID_ERROR_MESSAGE = 'Must be a valid email';
const EMAIL_REQUIRED_ERROR_MESSAGE = 'Must be a valid email';

export const getEmailYupSchema = () =>
  Yup.string()
    .matches(EMAIL_REGEX_GIGYA, EMAIL_INVALID_ERROR_MESSAGE)
    .required(EMAIL_REQUIRED_ERROR_MESSAGE);

export const PASSWORD_INVALID_ERROR_MESSAGE =
  'Minimum 8 characters, with at least 1 number and letter';
export const PASSWORD_REQUIRED_ERROR_MESSAGE =
  'Minimum 8 characters, with at least 1 number and letter';
export const OLD_PASSWORD_INVALID_ERROR_MESSAGE = 'Minimum 8 characters';

export const getPasswordYupSchema = () =>
  Yup.string()
    .matches(PASSWORD_REGEX, PASSWORD_INVALID_ERROR_MESSAGE)
    .required(PASSWORD_REQUIRED_ERROR_MESSAGE);

export const getOldPasswordYupSchema = () =>
  Yup.string()
    .min(MIN_PASSWORD_LENGTH, OLD_PASSWORD_INVALID_ERROR_MESSAGE)
    .required(OLD_PASSWORD_INVALID_ERROR_MESSAGE);

const FIRST_LAST_NAME_INVALID = 'Invalid name';

const FIRST_NAME_REQUIRED_ERROR_MESSAGE = 'Required';

export const getFirstNameYupSchema = () =>
  Yup.string()
    .matches(ONLY_LETTER_REGEX, FIRST_LAST_NAME_INVALID)
    .required(FIRST_NAME_REQUIRED_ERROR_MESSAGE);

const LAST_NAME_REQUIRED_ERROR_MESSAGE = 'Required';

export const getLastNameYupSchema = () =>
  Yup.string()
    .matches(ONLY_LETTER_REGEX, FIRST_LAST_NAME_INVALID)
    .required(LAST_NAME_REQUIRED_ERROR_MESSAGE);

const VALID_BIRTH_YEAR_DIFFERENCE = 120;
const BIRTH_YEAR_MIN = getCurrentYear() - VALID_BIRTH_YEAR_DIFFERENCE;
const BIRTH_YEAR_MAX = getCurrentYear();

const BIRTH_YEAR_TYPE_ERROR_MESSAGE = 'Birth year should be a number';
const BIRTH_YEAR_MIN_ERROR_MESSAGE = `Must be between ${BIRTH_YEAR_MIN} - ${BIRTH_YEAR_MAX}`;
const BIRTH_YEAR_MAX_ERROR_MESSAGE = `Must be between ${BIRTH_YEAR_MIN} - ${BIRTH_YEAR_MAX}`;
const BIRTH_YEAR_REQUIRED_ERROR_MESSAGE = 'Required';
const POSTCODE_REQUIRED_ERROR_MESSAGE = 'Required';

export const getBirthYearYupSchema = () =>
  Yup.number()
    .typeError(BIRTH_YEAR_TYPE_ERROR_MESSAGE)
    .min(BIRTH_YEAR_MIN, BIRTH_YEAR_MIN_ERROR_MESSAGE)
    .max(BIRTH_YEAR_MAX, BIRTH_YEAR_MAX_ERROR_MESSAGE)
    .required(BIRTH_YEAR_REQUIRED_ERROR_MESSAGE);

const POSTCODE_TYPE_ERROR_MESSAGE = 'Invalid postcode';

export const getPostcodeYupSchema = () =>
  Yup.string()
    .matches(FOUR_DIGITS_REGEX, POSTCODE_TYPE_ERROR_MESSAGE)
    .required(POSTCODE_REQUIRED_ERROR_MESSAGE);

const GENDER_REQUIRED_ERROR_MESSAGE = 'Please complete gender';

export const getGenderYupSchema = () =>
  Yup.string().required(GENDER_REQUIRED_ERROR_MESSAGE);

export const getRegistrationYupSchema = () =>
  Yup.object().shape({
    email: getEmailYupSchema(),
    password: getPasswordYupSchema(),
    firstName: getFirstNameYupSchema(),
    lastName: getLastNameYupSchema(),
    birthYear: getBirthYearYupSchema(),
    postcode: getPostcodeYupSchema(),
    gender: getGenderYupSchema(),
  });

export const getEditProfileYupSchema = () =>
  Yup.object().shape({
    email: getEmailYupSchema(),
    firstName: getFirstNameYupSchema(),
    lastName: getLastNameYupSchema(),
    birthYear: getBirthYearYupSchema(),
    postcode: getPostcodeYupSchema(),
  });

export const getSignInYupSchema = () =>
  Yup.object().shape({
    email: getEmailYupSchema(),
    password: getOldPasswordYupSchema(),
  });

export const getForgotPasswordYupSchema = () =>
  Yup.object().shape({
    email: getEmailYupSchema(),
  });

export const getChangePasswordSignInYupSchema = () =>
  Yup.object().shape({
    newPassword: getPasswordYupSchema(),
  });

// removes whitespace before calling callback with amended value
/* istanbul ignore next */
export const trimValue = (changeValueCallback) => (event) => {
  if (
    (event.target.name === 'postcode' || event.target.name === 'birthYear') &&
    event.target.value.length > 4
  ) {
    return;
  }
  event.target.value = event.target.value.trim();

  changeValueCallback(event);
};
