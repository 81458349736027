import {
  IPlayerReducerState,
  INCREASE_CARD_CLICK_COUNT,
  ISetPlaying,
  PlayerActionType,
  SET_PLAYING,
  SET_VOD_PLAYER_VISIBLE,
  ISetVodPlayerVisible,
  TOGGLE_BACKGROUND_PLAYER_AUDIO,
} from './types';

const initialState: IPlayerReducerState = {
  playing: false,
  cardClickCount: 0,
  vodPlayerVisible: false,
  backgroundPlayerMuted: true,
};

export default (
  state = initialState,
  action: PlayerActionType,
): IPlayerReducerState => {
  switch (action.type) {
    case SET_PLAYING:
      return {
        ...state,
        playing: (action as ISetPlaying).payload,
      };
    case SET_VOD_PLAYER_VISIBLE:
      return {
        ...state,
        vodPlayerVisible: (action as ISetVodPlayerVisible).payload,
      };
    case INCREASE_CARD_CLICK_COUNT:
      return {
        ...state,
        cardClickCount: state.cardClickCount + 1,
      };
    case TOGGLE_BACKGROUND_PLAYER_AUDIO:
      return {
        ...state,
        backgroundPlayerMuted: !state.backgroundPlayerMuted,
      };
    default:
      return state;
  }
};
