export const activeTabName = 'ACTIVE_TAB';
export const SET_ACTIVE_TAB = 'ACTIVE_TAB/SET_ACTIVE_TAB';
export const SET_DEFAULT_ACTIVE_TAB = 'ACTIVE_TAB/SET_DEFAULT_ACTIVE_TAB';
export const SET_ACTIVE_TAB_NAME = 'ACTIVE_TAB/SET_ACTIVE_TAB_NAME';

export interface ISetActiveTabAction {
  type: typeof SET_ACTIVE_TAB;
  payload: number;
}

export interface ISetDefaultActiveTabAction {
  type: typeof SET_DEFAULT_ACTIVE_TAB;
  payload?: number;
}

export interface ISetActiveTabNameAction {
  type: typeof SET_ACTIVE_TAB_NAME;
  payload: string;
}

export type ActiveTabActionType =
  | ISetActiveTabAction
  | ISetDefaultActiveTabAction
  | ISetActiveTabNameAction;

export interface IActiveTabReducerState {
  activeTabIndex: number;
  activeTabName: string;
}
