const LINK_SUPPORT = 'https://support.7live.com.au/contact/';

const {
  config: { errors, supportUrl },
} = window.swm;

const getErrorResponse = (status, url) => {
  switch (status) {
    case 400:
      return errors.BAD_REQUEST;
    case 401:
      return errors.UNAUTHORISED;
    case 403:
      return errors.FORBIDDEN;
    case 404:
      return errors.NOT_FOUND;
    case 503:
      return { ...errors.SERVER_UNAVAILABLE, linkUrl: url };
    default:
      return { ...errors.SERVER_ERROR, linkUrl: url };
  }
};

export const getErrorResponseData = (status) =>
  getErrorResponse(status, supportUrl || LINK_SUPPORT);

export const isSwmError = (error): boolean =>
  error &&
  typeof error.status === 'number' &&
  typeof error.title === 'string' &&
  typeof error.message === 'string';
