export const adBlockerName = 'AD_BLOCKER';
export const SET_AD_BLOCKER_STATUS = 'AD_BLOCKER/SET_AD_BLOCKER_STATUS';
export const SET_AD_BLOCKER_DISMISSED = 'AD_BLOCKER/SET_AD_BLOCKER_DISMISSED';

export interface ISetAdBlockerStatusAction {
  type: typeof SET_AD_BLOCKER_STATUS;
  payload: boolean;
}

export interface ISetAdBlockerDismissedAction {
  type: typeof SET_AD_BLOCKER_DISMISSED;
  payload: boolean;
}

export type AdBlockerActionType =
  | ISetAdBlockerStatusAction
  | ISetAdBlockerDismissedAction;

export interface IAdBlockerReducerState {
  isAdBlockerOverlayDismissed: boolean;
  isAdBlockerActive: boolean | null;
}
