import {
  SHOW_SUB_NAVIGATION,
  CLOSE_SUB_NAVIGATION,
  NavigationActionTypes,
  ISubNavigationState,
} from './types';

const initialState: ISubNavigationState = {
  subNavigationLink: '',
};

export function navigationReducer(
  state = initialState,
  action: NavigationActionTypes,
): ISubNavigationState {
  switch (action.type) {
    case SHOW_SUB_NAVIGATION:
      return {
        ...state,
        subNavigationLink: action.payload,
      };
    case CLOSE_SUB_NAVIGATION:
      return {
        ...state,
        subNavigationLink: '',
      };
    default:
      return state;
  }
}
