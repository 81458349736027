/**
 * Loads a JS script asynchronously.
 * @param {object} attrs Attributes to add to the script tag eg { src: '<url>' }
 * @returns {Promise}
 */
export const loadScriptAsync = attrs =>
  new Promise((res, rej) => {
    const script = document.createElement('script');
    script.onload = res;
    script.onerror = rej;
    script.async = true;

    Object.assign(script, attrs);
    document.head.appendChild(script);
  });
