import React, { MouseEvent, ReactNode } from 'react';
import clsx from 'clsx';

import { TextElement } from '##/components/TextElement';
import { bem } from '##/utils/bemClasses';
import EyeIcon from '##/assets/img/eye-icon.svg';
import EyeCrossedOutIcon from '##/assets/img/eye-crossed-out.svg';

import './FormInput.pcss';

const BLK = 'FormInput';
const passwordType = 'password';
const textType = 'text';

interface IProps {
  autoComplete?: string;
  centeredText?: boolean;
  error?: string;
  id?: string;
  label?: string;
  message?: string;
  name: string;
  onBlur?(name: string, isTouched?: boolean, shouldValidate?: boolean): void;
  onFocus?(name: string, isTouched?: boolean, shouldValidate?: boolean): void;
  onChange(e: React.ChangeEvent<any>): void;
  placeholder?: string;
  required?: boolean;
  touched?: boolean;
  type: string;
  value?: string;
  pattern?: string;
  inputMode?: React.HTMLAttributes<any>['inputMode'];
  onTypingShownError?: string;
  inputfontsize?: string;
  disabled?: boolean;
  className?: string;
  placeholderTranslation?: boolean;
  iconEye?: boolean;
  maxWidthError?: boolean;
  hintText?: string;
  maxLength?: number;
  isConnectScreen?: boolean;
}

interface IState {
  type: string;
}

export class FormInput extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps> = {
    error: '',
    label: '',
    message: '',
    name: undefined,
    onBlur: () => {},
    onFocus: () => {},
    required: false,
    touched: false,
    value: '',
    centeredText: false,
    onTypingShownError: '',
    inputfontsize: '',
    disabled: false,
    className: '',
    placeholderTranslation: false,
    iconEye: false,
    maxWidthError: false,
    hintText: '',
    maxLength: null,
  };

  constructor(props: IProps) {
    super(props);

    this.state = {
      type: props.type,
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  public handleFocus(): void {
    const { type, onFocus, name } = this.props;

    if (type === passwordType) {
      this.setState({
        type: passwordType,
      });
    }

    onFocus(name, true);
  }

  public handleBlur(): void {
    const { type, onBlur, name } = this.props;

    if (type === passwordType) {
      this.setState({
        type: passwordType,
      });
    }

    onBlur(name, true);
  }

  public togglePasswordVisibility(event: MouseEvent<HTMLButtonElement>): void {
    const { type } = this.state;

    event.preventDefault();
    this.setState({
      type: type === passwordType ? textType : passwordType,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public handlePasswordToggleClick(event: MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
  }

  public render(): ReactNode {
    const { type: sType } = this.state;
    const {
      type: pType,
      id,
      value,
      disabled,
      inputfontsize,
      className,
    } = this.props;
    const {
      error,
      label,
      message,
      touched,
      centeredText,
      pattern,
      inputMode,
      onTypingShownError,
      placeholderTranslation,
      iconEye,
      maxWidthError,
      hintText,
      maxLength,
      isConnectScreen,
      ...filteredProps
    } = this.props;

    const showButton = value && pType === passwordType;
    const displayMessage = error || onTypingShownError || message || hintText;
    const inputClasses = clsx(
      'FormInput__input',
      { 'FormInput__input--padding-right': showButton },
      { 'FormInput__input--padding-right-double': showButton },
      {
        'FormInput__input--line-error':
          error || onTypingShownError.length > 0 || message,
      },
    );
    const inputClassesSmallFont = clsx(
      'FormInput__input',
      'FormInput__input--small-font',
      {
        'FormInput__input--line-error': error || onTypingShownError.length > 0,
      },
    );

    const labelClasses = bem(BLK, 'placeholder', value ? 'withValue' : '');
    const messageClasses = clsx(
      {
        FormInput__message:
          (error && !isConnectScreen) || message || onTypingShownError,
      },
      {
        'FormInput__no-message-text': !displayMessage,
      },
      {
        'FormInput__no-message-text-connect':
          !displayMessage && isConnectScreen,
      },
      {
        'FormInput__error-connect': error && isConnectScreen,
      },
      { 'FormInput__message--error': error },
      {
        'FormInput__message--onTypingShownError': onTypingShownError,
      },
      { 'FormInput__message--centered': centeredText },
      {
        'FormInput__message--max-width-error': maxWidthError,
      },
      {
        'FormInput__hint-text':
          hintText && !error && !message && !onTypingShownError,
      },
    );
    const showHideClasses = bem(BLK, 'showHide');

    const getShowHideSign = () => {
      if (sType === passwordType && iconEye) {
        return (
          <img
            className={clsx(
              'FormInput__eyeIcon',
              'FormInput__eyeIconWithMargin',
            )}
            src={EyeIcon}
            alt="Eye icon"
          />
        );
      } else if (sType !== passwordType && iconEye) {
        return (
          <img
            className="FormInput__eyeIcon"
            src={EyeCrossedOutIcon}
            alt="Crossed out eye icon"
          />
        );
      } else if (sType === passwordType) {
        return 'show';
      }
      return 'hide';
    };

    const getInputClasses = () => {
      if (inputfontsize === 'small') {
        return inputClassesSmallFont;
      }
      return inputClasses;
    };

    return (
      <span className={BLK}>
        <label className={clsx(bem(BLK, 'label'), className)} htmlFor={id}>
          <input
            {...filteredProps}
            className={clsx(getInputClasses())}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            spellCheck={false}
            type={sType}
            pattern={pattern}
            inputMode={inputMode}
            maxLength={maxLength}
          />
          {label && (
            <TextElement
              cssClasses={
                inputfontsize === 'small'
                  ? disabled
                    ? ['silver', 'fw600', 'opacity04']
                    : ['silver', 'fw600']
                  : ['mediumGrey', 'h6', 'fw500']
              }
              className={clsx(labelClasses, {
                'FormInput__placeholder--placeholderTranslation': placeholderTranslation,
              })}
            >
              {label}
            </TextElement>
          )}
          {showButton && (
            <button
              className={showHideClasses}
              onMouseDown={this.togglePasswordVisibility}
              onClick={this.handlePasswordToggleClick}
              type="button"
              tabIndex={-1}
            >
              {getShowHideSign()}
            </button>
          )}
        </label>
        {displayMessage ? (
          <span className={messageClasses}>{displayMessage}</span>
        ) : (
          <span className={messageClasses}>&nbsp;</span>
        )}
      </span>
    );
  }
}
