import { getComponent } from '##/components';
import React, { Suspense } from 'react';
import { CatchComponentError } from '##/components/CatchComponentError';
import { logError } from '##/utils/trackJs';

export default function renderComponent(
  id,
  componentType,
  props,
  allItems = [],
) {
  const Component = getComponent(componentType) as React.ReactType;

  if (Component) {
    return (
      <CatchComponentError key={id} componentType={componentType}>
        <Suspense fallback={null}>
          <Component {...props} id={id} allItems={allItems} />
        </Suspense>
      </CatchComponentError>
    );
  }

  logError(`Component does not exist: ${componentType}`);
}
