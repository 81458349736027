import {
  HIDE_MENUS,
  TOGGLE_MENU,
  TOGGLE_MOBILE_MENU,
  SHOW_MENU,
} from './constants';

const initialState = {
  sidebarMenuVisible: false,
  mobileMenuVisible: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MENU:
      return { ...state, sidebarMenuVisible: true };
    case HIDE_MENUS:
      return { ...state, sidebarMenuVisible: false, mobileMenuVisible: false };
    case TOGGLE_MENU:
      return { ...state, sidebarMenuVisible: !state.sidebarMenuVisible };
    case TOGGLE_MOBILE_MENU:
      return { ...state, mobileMenuVisible: !state.mobileMenuVisible };
    default:
      return state;
  }
};
