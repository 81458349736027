import React from 'react';

import sectionStyles from '##/components/_Section.pcss';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export const PageSection: React.FC<IProps> = ({ children }) => (
  <section className={sectionStyles.sectionPage}>{children}</section>
);
