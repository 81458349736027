import { withRouter } from 'react-router-dom';
import { userName } from '##/state/user/types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { SignInWidget } from './SignInWidget';
import { logout } from '##/state/user/managers/logout';
import { mobileSidebarMenu } from '##/state/sidebarMenu';

export const mapStateToProps = (state) => ({
  isLoggedIn: state[userName].isLoggedIn,
  provider: state[userName].provider,
  firstName: state[userName].firstName,
});

export const mapDispatchToProps = (dispatch) => ({
  onLogoutClick: (history, provider) => {
    logout(history, dispatch, provider);
    dispatch(mobileSidebarMenu());
  },
  closeMenu: () => dispatch(mobileSidebarMenu()),
});

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SignInWidget);
