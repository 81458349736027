import { useCallback, useState } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';
import { useEventListener } from './useEventListener';
interface Size {
  width: number;
  height: number;
}
declare function UseElementSizeType<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
];
/* eslint-disable no-void */
/* istanbul ignore next */
export const useElementSize: typeof UseElementSizeType = () => {
  const [ref, setRef] = useState(null);
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const handleSize = useCallback(() => {
    setSize({
      width: (ref === null || ref === void 0 ? void 0 : ref.offsetWidth) || 0,
      height: (ref === null || ref === void 0 ? void 0 : ref.offsetHeight) || 0,
    });
  }, [
    ref === null || ref === void 0 ? void 0 : ref.offsetHeight,
    ref === null || ref === void 0 ? void 0 : ref.offsetWidth,
  ]);
  useEventListener('resize', handleSize);
  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [
    ref === null || ref === void 0 ? void 0 : ref.offsetHeight,
    ref === null || ref === void 0 ? void 0 : ref.offsetWidth,
  ]);
  return [setRef, size];
};
/* eslint-enable no-void */
