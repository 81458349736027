import React from 'react';
import clsx from 'clsx';
import { TextElement } from '##/components/TextElement';
import { Button } from '##/components/Button';
import { ButtonLink } from '##/components/ButtonLink';
import styles from './ContentPrompt.pcss';
import { INotificationPromptProps } from '../NotificationPrompt';
import { PATHS } from '##/utils/route';
import { trackEvent } from '##/utils/tracking/helpers';
import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';

export const EDIT_PROFILE_TEXT = 'Profile updated successfully';
export const CHANGE_PASSWORD_TEXT = 'Password changed successfully';

export const SIGN_IN_TEXT = 'You’re now signed in to 7plus';
const CONNECT_YOUR_TV_URL = `${PATHS.CONNECT}`;
const CONNECT_YOUR_TV_TEXT = 'Connect your TV';

export const SIGN_OUT_TEXT = 'You’re now signed out of 7plus';

export const UNABLE_TO_VERIFY_EMAIL = 'We weren’t able to verify your email.';
const LINK_INVALID = 'Link invalid or expired. Try signing in again.';

const SIGN_IN = 'Sign in';
const SIGN_IN_URL = `?overlay=${PATHS.SIGN_IN}`;

export const TV_SUCCESSFULLY_CONNECTED =
  'You’ve successfully connected your TV';
const TV_UPDATE_TEXT = 'It may take a moment for your TV to update.';

export const CHECK_TO_VERIFY_TEXT = 'Check your email to verify your account.';
const SIGN_IN_TO_REQUEST_NEW_LINK = 'Sign in to request a new  link';

export const ACCOUNT_IS_CLOSED = 'Your account is now being closed.';
const SEVEN_DAYS_TO_DELETE = 'Allow 7 days for account data to be deleted.';

interface IConntentPromptProps extends INotificationPromptProps {
  handleClick?(showing: boolean, closeWithTimeout: boolean): void;
}

export const ContentPrompt: React.FC<IConntentPromptProps> = ({
  statusCode,
  editProfile,
  changePassword,
  signInSuccess,
  signOutSuccess,
  registerSuccess,
  isError,
  resetPasswordSuccess,
  showCheckYourEmailPrompt,
  showConnectYourTvPrompt,
  handleClick,
  closeAccount,
}) => {
  const getNotificationText = () => {
    if (editProfile) {
      return EDIT_PROFILE_TEXT;
    } else if (changePassword || resetPasswordSuccess) {
      return CHANGE_PASSWORD_TEXT;
    } else if (signInSuccess || registerSuccess) {
      return SIGN_IN_TEXT;
    } else if (signOutSuccess) {
      return SIGN_OUT_TEXT;
    } else if (showCheckYourEmailPrompt) {
      return CHECK_TO_VERIFY_TEXT;
    } else if (closeAccount) {
      return (
        <>
          <TextElement
            className={styles.firstRowText}
            cssClasses={['body2', 'white']}
          >
            {ACCOUNT_IS_CLOSED}
          </TextElement>
          <TextElement cssClasses={['body3', 'white', 'fw300']}>
            {SEVEN_DAYS_TO_DELETE}
          </TextElement>
        </>
      );
    } else if (isError) {
      return (
        <>
          <TextElement
            className={styles.firstRowText}
            cssClasses={['body2', 'redNew']}
          >
            {UNABLE_TO_VERIFY_EMAIL}
          </TextElement>
          <TextElement cssClasses={['body3', 'white', 'fw300']}>
            {LINK_INVALID}
          </TextElement>
        </>
      );
    } else if (showConnectYourTvPrompt) {
      return (
        <>
          <TextElement className={styles.firstRowText}>
            {TV_SUCCESSFULLY_CONNECTED}
          </TextElement>
          <TextElement cssClasses={['body3', 'white', 'fw300']}>
            {TV_UPDATE_TEXT}
          </TextElement>
        </>
      );
    }
  };

  const handleNotificationPromptButtonClick = (descriptor, containerValue) => {
    trackEvent(InfernoUniversalEvents.ux.interaction, {
      ux_element_action: 'selected',
      ux_element_type: 'flashButton',
      ux_element_descriptor: descriptor,
      ux_element_index: 'na',
      ux_element_value: 'internal routing',
      ux_element_container_type: 'flashAlert',
      ux_element_container_value: containerValue,
    });
  };

  return (
    <div className={styles.contentPromptWrapper}>
      <span
        className={clsx(styles.text, {
          [styles.textMobile]: signInSuccess,
        })}
      >
        {getNotificationText()}
      </span>
      {registerSuccess && (
        <div className={styles.whiteBorederedButtonWrapper}>
          <ButtonLink
            className={styles.btnLink}
            url={CONNECT_YOUR_TV_URL}
            data-test-id="connect-your-tv-link"
            onClick={() => {
              handleClick(false, false);
              handleNotificationPromptButtonClick(
                CONNECT_YOUR_TV_TEXT,
                SIGN_IN_TEXT,
              );
            }}
          >
            {CONNECT_YOUR_TV_TEXT}
          </ButtonLink>
          <Button
            message="Not now"
            className={styles.btn}
            onClick={() => {
              handleClick(false, true);
            }}
            cssClasses={['noBackground', 'smallerFontSize']}
          />
        </div>
      )}
      {signOutSuccess && (
        <div className={styles.whiteBorederedButtonWrapper}>
          <ButtonLink
            className={styles.btnLink}
            url={SIGN_IN_URL}
            data-test-id="sign-in-link"
            onClick={() => {
              handleClick(false, true);
              handleNotificationPromptButtonClick(SIGN_IN, SIGN_OUT_TEXT);
            }}
          >
            {SIGN_IN}
          </ButtonLink>
          <Button
            message="Not now"
            className={styles.btn}
            onClick={() => {
              handleClick(false, true);
              handleNotificationPromptButtonClick('Not now', SIGN_OUT_TEXT);
            }}
            cssClasses={['noBackground', 'smallerFontSize']}
          />
        </div>
      )}
      {showCheckYourEmailPrompt && (
        <div className={styles.whiteBorederedButtonWrapper}>
          <ButtonLink
            className={styles.btnLink}
            url={SIGN_IN_URL}
            data-test-id="sign-in-link"
            onClick={() => {
              handleClick(false, true);
              handleNotificationPromptButtonClick(
                SIGN_IN_TO_REQUEST_NEW_LINK,
                CHECK_TO_VERIFY_TEXT,
              );
            }}
          >
            {SIGN_IN_TO_REQUEST_NEW_LINK}
          </ButtonLink>
        </div>
      )}
    </div>
  );
};
