import { setAuthResult, setUserInfo } from '##/state/user/actions';

import axios from 'axios';
import { Dispatch } from 'redux';
import { logError } from '##/utils/trackJs';
import {
  getJwtFromLocalStorage,
  getLoginDataFromLocalStorage,
  setJwtToLocalStorage,
  setLoginDataToLocalStorage,
} from './authLocalStorage';
import { clearUsersLoggedInState } from '##/state/user/managers/logout';

export const nodeEndpoints = {
  AUTH_OTP: '/auth/otp',
  AUTH_TOKEN: '/auth/token',
  USER_PROFILE: '/user/profile',
  DELETE_PROFILE: '/account/disable',
  LOGOUT_FROM_ALL_DEVICES: '/account/logout',
  REFRESH_TOKEN: '/auth/refresh',
};
export const REG_SOURCE: string = '7plus';
export const WEB_PLATFORM_ID: string = 'web';
export const DEFAULT_FETCH_TIMEOUT: number = 14e3; // 14 seconds
export const GIGYA_SDK_ID: string = 'gigya';

export interface IJwtData {
  token?: string;
  refreshToken?: string;
  exp?: number;
  profileIncomplete?: boolean;
}

export interface IAuthData {
  birthYear: number | null;
  email: string | null;
  firstName: string | null;
  gender: string | null;
  hasFullProfileAccount: boolean | null;
  lastName: string | null;
  postcode: number | null;
}

export type IUserData = IAuthData & {
  id: string;
  imageUrl: string;
  isVerified?: boolean;
  profileIncomplete?: boolean | null;
  provider: string;
  statusCode?: number | null;
  editProfile?: boolean | null;
};

/** Checks if JWT is valid now and next 5 minutes */
export const isJwtValid = (jwt: IJwtData, delay = 5 * 60) => {
  /**
   * jwt.exp is in seconds as it's default unix timestamp.
   * However Javascript returns in milliseconds,
   * so it's necessary to convert milliseconds to seconds.
   */
  const currentTimeInMilliSeconds = new Date().getTime();
  const currentTimeInSeconds = Math.round(currentTimeInMilliSeconds / 1000);
  return currentTimeInSeconds + delay <= (jwt.exp || 0);
};

/**
 * Exchange a Gigya JWT for data from the core auth2 service.
 */
export async function tokenExchange(gigyaToken: string): Promise<IJwtData> {
  try {
    const res = await axios(nodeEndpoints.AUTH_TOKEN, {
      method: 'post',
      data: {
        idToken: gigyaToken,
        platformId: WEB_PLATFORM_ID,
        regSource: REG_SOURCE,
      },
      timeout: DEFAULT_FETCH_TIMEOUT,
    });

    // that's the auth JWT
    return res.data;
  } catch (error) {
    logError('authManager.tokenExchange', error);
    // Don't throw on error so the login process still runs because at this point
    // login is still handled with Gigya and this function is only used to in
    // detecting if the user's profile data is complete (as part of the age &
    // gender feature). This way we're more resilient to failures with either the
    // web node server or the core auth2 service.

    return {};
  }
}

export async function refreshAuthToken(
  refreshToken: string,
): Promise<IJwtData> {
  try {
    const res = await axios(nodeEndpoints.REFRESH_TOKEN, {
      method: 'post',
      data: {
        refreshToken,
        platformId: WEB_PLATFORM_ID,
        regSource: REG_SOURCE,
      },
      timeout: DEFAULT_FETCH_TIMEOUT,
    });

    return res.data;
  } catch (error) {
    logError('authManager.refreshAuthToken', error);
  }
}

/**
 * Checks if the token exists on localStorage and its expiry conditions.
 * If it's valid: returns JWT, if it's not valid: requests a new JWT,
 * saves it to localStorage and redux state, and return it. If JWT doesn't
 * exist in localStorage, returns null.
 */
export async function getToken(
  dispatch: Dispatch,
  logout?: () => void,
): Promise<IJwtData | null> {
  try {
    /* Check if JWT exists on local storage, otherwise return null */
    const jwtFromLocalStorage: IJwtData | null | undefined =
      getJwtFromLocalStorage();

    /**
     * Previously, we were using a login object in localStorage to
     * save logged in user information. Currently we have updated to
     * userJWT object. But we have to take older users in consideration
     * if they haven't used 7plus recently. They will still have old token
     * in their localStorage. We have to detect this, validate it, and request
     * a new jwt object and save the correct userJWT into localStorage.
     */
    const loginDataFromLocalStorage: IUserData | null | undefined =
      getLoginDataFromLocalStorage();

    if (
      Object.keys(jwtFromLocalStorage || {}).length ||
      Object.keys(loginDataFromLocalStorage || {}).length
    ) {
      dispatch(setUserInfo(loginDataFromLocalStorage));

      /* Check if token expires in 5 minutes. If it doesn't expire: return JWT */
      if (
        jwtFromLocalStorage &&
        isJwtValid(jwtFromLocalStorage)
      ) {
        // Logout user if their saved jwt token doesn't have refreshToken.
        if (!jwtFromLocalStorage.refreshToken) {
          clearUsersLoggedInState(dispatch);
          return null;
        }
        return jwtFromLocalStorage;
      }
      /* If the token expired or will expire in 5 minutes: request a new JWT token */
      // return getGigyaToken()
      //   .then((gigyaToken: string) => {
      //     // temp code logging if the gigya method fired successfully
      //     // delete when this gigya dependancy is removed
      //     logError(
      //       'authManager.getToken.getGigyaToken.success',
      //       `gigyaToken ${gigyaToken}`,
      //     );
      //     return gigyaToken;
      //   })
      return refreshAuthToken(jwtFromLocalStorage.refreshToken)
        .then((jwt: IJwtData | {}) => {
          setJwtToLocalStorage(jwt);
          /** Updates the redux store with the new jwt: */
          dispatch(setAuthResult(jwt));

          /* If we can't find the JWT while trying to retrieve the token,
          we perform a logout to ensure a clean user session and clear any residual data from local storage.
          This is necessary because we want to logout the user on other devices after closing the account or
          Auth2 Service encounters an error on refresh token. */
          if (!jwt) {
            return logout()
          }

          return jwt;
        })
        .catch((error) => {
          // this is a bit of a hack but is a workaround until we remove the tokenExchange
          // and call auth directly
          console.log('authManager.refreshAuthToken.failed', error);
          logError('authManager.refreshAuthToken.failed', error);
          clearUsersLoggedInState(dispatch);

          return null;
        });
    }
    return null;
  } catch (error) {
    logError('authManager.getToken', error);
    return null;
  }
}

/**
 * Takes data from auth and gigya and merges them to create final
 * user information data object. Updates redux and localStorage with
 * the newly created user data object. Returns this new user data object.
 *
 * @param {IAuthData} authData - The user information from auth
 * @param {IUserData} gigyaUserData - The user information from gigya
 * @param {IJwtData} authResult - JWT token, expiration and profileIncomplete data
 * @param {Dispatch} dispatch - Redux dispatch action
 * @param {(string|null)} provider - Login provider ie Facebook, Google, Email.
 * @return {IUserData} Merged user data
 */
export function saveAndReturnUserAuthData(
  authData: IAuthData,
  gigyaUserData: IUserData,
  authResult: IJwtData,
  dispatch: Dispatch,
  provider: string | null = null,
): IUserData {
  // Merge gigyaUserData and authData
  /* AUTH getProfile only returns email, birthYear, firstName and gender.
   * Also, for example, it doesn't return gender if gender
   * doesn't exist on auth. Hence, it's necessary to reconstruct
   * the object as below: */
  const authProfile: IAuthData = {
    birthYear: authData.birthYear || null,
    email: authData.email || null,
    firstName: authData.firstName || gigyaUserData.firstName || null,
    gender: authData.gender || null,
    lastName: authData.lastName || gigyaUserData.lastName || null,
    postcode: authData.postcode || null,
    hasFullProfileAccount: authData.hasFullProfileAccount || null,
  };

  const currentProfile: IUserData = {
    ...gigyaUserData,
    ...authProfile,
  };

  const { token, refreshToken, exp, profileIncomplete } = authResult;

  setLoginDataToLocalStorage({ ...currentProfile, profileIncomplete });
  setJwtToLocalStorage({ token, refreshToken, exp });

  dispatch(setAuthResult(authResult));

  /* If provider exists, the call is tracing from onSuccessfulLogin rather than
  loginInit.*/
  if (provider) {
    dispatch(setUserInfo({ ...currentProfile, provider }));
  } else if (!provider && currentProfile.isVerified) {
    dispatch(setUserInfo(currentProfile));
  }

  return currentProfile;
}

export function updateProfileInLocalStorage(data: Partial<IUserData>): void {
  const user: IUserData = getLoginDataFromLocalStorage();

  setLoginDataToLocalStorage({
    ...user,
    ...data,
  });
}
