import { ICookieNoticeDataObject } from '##/interfaces/ICookieNoticeDataObject';

export const cookieName = 'COOKIE';

export const TOGGLE_COOKIE_NOTICE = 'TOGGLE_COOKIE_NOTICE';
export const SET_IS_GDPR_COUNTRY = 'SET_IS_GDPR_COUNTRY';

export interface IToggleCookieNoticeAction {
  type: typeof TOGGLE_COOKIE_NOTICE;
  payload: null;
}

export interface ISetIsGDPRCountry {
  type: typeof SET_IS_GDPR_COUNTRY;
  payload: boolean;
}

export type CookieNoticeActionType =
  | IToggleCookieNoticeAction
  | ISetIsGDPRCountry;

export interface ICookieReducerState extends ICookieNoticeDataObject {
  isGDPRCountry: boolean | null;
}
