import { Classes } from '##/utils/constants';

const activeLocks = new Map();

export function lockScroll(lockEl) {
  const currentLocks = activeLocks.get(lockEl) || 0;
  activeLocks.set(lockEl, currentLocks + 1);

  // prevent scrolling
  lockEl.classList.add(Classes.UTIL_PREVENT_SCROLL);
}

export function unlockScroll(lockEl) {
  const currentLocks = activeLocks.get(lockEl);
  if (currentLocks) {
    activeLocks.set(lockEl, currentLocks - 1);
    if (!activeLocks.get(lockEl)) {
      // allow scrolling
      lockEl.classList.remove(Classes.UTIL_PREVENT_SCROLL);
    }
  }
}

export function getActiveLocks() {
  return activeLocks;
}

export function getElemLocksCount(elem) {
  return activeLocks.get(elem);
}

export function clearScollLocks() {
  // clear all existing lock classes for all elements
  if (activeLocks.size) {
    activeLocks.forEach((value, lockEl) => {
      lockEl.classList.remove(Classes.UTIL_PREVENT_SCROLL);
    });
    activeLocks.clear();
  }
}

export const scrollWindow = (scrollDuration) => {
  const getScrollY = () => window.scrollY || window.pageYOffset;

  const initialScrollY = getScrollY();
  const cosParameter = initialScrollY / 2;
  let scrollCount = 0;
  let oldTimestamp = performance.now();
  let animationFrame: number;

  const step = (newTimestamp) => {
    scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
    if (scrollCount >= Math.PI) window.scrollTo(0, 0);
    const scrollY = getScrollY();
    if (scrollY === 0) {
      window.cancelAnimationFrame(animationFrame);
      return;
    }
    const cosParameterTimesCosineOfScrollCount =
      cosParameter * Math.cos(scrollCount);
    window.scrollTo(
      0,
      Math.round(cosParameter + cosParameterTimesCosineOfScrollCount),
    );
    oldTimestamp = newTimestamp;
    animationFrame = window.requestAnimationFrame(step);
  };

  animationFrame = window.requestAnimationFrame(step);
};

export const scrollToTopSmoothly = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
