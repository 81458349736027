import Cookies from 'js-cookie';

import { getValueFromLocalStorage } from '##/utils/storage';
import { logError } from '##/utils/trackJs';
import {
  ICookieNoticeDataObject,
  ICookieSettings,
} from '##/interfaces/ICookieNoticeDataObject';

export const COOKIE_NOTICE_CLOSED_BY_USER = 'cookieNoticeClosedByUser';
const COOKIE_KEY = 'cookieNotice';
const COOKIE_TIME_KEY = 'cookieNoticeTime';

/**
 * Sets the initial cookie state depending on the data set in the `cookieNotice` cookie
 */
export const setInitialCookieState = (): ICookieNoticeDataObject => {
  const cookieData = getCookieNoticeDataFromCookies();
  let displayCookieNotice = false;

  if (!cookieData) {
    const cookieNoticeClosedByUser = getValueFromLocalStorage(
      COOKIE_NOTICE_CLOSED_BY_USER,
    );

    if (!cookieNoticeClosedByUser) {
      displayCookieNotice = true;
    }
  }

  return {
    displayCookieNotice,
  };
};

/**
 * Sets the data for the cookie notice in two cookies.
 * One for the user selection values and the other with the user selection time,
 * in order to be able to cache it properly in CloudFront
 */
export const setCookieNoticeDataAsACookies = (
  cookieSettings: ICookieSettings,
): void => {
  if (cookieSettings) {
    try {
      const { advertising, analytical, essential, modified } = cookieSettings;

      // NOTE: we should only store the user selections (true/false values) as part of 'COOKIE_KEY'
      // and the 'modified' (user selection time) should be saved as part of a separate cookie,
      // otherwise we'll not be able to cache it in CloudFront since 'modified' has a unique value
      Cookies.set(
        COOKIE_KEY,
        JSON.stringify({ advertising, analytical, essential }),
      );
      Cookies.set(COOKIE_TIME_KEY, JSON.stringify({ modified }));
    } catch (error) {
      logError(
        `Error setting cookieNotice settings. Cookie settings data: ${JSON.stringify(
          cookieSettings,
        )}.`,
        error,
      );
    }
  }
};

/**
 * Returns the data for the cookie notice from cookies
 */
export const getCookieNoticeDataFromCookies = ():
  | ICookieSettings
  | undefined => {
  try {
    const cookieData = Cookies.get(COOKIE_KEY);
    const cookieTimeData = Cookies.get(COOKIE_TIME_KEY);

    // NOTE: it's important to use `undefined` as it's default value
    const cookieNoticeJson = cookieData ? JSON.parse(cookieData) : undefined;
    // NOTE: it's important to use an empty JSON as it's default value
    const cookieTimeJson = cookieTimeData ? JSON.parse(cookieTimeData) : {};

    return cookieNoticeJson
      ? { ...cookieNoticeJson, ...cookieTimeJson }
      : undefined;
  } catch (error) {
    console.log('error', error);
    logError("Can't retrieve the cookieNotice data.", error);
  }
};
