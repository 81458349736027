import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const RedRemoveIcon: React.FunctionComponent<IIcon> = ({
  opacity = 0.8,
  className,
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="3"
    viewBox="0 0 14 3"
    fill="none"
    className={className}
  >
    <path
      opacity={opacity}
      d="M12.2695 1.21655L1.76766 1.21655"
      stroke="#E6001E"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default React.memo(RedRemoveIcon);
