import React, { FC } from 'react';
import clsx from 'clsx';

import { TextElement } from '##/components/TextElement';

import styles from './AccountManagementPageSectionTitle.pcss';
interface IAccountManagementPageSectionTitleProps {
  title: string;
  icon: string;
  alt: string;
  iconClassName?: string;
}

export const AccountManagementPageSectionTitle: FC<
  IAccountManagementPageSectionTitleProps
> = ({ title, icon, alt, iconClassName }) => (
  <div className={styles.accountManagementPageSectionTitle}>
    <img src={icon} alt={alt} className={clsx(styles.icon, iconClassName)} />
    <TextElement
      textType="title"
      cssClasses={['h2', 'episodeWhiteOpacity6', 'fw400']}
      className={styles.title}
    >
      {title}
    </TextElement>
  </div>
);
