const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 60 * MINUTE_IN_SECONDS;
const DAY_IN_SECONDS = 24 * HOUR_IN_SECONDS;
const DAY_IN_MS = DAY_IN_SECONDS * 1000;

export const enoughTimeElapsed = (time1, time2, timeInterval) =>
  time1 - timeInterval > time2;

export const convertDaysToMilliseconds = (days) => days * DAY_IN_MS;

export const getCurrentYear = () => new Date().getFullYear();

export const parseDateToHour = (time) =>
  new Date(time)
    .toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    .toLocaleLowerCase();

export const parseDurationToSeconds = (duration: string) => {
  const parseSingleDuration = (d: string) => {
    if (d.includes('d')) {
      return parseInt(d, 10) * 24 * 60 * 60;
    } else if (d.includes('h')) {
      return parseInt(d, 10) * 60 * 60;
    } else if (d.includes('m')) {
      return parseInt(d, 10) * 60;
    }
    return 0;
  }

  const durations = duration.split(' ');
  let seconds = 0;
  durations.forEach((d, i) => {
    seconds += parseSingleDuration(d)
  })
  return seconds
}

export const parseSecondsToDuration = (seconds: number) => {
  const minutes = Math.round(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days) {
    return `${days}d ${hours - days * 24}h ${minutes - hours * 60}m`
  } else if (hours) {
    return `${hours}h ${minutes - hours * 60}m`
  } else if (minutes) {
    return `${minutes}m`
  } else if (seconds < 60) {
    return "1m"
  }

  return undefined
}
