import { CHANGE_ID, SET_CURRENT_MEDIA_COLOR } from './constants';

const initialState = { mediaId: '0', currentMediaColor: 'fff' };

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_ID:
      return { ...state, mediaId: action.payload };
    case SET_CURRENT_MEDIA_COLOR:
      return { ...state, currentMediaColor: action.payload };
    default:
      return state;
  }
};
