import { connect } from 'react-redux';
import { EnhancedChangePasswordForm } from './EnhancedChangePasswordForm';
import { changePassword } from '##/state/user/managers/user';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { userName } from '##/state/user/types';
import { setError, setStatusCode } from '##/state/user/actions';

export const mapStateToProps = (state) => ({
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  statusCode: state[userName].statusCode,
  errorCode: state[userName].errorCode,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changePassword: (values) => dispatch(changePassword(values)),
  setStatusCode: (statusCode) => dispatch(setStatusCode(statusCode)),
  setErrorCode: (error) => dispatch(setError(error)),
});

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EnhancedChangePasswordForm);
