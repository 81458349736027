export const HEADER_IS_FLOATING = 'HEADER_IS_FLOATING';

export const header = 'HEADER';

export interface IHeaderIsFloatingAction {
  type: typeof HEADER_IS_FLOATING;
  payload: boolean;
}
export interface IHeaderState {
  isFloatingHeader: boolean;
}
export type HeaderActionTypes = IHeaderIsFloatingAction;
