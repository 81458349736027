import React from 'react';

import { Button } from '##/components/Button';
import { Spacing } from '##/components/Spacing';

export interface IButtonWithSpacingProps {
  errorStatus: number;
  message: string;
}

export const ButtonWithSpacing: React.FC<IButtonWithSpacingProps> = ({
  errorStatus,
  message,
}) =>
  errorStatus < 500 ? (
    <Spacing className="Spacing--lg Spacing--md-desktop" top bottom>
      <Button
        url="/"
        message={message}
        cssClasses={['dtRed', 'textWhite']}
      />
    </Spacing>
  ) : null;
