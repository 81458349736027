import { IIcon } from '##/interfaces/IIcon';
import { IconNames } from './iconNames';
import ExternalLinkIcon from './svgs/externalLinkIcon';
import PauseIcon from './svgs/pauseIcon';
import PlayIcon from './svgs/playIcon';
import StopIcon from './svgs/stopIcon';
import eePlayIcon from './svgs/eePlayIcon';
import CcIcon from './svgs/CcIcon';
import FullscreenIcon from './svgs/FullscreenIcon';
import LeftArrowIcon from './svgs/LeftArrowIcon';
import LogoIcon from './svgs/LogoIcon';
import MuteIcon from './svgs/MuteIcon';
import NewPauseIcon from './svgs/NewPauseIcon';
import NewPlayIcon from './svgs/NewPlayIcon';
import SettingsIcon from './svgs/SettingsIcon';
import UnmuteIcon from './svgs/UnmuteIcon';
import AddIcon from './svgs/AddIcon';
import RemoveIcon from './svgs/RemoveIcon';
import RedAddIcon from './svgs/RedAddIcon';
import RedRemoveIcon from './svgs/RedRemoveIcon';
import RedCloseIcon from './svgs/RedCloseIcon';

// We can't just delcare icons in a <img /> or in background-image
// as the SVG's require the correct width, height and styles applied to them
export const getIcon = (
  iconName: string,
): React.NamedExoticComponent<IIcon> | never => {
  switch (iconName) {
    case IconNames.EXTERNAL_LINK:
      return ExternalLinkIcon;
    case IconNames.PAUSE:
      return PauseIcon;
    case IconNames.STOP:
      return StopIcon;
    case IconNames.PLAY:
      return PlayIcon;
    case IconNames.EE_PLAY:
      return eePlayIcon;
    case IconNames.CC:
      return CcIcon;
    case IconNames.FULLSCREEN:
      return FullscreenIcon;
    case IconNames.LEFT_ARROW:
      return LeftArrowIcon;
    case IconNames.LOGO:
      return LogoIcon;
    case IconNames.MUTE:
      return MuteIcon;
    case IconNames.NEW_PAUSE:
      return NewPauseIcon;
    case IconNames.SETTINGS:
      return SettingsIcon;
    case IconNames.UNMUTE:
      return UnmuteIcon;
    case IconNames.NEW_PLAY:
      return NewPlayIcon;
    case IconNames.ADD_ICON:
      return AddIcon;
    case IconNames.REMOVE_ICON:
      return RemoveIcon;
    case IconNames.RED_ADD_ICON:
      return RedAddIcon;
    case IconNames.RED_REMOVE_ICON:
      return RedRemoveIcon;
    case IconNames.RED_CLOSE_ICON:
      return RedCloseIcon;
    default:
      throw new Error('Icon not found');
  }
};
