import React from 'react';

import { Spacing } from '##/components/Spacing';
import { TextElement } from '##/components/TextElement';

export interface IMessageWithSpacingProps {
  text: string;
}

export const MessageWithSpacing: React.FC<IMessageWithSpacingProps> = ({
  text,
}) => (
  <Spacing className="Spacing--sm-bottom" top bottom>
    <TextElement cssClasses={['body1', 'fw500', 'dtLightGrey']}>{text}</TextElement>
  </Spacing>
);
