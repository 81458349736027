/**
 * Here we initialise the tracker. We gather as much data as possible
 * to populate our base properties and pass them to the tracker upon initialisation
 */
import {
  ConsolePlugin,
  LOCATION_PRECISION,
  NielsenPlugin,
  SnowplowPlugin,
} from 'inferno-web-analytics-proxy';
import { v4 as uuid } from 'uuid';

import { app } from '##/utils/constants';
import { getLoginDataFromLocalStorage } from '##/utils/auth/authLocalStorage';
import { getUrlParam } from '##/utils/getUrlParam';
import {
  getValueFromLocalStorage,
  setValueInLocalStorage,
} from '##/utils/storage';

import { getPpid, init as lotameInit } from './lotame';
import infernoTracker from './analytics-tracker';

const { version: APP_VERSION } = app;
const debug = getUrlParam('debug');
const events = getUrlParam('events');
const debugMode = debug && events === 'proxy';

export const DEVICE_ID_KEY = 'device_id';
export const LENGTH_OF_GUID = 36;

const dnt = ():boolean => {
  // get the value from the various browser implementations.
  let dnt_active = parseInt(
    // Internet Explorer 9 and 10 vendor prefix
    navigator.msDoNotTrack ||
    // IE 11 uses window.doNotTrack
    window.doNotTrack ||
    // W3C
    navigator.doNotTrack,
    10
  );
  // If this comes to exactly 1 DNT is set.
  return (dnt_active === 1);
}

export const getDeviceId = () => {
  let deviceId = getValueFromLocalStorage(DEVICE_ID_KEY) || '';
  let updateDeviceId = false;

  // Extract surrounding quotes, if any
  const [, quote, guid] = /^(")?([^"]?.*[^"]?)(\1)$/.exec(deviceId) || [];
  if (quote) {
    deviceId = guid; // Attempt to reuse inner guid
    updateDeviceId = true;
  }

  // Ensure device id length matches length of guid
  if (deviceId.length !== LENGTH_OF_GUID) {
    deviceId = uuid();
    updateDeviceId = true;
  }

  const emptyId = '00000000-0000-0000-0000-000000000000';

  if (dnt()) {
    deviceId = emptyId;
    updateDeviceId = true;
  } else if (deviceId === emptyId) {
    deviceId = uuid();
    updateDeviceId = true;
  }

  if (updateDeviceId) {
    setValueInLocalStorage(DEVICE_ID_KEY, deviceId);
  }

  return deviceId;
};

// https://stackoverflow.com/questions/33444711/js-object-has-property-deep-check/33445095
const hasOwnDeepProperty = (obj, prop) => {
  if (typeof obj === 'object' && obj !== null) {
    // only performs property checks on objects (taking care of the corner case for null as well)
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      // if this object already contains the property, we are done
      return true;
    }

    // otherwise iterate on all the properties of this object
    // and as soon as you find the property you are looking for, return true
    return Object.keys(obj).some(
      (key) =>
        Object.prototype.hasOwnProperty.call(obj, key) &&
        hasOwnDeepProperty(obj[key], prop),
    );
  }

  return false;
};

const updateBaseProperties = (props) => {
  infernoTracker.setProperties({
    category: 'base',
    properties: props,
  });
};

export const updateTrackerUserId = (userId) =>
  updateBaseProperties({ user_id: userId });

export const updateTrackerAdvertiserId = (ppid) =>
  updateBaseProperties({ advertising_id: ppid });

// TODO: Remove this, we only need it now as we're changing the route on channel changes
let initialised = false;
// NOTE: disabling this eslint rule here since Prittier doesnt allow us to add that space
// eslint-disable-next-line space-before-function-paren
export default function({ config, postcode }) {
  if (
    initialised ||
    Object.prototype.hasOwnProperty.call(config, 'analytics') === false
  ) {
    return;
  }

  if (hasOwnDeepProperty(config.analytics, 'snowplow')) {
    // Attach our plugins to the analytics tracker
    infernoTracker.addPlugin(
      new SnowplowPlugin({
        endpoint: config.analytics.snowplow.endpoint || null,
      }),
    );
  }

  if (
    hasOwnDeepProperty(config.analytics, 'nielsen') &&
    config.analytics.nielsen.enabled
  ) {
    infernoTracker.addPlugin(
      new NielsenPlugin({
        apiKey: config.analytics.nielsen.apikey,
        appName: config.analytics.nielsen.appName,
        eventType: 'static',
        cms: 'cms',
      }),
    );
  }

  if (debugMode) {
    infernoTracker.addPlugin(new ConsolePlugin()); // log events to console
  }

  interface IBaseProperties {
    advertising_id: string | null;
    app_id: string | null;
    app_name: string | null;
    app_session_id: string | null;
    app_version: number;
    device_id: string | null;
    device_location: string | null;
    device_location_precision: number;
    device_platform?: string | null;
    device_postcode: string | null;
    device_streaming_type: string | null;
    device_type?: string | null;
    user_id: string | null;
  }

  const baseProperties: IBaseProperties = {
    app_id: config.app.id || null,
    advertising_id: null,
    device_id: getDeviceId(),
    user_id: null,
    app_session_id: uuid(),
    app_name: config.app.name || null,
    app_version: APP_VERSION,
    device_postcode: postcode || null,
    device_location: null /* TODO #6174 */,
    device_location_precision: LOCATION_PRECISION.NETWORK,
    device_streaming_type: null /* TODO #6173 update based on chromecast */,
    // NOTE: we must not set 'device_type' or 'device_platform' to `null` here
    //       it'll cause to always have those as `null`.
    //       a proper refactor of this file should be considered, but right now I'm
    //       just adding a fix for a production issue.
  };

  // Set the device_type and device_platform if specified in the config.
  // TODO: Make config values override base attributes.
  if (config.device_type) {
    baseProperties.device_type = config.device_type;
  }

  if (config.device_platform) {
    baseProperties.device_platform = config.device_platform;
  }

  updateBaseProperties(baseProperties);

  // Initialise the tracker
  infernoTracker.init({ debug: debugMode });
  initialised = true;

  const loginDataFromLocalStorage = getLoginDataFromLocalStorage();

  if (
    loginDataFromLocalStorage &&
    // we should initialise lotame when 'cookieSettings' is null
    (window.swm.cookieSettings === null ||
      // we should initialise lotame when 'cookieSettings.advertising' is true
      (window.swm.cookieSettings?.advertising))
  ) {
    lotameInit(loginDataFromLocalStorage.id).then(() => {
      updateTrackerAdvertiserId(getPpid());
    });
  }
}
