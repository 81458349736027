/**
 * Finds the appropriate styles from an string array list
 * @param  {string[]} cssClasses list of string classes.
 * @param  {styles} styles post css stylesheet.
 * @return {string[]} a list of styles.
 */

export const applyCssFromArray = (cssClasses, styles): string[] => {
  let cssObject: string[] = [];

  if (cssClasses) {
    cssClasses.forEach((cssClass) => {
      cssObject.push(styles[cssClass]);
    });
  }

  return cssObject;
};
