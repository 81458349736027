export const pageName = 'PAGE';
// NOTE: having the 'PAGE' value repeated instead of using template literals functionality
//       and re-using the 'pageName' when creating the constants below
//       because then it would be of the type of string, instead of being
//       of this specific literal type, e.g. 'PAGE/SET_INFO' instead of string
export const SET_INFO = 'PAGE/SET_INFO';
export const SET_PREVENT_CAPI_RELOAD = 'PAGE/SET_PREVENT_CAPI_RELOAD';
export const SET_LIVE_AUTOLOAD = 'PAGE/SET_LIVE_AUTOLOAD';

export interface ISetPreventCapiReloadAction {
  type: typeof SET_PREVENT_CAPI_RELOAD;
  payload: boolean;
}

export interface ISetLiveAutoplayAction {
  type: typeof SET_LIVE_AUTOLOAD;
  payload: boolean;
}

export interface ISetPageInfoPayload {
  canonicalUrl: string;
  hasListingNavigation?: boolean;
  title: string;
  type?: string;
}

export interface ISetPageInfoAction {
  type: typeof SET_INFO;
  payload: ISetPageInfoPayload;
}

export type PageActionType = ISetPageInfoAction | ISetPreventCapiReloadAction | ISetLiveAutoplayAction;

export interface IPageReducerState {
  canonicalUrl: string;
  hasListingNavigation: boolean;
  preventCapiReload: boolean;
  liveAutoplay: boolean;
  title: string;
  type: string;
}
