import { withFormik } from 'formik';
import { CloseYourAccount } from './CloseYourAccount';
import { trackEvent } from '##/utils/tracking/helpers';
import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';

export const EnhancedCloseYourAccountForm = withFormik<any, any>({
  mapPropsToValues: ({ disableAccount }) => ({
    disableAccount,
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    trackEvent(InfernoUniversalEvents.ux.interaction, {
      ux_element_action: 'selected',
      ux_element_type: 'navItem',
      ux_element_descriptor: 'Close account',
      ux_element_index: '1/1',
      ux_element_value: '/close-account',
      ux_element_container_type: 'navArea',
      ux_element_container_value: 'Account',
    });
    props.deleteAllProfile({ ...values, setSubmitting });
  },
  displayName: 'CloseYourAccount',
})(CloseYourAccount);
