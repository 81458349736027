import React from 'react';

const SwiperButtonSvgDef = () => {
  const stops = <><stop offset="0.0124413" stopColor="#2C2C2C" /><stop offset="1" stopColor="#171717" /></>
  const hoverStops = <><stop offset="0.0124413" stopColor="#404040" /><stop offset="1" stopColor="#171717" /></>
  const navigationStops = <><stop offset="0.0454172" stopColor="#2C2C2C" /><stop offset="1" stopColor="#171717" /></>
  const hoverNavigationStops = <><stop offset="0.0454172" stopColor="#404040" /><stop offset="1" stopColor="#171717" /></>

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={0} height={0} style={{margin: 0, display: 'block'}}>
      <defs>
        <radialGradient id="swiper_next" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-12.0557 85.5) scale(117.269 91.2371)">
          {stops}
        </radialGradient>
        <radialGradient id="swiper_next_hover" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-12.0557 85.5) scale(117.269 91.2371)">
          {hoverStops}
        </radialGradient>
        <radialGradient id="swiper_navigation_next" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-7 17) rotate(-1.6617) scale(34.0143 32.3466)">
          {navigationStops}
        </radialGradient>
        <radialGradient id="swiper_navigation_next_hover" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-7 17) rotate(-1.6617) scale(34.0143 32.3466)">
          {hoverNavigationStops}
        </radialGradient>
        <radialGradient id="swiper_previous" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.056 85.5) rotate(-180) scale(117.269 91.2371)">
          {stops}
        </radialGradient>
        <radialGradient id="swiper_previous_hover" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(189.056 85.5) rotate(-180) scale(117.269 91.2371)">
          {hoverStops}
        </radialGradient>
        <radialGradient id="swiper_navigation_previous" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52 17) rotate(-178.338) scale(34.0143 32.3466)">
          {navigationStops}
        </radialGradient>
        <radialGradient id="swiper_navigation_previous_hover" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(52 17) rotate(-178.338) scale(34.0143 32.3466)">
          {hoverNavigationStops}
        </radialGradient>
      </defs>
    </svg>
  )
}

export default SwiperButtonSvgDef;