import React, { Suspense } from 'react';
import { CatchComponentError } from '##/components/CatchComponentError';

// Uses <Suspense /> to Lazy load and <CatchComponentError /> to error bound
// a React.Lazy component :partyTime:
export const RenderReactLazy = (
  Component: React.LazyExoticComponent<any>,
  fallback: JSX.Element | null,
  componentName: string,
  props = {},
  children = null,
): JSX.Element => {
  return (
    <Suspense fallback={fallback}>
      <CatchComponentError componentType={componentName}>
        <Component {...props}>{children}</Component>
      </CatchComponentError>
    </Suspense>
  );
};
