import {
  IDisplayAdReducerState,
  IRefreshDisplayAdAction,
  REFRESH_DISPLAY_AD,
} from './types';

const initialState: IDisplayAdReducerState = {
  refreshAd: false,
};

export function displayAdReducer(
  state = initialState,
  action: IRefreshDisplayAdAction,
): IDisplayAdReducerState {
  switch (action.type) {
    case REFRESH_DISPLAY_AD: {
      return {
        ...state,
        refreshAd: action.payload,
      };
    }
    default:
      return state;
  }
}
