import React, { useRef, useEffect, useState } from 'react';
import styles from './ProgressBar.pcss';
import clsx from 'clsx';

interface IProgressBarProps {
  showProgressBar?: boolean;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ showProgressBar }) => {
  const hidingTimerId = useRef(null);
  const [clsName, setClassName] = useState('hidden');

  const show = () => {
    setClassName('running');
    clearTimeout(hidingTimerId.current);
  };

  const hide = () => {
    setClassName('finishing');
    hidingTimerId.current = setTimeout(toHiddenState, 500);
  };

  const toHiddenState = () => {
    setClassName('hidden');
  };

  useEffect(() => {
    if (showProgressBar) {
      show();
    } else {
      hide();
    }

    return () => {
      clearTimeout(hidingTimerId.current);
    };
  }, [showProgressBar]);

  return (
    <div className={clsx(styles.loader, 'loader', styles[clsName])}>
      <div className={clsx(styles.loaderProgress, 'loaderProgress')} />
    </div>
  );
};

export default ProgressBar;
