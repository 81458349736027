import React, { FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import { trimValue } from '##/utils/formValidation';
import { Button } from '##/components/Button';
import { FormInput } from '##/components/FormInput';
import { TextElement } from '##/components/TextElement';
import { FormGenderButton } from '##/components/FormGenderButton';
import { Spinner } from '##/components/Spinner';
import { IProps } from './constants';
import { usePrevious } from '##/hooks';

import styles from './EditProfileForm.pcss';

const STATUS_CODE_SUCCESS = 200;
const REQUIRED_ERROR_MESSAGE = 'Required';
const LENGTH_ERROR_MESSAGE = 'Must be less than 50 characters';

const GENDER_BUTTONS_ITEMS = [
  { value: 'f', label: 'Female' },
  { value: 'm', label: 'Male' },
  { value: 'u', label: 'Non-binary' },
];

export const EditProfileForm: FC<IProps> = ({
  errors = {},
  touched = {},
  setFieldTouched,
  cancelEditForm,
  handleSubmit,
  handleChange,
  values,
  isSubmitting,
  statusCode,
}) => {
  const [valuesChanged, setValuesHasChanged] = useState(false);
  const [firstNameLengthError, setFirstNameLengthError] = useState('');
  const [lastNameLengthError, setLastNameLengthError] = useState('');
  const [isFocus, setFocus] = useState({
    firstName: false,
    lastName: false,
    birthYear: false,
    postcode: false,
  });

  const prevAmount = usePrevious({ values });

  useEffect(() => {
    if (prevAmount && prevAmount.values !== values) {
      setValuesHasChanged(true);
    }
  }, [values]);

  const checkNameErrorValidation = (
    error,
    value,
    focus,
    touched,
    lengthError,
  ) => {
    if (error && value.length > 0 && !focus) {
      return error;
    } else if (value.length >= 50 && !focus) {
      return lengthError;
    } else if (
      (touched && focus && value.length === 0) ||
      (touched && value.length === 0 && !focus)
    ) {
      return REQUIRED_ERROR_MESSAGE;
    }
    return '';
  };

  /* istanbul ignore next */
  const checkErrorValidation = (error, value, focus, touched) => {
    if (error && value.length > 0 && !focus) {
      return error;
    } else if (
      (touched && focus && value.length === 0) ||
      (touched && value.length === 0 && !focus)
    ) {
      return REQUIRED_ERROR_MESSAGE;
    }
    return '';
  };

  const onFocus = (name, focus) => {
    setFocus((prevState) => ({
      ...prevState,
      [name]: focus,
    }));
    setFieldTouched(name, focus);
  };

  const onBlur = (name, blur) => {
    setFocus((prevState) => ({
      ...prevState,
      [name]: false,
    }));
    setFieldTouched(name, blur);
  };

  useEffect(() => {
    checkingLengthErrors(
      values.firstName.length,
      setFirstNameLengthError,
      50,
      LENGTH_ERROR_MESSAGE,
    );
  }, [values.firstName.length]);

  useEffect(() => {
    checkingLengthErrors(
      values.lastName.length,
      setLastNameLengthError,
      50,
      LENGTH_ERROR_MESSAGE,
    );
  }, [values.lastName.length]);

  const limitFirstNameLength =
    values.firstName.length < 50
      ? values.firstName
      : values.firstName.substring(0, 50);
  const limitLastNameLength =
    values.lastName.length < 50
      ? values.lastName
      : values.lastName.substring(0, 50);

  // function for showing/removing length errors
  const checkingLengthErrors = (
    textValueLength: number,
    setError: Function,
    maxChar: number,
    message: string,
  ) => {
    if (textValueLength >= maxChar) {
      setError(message);
    } else {
      setError('');
    }
  };

  const getIsSubmitButtonDisabled = () => {
    return (
      errors.firstName?.length > 0 ||
      values.firstName?.length >= 50 ||
      errors.lastName?.length > 0 ||
      values.lastName?.length >= 50 ||
      errors.birthYear?.length > 0 ||
      errors.postcode?.length > 0 ||
      errors.gender?.length > 0
    );
  };

  useEffect(() => {
    if (statusCode === STATUS_CODE_SUCCESS && !isSubmitting) {
      cancelEditForm(true);
    }
  }, [isSubmitting, statusCode]);

  return (
    <div className={styles.basicEditProfileContainer}>
      <TextElement
        className={styles.title}
        textType="title"
        cssClasses={['body1', 'white', 'fw600']}
      >
        Edit Details
      </TextElement>
      <form className={styles.editProfileForm} onSubmit={handleSubmit}>
        <FormInput
          label="Email"
          name="email"
          type="email"
          id="emailFormInput"
          disabled
          value={values.email}
          inputfontsize="small"
          placeholderTranslation
        />
        <div className={styles.formWrapper}>
          <FormInput
            label="First name"
            name="firstName"
            type="text"
            id="firstNameFormInput"
            value={limitFirstNameLength}
            onChange={handleChange}
            onBlur={(name, blur) => onBlur(name, blur)}
            onFocus={(name, focus) => onFocus(name, focus)}
            error={checkNameErrorValidation(
              errors.firstName,
              values.firstName,
              isFocus.firstName,
              touched.firstName,
              firstNameLengthError,
            )}
            touched={touched.firstName}
            inputfontsize="small"
            placeholderTranslation
            className={clsx(styles.editProfileInput, {
              [styles.errorInput]:
                (errors.firstName &&
                  values.firstName.length > 0 &&
                  !isFocus.firstName) ||
                (values.firstName.length >= 50 && !isFocus.firstName),
              [styles.hintTextInput]:
                (touched.firstName &&
                  isFocus.firstName &&
                  values.firstName.length === 0) ||
                (touched.firstName &&
                  !isFocus.firstName &&
                  values.firstName.length === 0),
            })}
            maxWidthError
          />
          <FormInput
            label="Last name"
            name="lastName"
            type="text"
            id="lastNameFormInput"
            value={limitLastNameLength}
            onChange={handleChange}
            onBlur={(name, blur) => onBlur(name, blur)}
            onFocus={(name, focus) => onFocus(name, focus)}
            error={checkNameErrorValidation(
              errors.lastName,
              values.lastName,
              isFocus.lastName,
              touched.lastName,
              lastNameLengthError,
            )}
            touched={touched.lastName}
            inputfontsize="small"
            placeholderTranslation
            className={clsx(styles.editProfileInput, {
              [styles.errorInput]:
                (errors.lastName &&
                  values.lastName.length > 0 &&
                  !isFocus.lastName) ||
                (values.lastName.length >= 50 && !isFocus.lastName),
              [styles.hintTextInput]:
                (touched.lastName &&
                  isFocus.lastName &&
                  values.lastName.length === 0) ||
                (touched.lastName &&
                  !isFocus.lastName &&
                  values.lastName.length === 0),
            })}
            maxWidthError
          />
        </div>
        <div className={styles.formWrapper}>
          <FormInput
            label="Birth year"
            name="birthYear"
            type="text"
            id="birthYearFormInput"
            value={values.birthYear}
            onChange={trimValue(handleChange)}
            onBlur={(name, blur) => onBlur(name, blur)}
            onFocus={(name, focus) => onFocus(name, focus)}
            error={checkErrorValidation(
              errors.birthYear,
              values.birthYear,
              isFocus.birthYear,
              touched.birthYear,
            )}
            touched={touched.birthYear}
            inputfontsize="small"
            placeholderTranslation
            className={clsx(styles.editProfileInput, {
              [styles.errorInput]:
                errors.birthYear &&
                values.birthYear.length > 0 &&
                !isFocus.birthYear,
              [styles.hintTextInput]:
                (touched.birthYear &&
                  isFocus.birthYear &&
                  values.birthYear.length === 0) ||
                (touched.birthYear &&
                  !isFocus.birthYear &&
                  values.birthYear.length === 0),
            })}
            maxWidthError
          />
          <FormInput
            label="Postcode"
            name="postcode"
            type="text"
            id="postcodeFormInput"
            value={values.postcode}
            onChange={trimValue(handleChange)}
            onBlur={(name, blur) => onBlur(name, blur)}
            onFocus={(name, focus) => onFocus(name, focus)}
            error={checkErrorValidation(
              errors.postcode,
              values.postcode,
              isFocus.postcode,
              touched.postcode,
            )}
            touched={touched.postcode}
            inputfontsize="small"
            placeholderTranslation
            className={clsx(styles.editProfileInput, {
              [styles.errorInput]:
                errors.postcode &&
                values.postcode.length > 0 &&
                !isFocus.postcode,
              [styles.hintTextInput]:
                (touched.postcode &&
                  isFocus.postcode &&
                  values.postcode.length === 0) ||
                (touched.postcode &&
                  !isFocus.postcode &&
                  values.postcode.length === 0),
            })}
            maxWidthError
          />
        </div>
        <TextElement
          className={styles.genderTitle}
          textType="title"
          cssClasses={['body2', 'silver', 'fw600']}
        >
          Gender
        </TextElement>
        <FormGenderButton
          value={values.gender}
          onChange={handleChange}
          name="gender"
          items={GENDER_BUTTONS_ITEMS}
        />
        <div className={styles.btnWrapper}>
          <div className={styles.cancelWrapper}>
            <Button
              className={styles.cancelButton}
              message="Cancel"
              onClick={() => cancelEditForm(true)}
              cssClasses={[
                'borderRadius1',
                'textRed',
                'biggerFontSize',
                'transparentBorder',
              ]}
            />
          </div>
          <div
            className={clsx(styles.submitWrapper, {
              [styles.isSubmittingWrapper]: isSubmitting,
            })}
          >
            {isSubmitting ? (
              <Spinner />
            ) : (
              <Button
                type="submit"
                message="Save details"
                className={styles.saveDetailsButton}
                cssClasses={
                  getIsSubmitButtonDisabled()
                    ? [
                        'dtRed',
                        'opacity7',
                        'biggerFontSize',
                        'opacityBorder',
                        'borderRadius1',
                      ]
                    : [
                        'dtRed',
                        'biggerFontSize',
                        'transparentBorder',
                        'borderRadius1',
                      ]
                }
                disabled={!valuesChanged || getIsSubmitButtonDisabled()}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
