import {
  Component,
  IMediaPlayer,
  IShelfContainer,
  IChannelInfoPanel,
  IChannelPlayer,
  IEpisodeContainer,
  IImage,
} from '@swm/types-component-api';

import { IComponentIdsByTypeObject } from '##/interfaces/IComponentIdsByTypeObject';
import { ReduxStorableComponentsType } from '##/utils/capi';
import { IShowPageFeatureData } from '##/components/ShowPage/ShowPageTopFeature/constants';

export const componentsName = 'COMPONENTS';

export const STORE_COMPONENT_BY_ID = 'COMPONENTS/STORE_COMPONENT_BY_ID';
export const STORE_COMPONENT_ID_BY_TYPE =
  'COMPONENTS/STORE_COMPONENT_ID_BY_TYPE';
export const UPDATE_VIDEO_COMPONENT = 'COMPONENTS/UPDATE_VIDEO_COMPONENT';

export const UPDATE_LIVE_DYNAMIC_DATA_COMPONENTS =
  'COMPONENTS/UPDATE_LIVE_DYNAMIC_DATA_COMPONENTS';
export const CLEAR_COMPONENTS = 'COMPONENTS/CLEAR_COMPONENTS';

export interface IClearComponentsAction {
  type: typeof CLEAR_COMPONENTS;
  payload: {};
}

export interface IStoreComponentByIdAction {
  type: typeof STORE_COMPONENT_BY_ID;
  payload: ReduxStorableComponentsType;
}

export interface IStoreComponentIdByTypeAction {
  type: typeof STORE_COMPONENT_ID_BY_TYPE;
  payload: Pick<Component, 'id' | 'type'>;
}

export interface IUpdateVideoComponentAction {
  type: typeof UPDATE_VIDEO_COMPONENT;
  payload: IEpisodeContainer & {
    infoPanelData: {
      expiresOn?: string
    }
    cardData: {
      productionYear?: string;
      seriesLogo?: IImage;
      seriesBackgroundImage?: IImage;
    }
  };
}

interface IDDSPlayer extends IChannelPlayer {
  restricted?: boolean;
}

interface ILiveDynamicData {
  videoPlayer: IDDSPlayer;
  infoPanel: IChannelInfoPanel;
}

export interface IUpdateLiveDynamicDataComponentsAction {
  type: typeof UPDATE_LIVE_DYNAMIC_DATA_COMPONENTS;
  payload: ILiveDynamicData;
}

export type ComponentsActionType =
  | IStoreComponentByIdAction
  | IStoreComponentIdByTypeAction
  | IUpdateVideoComponentAction
  | IClearComponentsAction
  | IUpdateLiveDynamicDataComponentsAction;

export interface IComponentsReducerState {
  componentsById:
    | {
        [id: string]:
          | IMediaPlayer
          | IShelfContainer
          | IShowPageFeatureData;
      }
    | {};
  componentIdsByType: IComponentIdsByTypeObject | {};
}
