import { TextElement } from '##/components/TextElement';
import clsx from 'clsx';
import { applyCssFromArray } from '##/utils/applyCssFromArray';
import React, { ReactNode } from 'react';

import styles from './Lozenge.pcss';

interface IProps {
  children?: JSX.Element[] | JSX.Element | ReactNode;
  lozengeLabel?: string;
  cssClasses?: LozengeCssClasses[];
}

type LozengeCssClasses =
  | 'lozenge'
  | 'solid'
  | 'fixedWidth'
  | 'fluidWidth'
  | 'smallWidth'
  | 'red'
  | 'lightRed'
  | 'lightGrey'
  | 'dtDarkGrey'
  | 'blackOpaque'
  | 'borderLightgrey'
  | 'borderRounded'
  | 'squareBorderRadius'
  | 'inline'
  | 'light'
  | 'lightPadded'
  | 'dark'
  | 'dot'
  | 'sideCut'
  | 'bigDot'
  | 'new'
  | 'paddingRight'
  | 'morePaddingRight'
  | 'rectangle';

export const Lozenge: React.FunctionComponent<IProps> = ({
  children,
  lozengeLabel,
  cssClasses,
}: IProps): JSX.Element | null => {
  const cssClass = clsx('Lozenge', applyCssFromArray(cssClasses, styles));
  const text = lozengeLabel || children;

  if (text) {
    return (
      <div className={cssClass} data-test-id="Lozenge">
        <TextElement cssClasses={['lozenge2', 'fw600', 'white']}>
          {text}
        </TextElement>
      </div>
    );
  }

  return null;
};
