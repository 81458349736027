import { env } from '##/utils/constants';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

/* Redux dev tools are only available at devtest environment. */
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */
export const generateStore = (reducer) =>
  createStore(
    reducer,
    window.swm.env === env.local
      ? composeEnhancers(applyMiddleware(thunk))
      : applyMiddleware(thunk),
  );

// TODO: stop using 'generateActionName' and do as `page` types/actions
//       for more details/info, please read explanation in src/state/page/types.ts
// TODO: delete 'generateActionName' once it's not used anymore
export const generateActionName = (scope, type) => [scope, type].join('/');

// TODO: delete 'generateActionNames' once it's not used anymore
export const generateActionNames = (scope, ...types) =>
  types.map((type) => generateActionName(scope, type));

// TODO: delete 'makeAction' once it's not used anymore
export const makeAction = (type, payload) => ({ type, payload });
