import {
  ISetAdBlockerStatusAction,
  ISetAdBlockerDismissedAction,
  SET_AD_BLOCKER_DISMISSED,
  SET_AD_BLOCKER_STATUS,
} from './types';

export const setAdBlockerStatus = (
  isActive: boolean,
): ISetAdBlockerStatusAction => {
  return {
    type: SET_AD_BLOCKER_STATUS,
    payload: isActive,
  };
};

export const setAdBlockerDismissed = (
  isActive: boolean,
): ISetAdBlockerDismissedAction => {
  return { type: SET_AD_BLOCKER_DISMISSED, payload: isActive };
};
