import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const FullscreenIcon: React.FunctionComponent<IIcon> = ({
  width = 27,
  height = 23,
  fill = '#B1A5A5',
}): JSX.Element => (
  <svg
    width="27"
    height="23"
    viewBox="0 0 27 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 15.7586V7.24138H8.69565L13.5652 2V21L8.69565 15.7586H2Z"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
    <path
      d="M26 8L18 16"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
    <path
      d="M18 8L26 16"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default React.memo(FullscreenIcon);
