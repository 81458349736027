import log from 'loglevel';
import detectAdBlock from '##/utils/detectAdBlock';

import { isDevEnvironment } from '##/utils/environment';

const { env, config, versionNumber } = window.swm;

// prints info, warn and error logs only
log.setLevel('info');

if (isDevEnvironment(env)) {
  // prints all logs except .trace()
  log.setLevel('debug');
}

const trackJS = import(/* webpackPreload: true */ 'trackjs').then(
  (module) => module.TrackJS,
);

export const initTrackJs = async () => {
  if (config.trackJs?.token) {
    const isAdBlockerActive = await detectAdBlock();

    const trackJsInstance = await trackJS;
    trackJsInstance.install({
      token: config.trackJs.token,
      application: env.toLowerCase(),
      // Math.random() <= 0.5 is used for control the sampling of tracking events
      // to be 50% of total volume for cost control purpose
      enabled: Math.random() <= 0.5,
      onError: (payload) => {
        payload.metadata.push({
          key: 'ad blocker active',
          value: isAdBlockerActive.toString(),
        });

        payload.metadata.push({
          key: 'version number',
          value: versionNumber,
        });

        return true;
      },
    });
  }
};

export const logError = async (message: string, error?: any) => {
  if (config.trackJs?.token) {
    const trackJsInstance = await trackJS;

    const errorMessage = error ? `error message [${error.message}]` : '';

    trackJsInstance.track(
      `7Plus Web Error [${versionNumber}]: ${message}
      ${errorMessage}`,
    );
  }
};
