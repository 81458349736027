import cookies from 'js-cookie';

// Method taken from https://mylotame.force.com/s/article/Audience-Extraction-API-Setup
export default (ccClientId, ccExtrCallback, portNumber) => {
  /**
  * Will generate the proper audience extraction URL to be used in a
  * JSONP call on the client page.
*/
  let domain = '.crwdcntrl.net';

  // Look for a domain cookie first
  let idCookie = false;
  let id;

  if (cookies.get('_cc_domain')) {
    domain = cookies.get('_cc_domain');
  } else if (cookies.get('_cc_id')) {
    // If there was no domain cookie then check to see if there is an id cookie.
    if (cookies.get('_cc_id')) {
      idCookie = true;
      id = cookies.get('_cc_id');
    }
  }
  let ccUrl = `//ad${domain}`;
  if (typeof (portNumber) !== 'undefined' && portNumber != null) {
    ccUrl = `${ccUrl}:${portNumber}`;
  }
  ccUrl = `${ccUrl}/5/c=${ccClientId}/pe=y/var=${ccExtrCallback}`;
  if (idCookie) {
    ccUrl = `${ccUrl}/pid=${id}`;
  }
  return ccUrl;
};
