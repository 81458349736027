import { connect } from 'react-redux';
import { EnhancedEditProfileForm } from './EnhancedEditProfileForm';
import { userName } from '##/state/user/types';
import { updateAllProfile } from '##/state/user/managers/user';
import { compose } from 'redux';
import { withRouter } from 'react-router';

export const mapStateToProps = (state) => ({
  birthYear: `${state[userName].birthYear || ''}`, // EditProfileForm expects a string
  email: state[userName].email,
  firstName: state[userName].firstName,
  gender: state[userName].gender,
  lastName: state[userName].lastName,
  postcode: `${state[userName].postcode || ''}`, // EditProfileForm expects a string
  statusCode: state[userName].statusCode,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateProfile: (values) => dispatch(updateAllProfile(values)),
});

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EnhancedEditProfileForm);
