import {
  IRegistrationReducerState,
  IS_OPERATING,
  REQUEST_RESET_PASSWORD_BY_EMAIL,
  RegistrationActionTypes,
  SET_DEVICE_REGISTRATION_MESSAGE,
  SET_DUPLICATE_EMAIL,
  SET_EMAIL_VERIFICATION_REQUEST,
  SET_REGISTRATION_ERROR_CODE,
  SET_REGISTRATION_TOKEN,
  SET_RESET_PASSWORD_BY_EMAIL_SUCCESS,
} from './types';

const initialState: IRegistrationReducerState = {
  deviceRegistrationMessage: '',
  duplicateEmail: false,
  emailVerificationRequired: null,
  errorCode: null,
  operationInProgress: false,
  registrationErrorCode: null,
  registrationToken: null,
  resetPassword: {
    email: null,
    isRequesting: false,
    requestPasswordResetSuccess: false,
  },
};

export function registrationReducer(
  state = initialState,
  action: RegistrationActionTypes,
): IRegistrationReducerState {
  switch (action.type) {
    // TODO: IS_OPERATING is used for user too. This should be seperated
    // should be done as part of 12176
    case IS_OPERATING: {
      return {
        ...state,
        errorCode: action.payload ? null : state.errorCode,
        operationInProgress: action.payload as boolean,
      };
    }
    case SET_DEVICE_REGISTRATION_MESSAGE: {
      return {
        ...state,
        deviceRegistrationMessage: action.payload as string,
      };
    }
    case SET_DUPLICATE_EMAIL: {
      return {
        ...state,
        duplicateEmail: action.payload as boolean,
      };
    }
    // merge all error codes
    case SET_REGISTRATION_ERROR_CODE: {
      return {
        ...state,
        registrationErrorCode: action.payload as string | null,
      };
    }
    case SET_REGISTRATION_TOKEN: {
      return {
        ...state,
        registrationToken: action.payload as string | null,
      };
    }
    case SET_EMAIL_VERIFICATION_REQUEST: {
      return {
        ...state,
        emailVerificationRequired: action.payload as boolean | null,
      };
    }
    case REQUEST_RESET_PASSWORD_BY_EMAIL: {
      const payload: { email: string } = action.payload as { email: string };

      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          email: payload.email ? payload.email : null,
          isRequesting: true,
        },
      };
    }
    case SET_RESET_PASSWORD_BY_EMAIL_SUCCESS: {
      const resetPayload: { isSuccess: boolean } = action.payload as {
        isSuccess: boolean;
      };

      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          isRequesting: false,
          requestPasswordResetSuccess: resetPayload.isSuccess,
        },
      };
    }

    default: {
      return state;
    }
  }
}
