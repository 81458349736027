import React, { FC, useMemo, Fragment } from 'react';

import { TextElement } from '##/components/TextElement';

import styles from './BasicInformation.pcss';

interface IBasicInformationProps {
  birthYear?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  lastName?: string;
  postcode?: string;
}

export const BasicInformation: FC<IBasicInformationProps> = ({
  birthYear,
  email,
  firstName,
  gender,
  lastName,
  postcode,
}) => {
  const getGender = useMemo(() => {
    switch (gender) {
      case 'm':
        return 'Male';
      case 'f':
        return 'Female';
      default:
        return 'Non-binary';
    }
  }, [gender]);

  const data = [
    { label: 'Name', value: `${firstName} ${lastName}` },
    { label: 'Email', value: email },
    { label: 'Birth year', value: birthYear },
    { label: 'Postcode', value: postcode },
    { label: 'Gender', value: getGender },
  ];

  return (
    <div className={styles.basicInformationContainer}>
      <div>
        {data.map(({ label, value }) => {
          const hideGender =
            label === 'Gender' &&
            (value === 'Non-binary' || value === undefined);

          return (
            <Fragment key={label}>
              {value && (
                <div className={styles.wrapper}>
                  <TextElement
                    textType="text"
                    cssClasses={['episodeWhiteOpacity6', 'fw600', 'body2']}
                    className={styles.label}
                  >
                    {label}
                  </TextElement>
                  {!hideGender && (
                    <TextElement
                      textType="text"
                      cssClasses={['white', 'fw600', 'body2']}
                      key={value}
                      className={styles.value}
                    >
                      {value}
                    </TextElement>
                  )}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
