import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const StopIcon: React.FunctionComponent<IIcon> = (
  props: IIcon,
): JSX.Element => {
  const { width = 50, height = 50, fill = '#e7131a' } = props;

  return (
    <svg
      width={width}
      height={height}
      aria-hidden="true"
      data-icon="stop"
      role="img"
      viewBox="0 0 50 50"
    >
      <path
        fill={fill}
        /* tslint:disable:max-line-length */
        d="M34.2,35.6H15.8c-0.8,0-1.4-0.6-1.4-1.4V15.8c0-0.8,0.6-1.4,1.4-1.4h18.3c0.8,0,1.4,0.6,1.4,1.4v18.3
        C35.6,34.9,34.9,35.6,34.2,35.6z"
      />
    </svg>
  );
};

export default React.memo(StopIcon);
