import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const LogoIcon: React.FunctionComponent<IIcon> = ({
  width = 36,
  height = 23,
  fill = '#e6001E',
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 36 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5039 23L22.0104 8.15308L17.1102 0L3.64648 23H13.5039Z"
      fill={fill}
    />
    <path
      d="M0 0.00012207L4.8014 8.1532H11.691L16.4525 0.00012207H0Z"
      fill={fill}
    />
    <path
      d="M26.6878 3.36292V8.15292H23.3247L20.8794 12.4204H26.6878V17.2104H31.0655V12.4204H35.884V8.15292H31.0655V3.36292H26.6878Z"
      fill="#E6001E"
    />
  </svg>
);

export default React.memo(LogoIcon);
