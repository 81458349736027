import { RouteComponentProps } from 'react-router-dom';
import { TextElement, TextCssClasses } from '##/components/TextElement';
import { parseQueryString } from '##/utils/queries';
import { getValueFromLocalStorage } from '##/utils/storage';
import React, { useEffect, useState, useRef } from 'react';
import { ILocation } from '##/interfaces/ILocation';
import { IRedirectType } from './constants';

const TIMER_RESOLUTION_MS = 1000;
const TIMER_RESOLUTION_SECONDS = TIMER_RESOLUTION_MS / 1000;
const DEBUGGING_KEY = 'debug-enabled';

interface IProps {
  message: string;
  link: string;
  timeSecs: number;
  location: ILocation;
  className?: string;
  textProps: TextCssClasses[];
}

type QueryResult = {
  redirect?: string;
};

export const RedirectTimerMessage: React.FunctionComponent<
  IProps & RouteComponentProps
> = ({ message, link, timeSecs, location, className = '', textProps = [] }) => {
  const timerRef = useRef(null);

  const splitResult = message.split('{timer}');
  const [textBeforeTime, textAfterTime] = splitResult;

  const [time, setTime] = useState(timeSecs);

  useEffect(() => {
    const timer = setInterval(onTimerFired, TIMER_RESOLUTION_MS);
    timerRef.current = timer;
    return () => {
      clearInterval(timer);
    };
  }, []);

  const redirect = (link: string): void => {
    return window.location.replace(link);
  };

  const isDebugRequest = (queryParams: QueryResult): string | true => {
    return (
      (Object.keys(queryParams).includes('debug') &&
        queryParams.redirect === '0') ||
      getValueFromLocalStorage(DEBUGGING_KEY)
    );
  };

  const isRedirectEnabled = (
    redirect: IRedirectType,
    queryParams: QueryResult,
  ): string => {
    return (
      redirect &&
      !isDebugRequest(queryParams) &&
      typeof redirect.timeSecs === 'number' &&
      redirect.link
    );
  };

  const redirectEnabled = isRedirectEnabled(
    {
      timeSecs,
      link,
    },
    parseQueryString(location.search),
  );

  const onTimerFired = (): void => {
    setTime((prevTime) => {
      let newTimerValue = prevTime - TIMER_RESOLUTION_SECONDS;
      if (newTimerValue <= 0) {
        newTimerValue = 0;
        const timer = timerRef.current;

        clearInterval(timer);

        if (redirectEnabled) {
          redirect(link);
        }
      }

      return newTimerValue;
    });
  };

  return (
    <TextElement cssClasses={textProps} className={className}>
      {splitResult.length > 1
        ? [textBeforeTime, <strong key={1}>{time}</strong>, textAfterTime]
        : message}
    </TextElement>
  );
};
