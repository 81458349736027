import { connect } from 'react-redux';
import { Header } from './Header';
import { registrationName } from '##/state/registration/types';
import { sidebarMenuName, mobileSidebarMenu } from '##/state/sidebarMenu';
import { pageName } from '##/state/page';
import { userName } from '##/state/user/types';

export const mapStateToProps = (state) => ({
  showProgress: state[registrationName].operationInProgress,
  mobileMenuVisible: state[sidebarMenuName].mobileMenuVisible,
  pageType: state[pageName].type,
  isLoggedIn: state[userName].isLoggedIn,
});

export const mapDispatchToProps = (dispatch) => ({
  toggleMobileMenu: () => dispatch(mobileSidebarMenu()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
