import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const NewPauseIcon: React.FunctionComponent<IIcon> = ({
  width = 13,
  height = 25,
  fill = '#fff',
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L2 27M16 2V27"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default React.memo(NewPauseIcon);
