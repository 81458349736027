import clsx from 'clsx';
import React from 'react';

import { RedirectTimerMessage } from '##/components/RedirectTimerMessage';
import { Spacing } from '##/components/Spacing';
import { bem } from '##/utils/bemClasses';
import { ButtonWithSpacing } from './ButtonWithSpacing';
import { ErrorImage } from './ErrorImage';
import { ErrorLozenge } from './ErrorLozenge';
import { LinkWithSpacing } from './LinkWithSpacing';
import { MessageWithSpacing } from './MessageWithSpacing';
import { TitleWithSpacing } from './TitleWithSpacing';

import './ErrorPage.pcss';

const errorPageOverlyClass = bem('ErrorPage', 'overlay');

export type IErrorPageProps = {
  buttonMessage?: string;
  errorMessage: string;
  errorStatus: number;
  errorTitle: string;
  linkMessage?: string;
  linkUrl?: string;
  redirect?: {
    link?: string;
    message?: string;
    timeSecs?: number;
  };
  location?: {
    search?: string;
  };
};

export const ErrorPage: React.FC<IErrorPageProps> = ({
  buttonMessage = 'Continue to 7plus',
  errorMessage,
  errorTitle,
  linkMessage = '',
  linkUrl = '',
  redirect = { timeSecs: 10, link: '/' },
  errorStatus,
}) => {
  const clientError = errorStatus < 500;
  const errorPageClass = clsx('ErrorPage', {
    'ErrorPage--client-error': clientError,
  });

  return (
    <div className={errorPageClass} data-test-id="ErrorPage">
      <ErrorImage errorStatus={errorStatus} errors={window.swm.config.errors} />
      <div className={errorPageOverlyClass}>
        <ErrorLozenge errorStatus={errorStatus} />
        <TitleWithSpacing text={errorTitle} />
        <MessageWithSpacing text={errorMessage} />
        {redirect.message && (
          <Spacing className="Spacing--sm-bottom" top bottom>
            <RedirectTimerMessage {...redirect} />
          </Spacing>
        )}
        <ButtonWithSpacing message={buttonMessage} errorStatus={errorStatus} />
        {linkMessage && linkUrl && (
          <LinkWithSpacing message={linkMessage} url={linkUrl} />
        )}
      </div>
    </div>
  );
};
