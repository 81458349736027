import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { applyCssFromArray } from '##/utils/applyCssFromArray';
import { Text } from './Text';
import { Title } from './Title';
import { SubTitle } from './SubTitle';

import styles from './TextElement.pcss';

// Typing generic css classes for text/title/subtitle
export type TextCssClasses =
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'bodyTitle'
  | 'eeBody4'
  | 'eeBody3'
  | 'footer'
  | 'lozenge1'
  | 'lozenge2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h2Mobile'
  | 'h4Mobile'
  | 'h6Mobile'
  | 'eeH5'
  | 'episodeH5'
  | 'red'
  | 'redNew'
  | 'lightBlue'
  | 'dark'
  | 'white'
  | 'eeGreyWhite'
  | 'eeGrey'
  | 'darkGrey'
  | 'grey'
  | 'silver'
  | 'mediumGrey'
  | 'lightGrey'
  | 'lightestGrey'
  | 'dtLightestGrey'
  | 'dtLighterGrey'
  | 'dtLightGrey'
  | 'dtMediumGrey'
  | 'eeMediumGrey'
  | 'whiteOpacity3'
  | 'episodeWhiteOpacity6'
  | 'episodeWhiteOpacity7'
  | 'whiteOpacity8'
  | 'eeWhiteOpacity5'
  | 'inheritColor'
  | 'eeMediumGreySingle'
  | 'eeDarkGreySingle'
  | 'fw300'
  | 'fw400'
  | 'fw500'
  | 'fw600'
  | 'fw700'
  | 'shadow'
  | 'truncate'
  | 'truncate2'
  | 'truncate3'
  | 'truncate6'
  | 'truncateMobile'
  | 'truncateMobile2'
  | 'underline'
  | 'block'
  | 'card'
  | 'oversize'
  | 'oversizeDesktop'
  | 'oversizedBody1'
  | 'lineHeight17'
  | 'lineHeight15'
  | 'lineHeight13'
  | 'lineHeight12'
  | 'lineHeight14'
  | 'opacity04'
  | 'whiteOpacity3';

type TextType = 'subTitle' | 'title' | 'text';

interface IProps {
  children?: JSX.Element[] | JSX.Element | ReactNode;
  className?: string;
  cssClasses?: TextCssClasses[];
  title?: string;
  textType?: TextType;
}

export const TextElement: React.FunctionComponent<IProps> = ({
  title = '',
  textType,
  children = null,
  className,
  cssClasses,
}: IProps): JSX.Element => {
  const cssClass = clsx(
    textType ? styles[textType] : '',
    className,
    applyCssFromArray(cssClasses, styles),
  );
  switch (textType) {
    case 'subTitle':
      return (
        <SubTitle subTitle={title} cssClass={cssClass}>
          {children}
        </SubTitle>
      );
    case 'title':
      return (
        <Title title={title} cssClass={cssClass}>
          {children}
        </Title>
      );
    case 'text':
    default:
      return <Text cssClass={cssClass}>{children}</Text>;
  }
};
