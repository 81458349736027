import React, { ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  cssClass?: string;
}

export const Text: React.FunctionComponent<IProps> = ({
  children = null,
  cssClass,
}: IProps): JSX.Element => {
  return (
    <span className={cssClass} data-test-id="Text">
      {children}
    </span>
  );
};
