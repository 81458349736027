import { generateActionName } from '##/utils/redux';

export const SCOPE = 'SIDEBAR_MENU';

// TODO: stop using 'generateActionName' and do as `page` types/actions
//       for more details/info, please read explanation in src/state/page/types.ts
export const HIDE_MENUS = generateActionName(SCOPE, 'HIDE_MENUS');

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const SHOW_MENU = 'SHOW_MENU';
