import { makeAction } from '##/utils/redux';
import {
  SET_PLAYING,
  INCREASE_CARD_CLICK_COUNT,
  SET_VOD_PLAYER_VISIBLE,
  TOGGLE_BACKGROUND_PLAYER_AUDIO,
  PlayerActionType,
} from './types';

// TODO: stop using 'makeAction' and type the 'player' reducer and actions
//       as 'page' reducer and actions
export const play = (): PlayerActionType => makeAction(SET_PLAYING, true);
export const pause = (): PlayerActionType => makeAction(SET_PLAYING, false);
export const setVodPlayerVisible = (visible: boolean): PlayerActionType =>
  makeAction(SET_VOD_PLAYER_VISIBLE, visible);
export const registerCardClick = (): PlayerActionType =>
  makeAction(INCREASE_CARD_CLICK_COUNT, null);
export const toggleBackgroundPlayerAudio = (): PlayerActionType =>
  makeAction(TOGGLE_BACKGROUND_PLAYER_AUDIO, null);
