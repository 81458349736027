import { IDynamicDataSource } from '@swm/types-component-api';

import { IJwtData } from '##/utils/auth/authManager';
import { addQueryParamsToUrl } from '##/utils/queries';
import { getCapiHeaders } from '##/utils/capi';
import { logError } from '##/utils/trackJs';

const { versionNumber } = window.swm;

const removeDuplicatedData = (dataSet, idPropertyName) => {
  let data = [];
  let dataIdsArray = [];
  dataSet.forEach((item) => {
    if (!dataIdsArray.includes(item[idPropertyName])) {
      dataIdsArray.push(item[idPropertyName]);
      data.push(item);
    }
  });

  return data;
};

const mergeDataSets = (dataSet, propertyInDDSResponse) => {
  let data = [];
  dataSet.forEach((item) => {
    data = [...data, ...item[propertyInDDSResponse]];
  });

  return data;
};

export const organizeDataSets = (
  dataSet,
  propertyInDDSResponse,
  idPropertyName,
) =>
  removeDuplicatedData(
    mergeDataSets(dataSet, propertyInDDSResponse),
    idPropertyName,
  );

export const getDynamicDataSourceData = async (
  source: IDynamicDataSource,
  getToken: () => Promise<IJwtData | null>,
  signal?,
): Promise<[] | any> => {
  const { authorise, url } = source;
  const tokenResult = authorise ? await getToken() : null;
  const jwt = tokenResult ? tokenResult.token : '';

  const headers = getCapiHeaders({ token: jwt });

  const urlWithParams = addQueryParamsToUrl(url, {
    'platform-version': versionNumber,
  });

  try {
    const response = await fetch(urlWithParams, {
      method: 'GET',
      headers: {
        ...headers,
      },
      signal,
    });

    if (response.status === 404 || response.status === 204) {
      return [];
    }

    return response.json();
  } catch (error) {
    logError(
      `getDynamicDataSourceData - failed to get data from dynamic data source: [${error}]`,
    );

    return [];
  }
};

export const startPollingDynamicDataSource = (
  source: IDynamicDataSource,
  getToken: () => Promise<IJwtData | null>,
  updateState: (response) => void,
  signal,
): NodeJS.Timer =>
  setInterval(async () => {
    const response = await getDynamicDataSourceData(source, getToken, signal);
    updateState(response);
  }, (source?.validFor ? source?.validFor : 0) * 1000);
