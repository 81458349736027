import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const PauseIcon: React.FunctionComponent<IIcon> = (
  props: IIcon,
): JSX.Element => {
  const { width = 50, height = 50, fill = '#e7131a' } = props;

  return (
    <svg
      width={width}
      height={height}
      aria-hidden="true"
      data-icon="pause"
      role="img"
      viewBox="0 0 50 50"
    >
      <path
        fill={fill}
        /* tslint:disable:max-line-length */
        d="M31.2,13.7L31.2,13.7c1.7,0,3.1,1.4,3.1,3.1v15c0,1.7-1.4,3.1-3.1,3.1h0c-1.7,0-3.1-1.4-3.1-3.1v-15
        C28.1,15,29.5,13.7,31.2,13.7z M18.8,13.7L18.8,13.7c1.7,0,3.1,1.4,3.1,3.1v15c0,1.7-1.4,3.1-3.1,3.1h0c-1.7,0-3.1-1.4-3.1-3.1
        v-15C15.7,15,17.1,13.7,18.8,13.7z"
      />
    </svg>
  );
};

export default React.memo(PauseIcon);
