import React from 'react';
import { Image } from '##/components/Image';
import {
  getDimension,
  getSrcSetSizes,
  IBreakpoint,
  IImageSrcSetSizes,
} from '##/utils/images';
import { PredefinedIconNames } from '##/interfaces/IPredefinedIcons';
import clsx from 'clsx';
import styles from '##/components/Navigation/Navigation.pcss';

interface IProps {
  iconName: PredefinedIconNames;
  classname: string;
  color?: string;
  iconSide?: string;
}

const logoWidthPerBreakpoint: IBreakpoint = {
  mobile1: 43,
  mobile2: 43,
  mobile3: 43,
  mobile4: 43,
  tablet: 49,
  desktop1: 49,
  desktop2: 59,
  desktop3: 59,
};

const logoWidth: number = getDimension(logoWidthPerBreakpoint);

const logoSrcSetSizes: IImageSrcSetSizes[] = getSrcSetSizes({
  width: logoWidth,
});

const {
  AUSSIE_EVENT = undefined,
  SCHEDULE = undefined,
  MEDAL = undefined,
  OLYMPICS = undefined,
  WATCH_MORE = undefined,
  LIVE_CIRCLE = undefined,
} = window.swm.config.predefinedIcons || {};

const getIconInfo = (iconName: string, color: string) => {
  const iconSet = {
    [`aussie-event`]: AUSSIE_EVENT,
    [`schedule`]: SCHEDULE,
    [`medal`]: MEDAL,
    [`olympics`]: OLYMPICS,
    [`watch-more`]: WATCH_MORE,
    [`live-circle`]: LIVE_CIRCLE,
  }[iconName];

  return iconSet?.[color] ? iconSet[color] : {};
};

export const PredefinedIcon: React.FC<IProps> = ({
  iconName,
  classname,
  color = 'default',
  iconSide,
}): JSX.Element | null => {
  const { url, alt } = getIconInfo(iconName, color);
  return url ? (
    <Image
      className={clsx(classname, {
        [styles.rightSide]: iconSide === 'right',
      })}
      url={url}
      altTag={alt}
      width={logoWidth}
      srcSetSizes={logoSrcSetSizes}
    />
  ) : null;
};
