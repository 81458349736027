import { BROADCAST_PAGE_TYPE, HOME_PAGE_TYPE } from '../constants';

export const isPageType = (string, pageType) => string === pageType;

export const isPageOneOf = (string, ...pageTypes) => pageTypes.includes(string);

export const isHomePage = (page) => isPageType(page, HOME_PAGE_TYPE);

export const isBroadcastPage = (page) => isPageType(page, BROADCAST_PAGE_TYPE);

const CHANNEL_CAROUSEL_ID = 'channelCarouselId';

export const getPageTitle = (
  pageMetaData,
  defaultPageTitle,
  appendSiteName,
) => {
  if (pageMetaData) {
    if (appendSiteName) {
      return `${pageMetaData.pageTitle} | ${pageMetaData.siteName}`;
    }

    return pageMetaData.pageTitle;
  }

  return defaultPageTitle;
};

export const manipulateCapiData = (capiData) => {
  let { components, items } = capiData;

  // manipulating tab items to combine Season Selector with other tab content
  const indexOfShelfContainerWithTabs: number = Object.values(items).findIndex(
    (itemsItem: any) => itemsItem.type === 'shelfContainer',
  );

  if (indexOfShelfContainerWithTabs >= 0) {
    let tabItems;
    let seasonTabItems;

    // getting array of first level tab items and episode tab item
    tabItems = items[Object.keys(items)[indexOfShelfContainerWithTabs]].items;

    // finding index for tab with season tab items
    const indexForTabWithSeasonTabItems = items[
      Object.keys(items)[indexOfShelfContainerWithTabs]
    ].items.findIndex((element) => element.items?.[0]?.theme === 'redTab');

    // finding tab with season tab items
    const tabItemWithSeasonTabItems = items[
      Object.keys(items)[indexOfShelfContainerWithTabs]
    ].items.find((element) => element.items?.[0]?.theme === 'redTab');

    if (tabItems[indexForTabWithSeasonTabItems]) {
      seasonTabItems = tabItems[indexForTabWithSeasonTabItems].items[0].items;
    }

    if (items) {
      if (seasonTabItems) {
        const newTabItems = seasonTabItems.concat(tabItems);
        items[
          Object.keys(items)[indexOfShelfContainerWithTabs]
        ].items = newTabItems;
        items[Object.keys(items)[indexOfShelfContainerWithTabs]].title =
          tabItemWithSeasonTabItems.items[0].title;
      }

      items[Object.keys(items)[indexOfShelfContainerWithTabs]].theme = 'redTab';
    }

    // removing episode tab item from tab items array
    items[Object.keys(items)[indexOfShelfContainerWithTabs]].items = items[
      Object.keys(items)[indexOfShelfContainerWithTabs]
    ].items.filter((element) => element !== tabItemWithSeasonTabItems);
  }

  if (isBroadcastPage(capiData.type)) {
    //get all channelShelves
    const allChannelShelves = components.filter(
      (item) => item.component.type === 'channelShelf',
    );

    let firstChannelShelfItem = allChannelShelves[0].component;

    const liveSection = components.find((item) => item.type === 'section');
    const liveSectionComponents = liveSection.component.items;
    const channelCarouselData = {
      id: CHANNEL_CAROUSEL_ID,
      allChannelShelves,
      type: 'channelCarousel',
    };

    liveSectionComponents.splice(1, 0, channelCarouselData);
    firstChannelShelfItem.hasLocationHeader = 'true';

    items = {
      ...items,
      [CHANNEL_CAROUSEL_ID]: channelCarouselData,
    };

    return {
      ...capiData,
      items,
    };
  }

  return capiData;
};
