import {
  SET_ACTIVE_TAB,
  SET_DEFAULT_ACTIVE_TAB,
  IActiveTabReducerState,
  ActiveTabActionType,
  SET_ACTIVE_TAB_NAME,
} from './types';

const initialState: IActiveTabReducerState = {
  activeTabIndex: 0,
  activeTabName: '',
};

export default (
  state = initialState,
  action: ActiveTabActionType,
): IActiveTabReducerState => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.payload,
      };
    case SET_DEFAULT_ACTIVE_TAB:
      return {
        ...state,
        activeTabIndex: action.payload,
      };
    case SET_ACTIVE_TAB_NAME:
      return {
        ...state,
        activeTabName: action.payload,
      };
    default:
      return state;
  }
};
