import { makeAction } from '##/utils/redux';
import {
  fetchMarketByGeo,
  fetchMarketByIp,
  loadMarketData,
} from '##/utils/market';
import { ERROR, LOADING, RECEIVE_DATA, SET_MARKET_DATA } from './constants';

const loading = () => makeAction(LOADING, {});
const receiveData = (data) => makeAction(RECEIVE_DATA, data);
const handleError = (error) => makeAction(ERROR, error);

export const setMarketData = (data) => makeAction(SET_MARKET_DATA, data);

export const getMarketDataByGeolocation = () => async (dispatch) => {
  dispatch(loading());

  try {
    dispatch(receiveData(await fetchMarketByGeo()));
  } catch (error) {
    dispatch(handleError(error));
  }
};

const getMarketDataByIp = () => async (dispatch) => {
  try {
    const marketData = await fetchMarketByIp();
    dispatch(receiveData(marketData));
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getMarketData = () => (dispatch) => {
  dispatch(loading());

  const marketData = loadMarketData();

  if (marketData) {
    dispatch(receiveData(marketData));
  } else {
    dispatch(getMarketDataByIp());
  }
};
