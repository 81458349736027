import {
  IMediaPlayer,
  Component,
  IInfoPanel,
  IChannelPlayer,
} from '@swm/types-component-api';

import { INFO_PANEL, MEDIA_PLAYER, SHOW_HEADER } from '##/utils/capi';
import {
  CLEAR_COMPONENTS,
  ComponentsActionType,
  IComponentsReducerState,
  IStoreComponentByIdAction,
  IStoreComponentIdByTypeAction,
  STORE_COMPONENT_BY_ID,
  STORE_COMPONENT_ID_BY_TYPE,
  UPDATE_VIDEO_COMPONENT,
  UPDATE_LIVE_DYNAMIC_DATA_COMPONENTS,
} from './types';

const initialState: IComponentsReducerState = {
  componentsById: {},
  componentIdsByType: {},
};

const getComponentByType = <T>(
  state: IComponentsReducerState,
  componentType: string,
): T | undefined => {
  let component;

  component = Object.values(state.componentsById).find(
    (comp) => (comp as Component).type === componentType,
  );

  return component;
};

export function componentsReducer(
  state = initialState,
  action: ComponentsActionType,
): IComponentsReducerState {
  switch (action.type) {
    case CLEAR_COMPONENTS: {
      return {
        ...state,
        componentsById: {},
      };
    }
    case STORE_COMPONENT_BY_ID: {
      // Ensuring components are not being added into the store more than once.
      // The components are stored on render, Video JS requires to be re-rendered
      // when updating the video so this prevents multiple Media Player components
      // to be added.
      const { id } = (action as IStoreComponentByIdAction).payload;
      const componentExists = state.componentsById[id];

      if (componentExists) {
        return state;
      }

      return {
        ...state,
        componentsById: {
          ...state.componentsById,
          [id]: action.payload,
        },
      };
    }
    case STORE_COMPONENT_ID_BY_TYPE: {
      const { id, type } = (action as IStoreComponentIdByTypeAction).payload;

      return {
        ...state,
        componentIdsByType: {
          ...state.componentIdsByType,
          [type]: id,
        },
      };
    }
    case UPDATE_VIDEO_COMPONENT: {
      const {
        title,
        videoUrl,
        episodePlayerId,
        restricted,
        image,
        analytics,
        startAt,
      } = action.payload.playerData;

      let currentMediaPlayer;
      let mediaPlayerData;

      currentMediaPlayer = getComponentByType<IMediaPlayer>(
        state,
        SHOW_HEADER,
      );

      mediaPlayerData = {
        cName: '',
        playerId: episodePlayerId,
        image,
        startAt,
        subtitle: title,
        type: 'featuredShowHeader' as const,
        videoUrl,
        analytics,
        restricted,
        airDate: action.payload.infoPanelData.airDate,
        classification: action.payload.infoPanelData.classification,
        genres: action.payload.infoPanelData.genre,
        isClosedCaption: action.payload.infoPanelData.isClosedCaption,
        shortSynopsis: action.payload.infoPanelData.shortSynopsis,
        expiresOn: action.payload.infoPanelData.expiresOn,
        backgroundImage: action.payload.cardData.seriesBackgroundImage,
        productionYear: action.payload.cardData.productionYear,
        duration: action.payload.cardData.duration,
        logo: action.payload.cardData.seriesLogo,
      };

      if (currentMediaPlayer) {
        mediaPlayerData.id = currentMediaPlayer.id;
        mediaPlayerData.type = currentMediaPlayer.type;
        mediaPlayerData.cName = currentMediaPlayer.cName;

        return {
          ...state,
          componentsById: {
            ...state.componentsById,
            [currentMediaPlayer.id]: mediaPlayerData,
          },
        };
      }

      return state;
    }

    case UPDATE_LIVE_DYNAMIC_DATA_COMPONENTS: {
      const { videoPlayer, infoPanel } = action.payload;
      if (!videoPlayer || !infoPanel) {
        return state;
      }

      const currentMediaPlayer = getComponentByType<
        IMediaPlayer | IChannelPlayer
      >(state, MEDIA_PLAYER);

      const currentInfoPanel = getComponentByType<IInfoPanel>(
        state,
        INFO_PANEL,
      );

      const resetExpiredInfoPanelState = {
        contentLink: undefined,
      };

      return {
        ...state,
        componentsById: {
          ...state.componentsById,
          [state.componentIdsByType[MEDIA_PLAYER]]: {
            ...currentMediaPlayer,
            ...videoPlayer,
            // this needs to be updated as new response
            // from dds may not have this prop
            restricted: videoPlayer.restricted || false,
            type: MEDIA_PLAYER,
          },
          [state.componentIdsByType[INFO_PANEL]]: {
            ...currentInfoPanel,
            ...resetExpiredInfoPanelState,
            ...infoPanel,
            type: INFO_PANEL,
          },
        },
      };
    }

    default:
      return state;
  }
}
