export const SHOW_SUB_NAVIGATION = 'SHOW_SUB_NAVIGATION';
export const CLOSE_SUB_NAVIGATION = 'CLOSE_SUB_NAVIGATION';

export const navigation = 'NAVIGATION';

export interface IShowSubNavigationAction {
  type: typeof SHOW_SUB_NAVIGATION;
  payload: string;
}

export interface ICloseSubNavigationAction {
  type: typeof CLOSE_SUB_NAVIGATION;
}

export interface ISubNavigationState {
  subNavigationLink: string;
}

export type NavigationActionTypes =
  | IShowSubNavigationAction
  | ICloseSubNavigationAction;
