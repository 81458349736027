import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const MuteIcon: React.FunctionComponent<IIcon> = ({
  width = 26,
  height = 23,
  fill = '#B1A5A5',
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 15.7586V7.24138H8.69565L13.5652 2V21L8.69565 15.7586H2Z"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
    <path
      d="M17.8696 18.5218C21.5195 18.5218 24.4783 15.378 24.4783 11.5C24.4783 7.62201 21.5195 4.47827 17.8696 4.47827"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
    <path
      d="M17.8696 13.5652C18.7821 13.5652 19.5218 12.6406 19.5218 11.5C19.5218 10.3594 18.7821 9.43481 17.8696 9.43481"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinejoin="round"
    />
  </svg>
);

export default React.memo(MuteIcon);
