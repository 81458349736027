import { logError } from '##/utils/trackJs';

let init = false;
let trac;

export default (
  sdkInit: (arg0: { cid: string; content: string; server: string }) => any,
): void => {
  try {
    if (sdkInit) {
      if (!init) {
        trac = sdkInit({ cid: 'au-snol', content: '0', server: 'secure-gl' });
        trac.record().post(); // intial call on pageload
        init = true;
      } else {
        trac.pageEvent(window.location.href); // Nielsen v60 NSE tracking
      }
    } else {
      logError('Failed to track page view - Nielsen SDK not loaded');
    }
  } catch (error) {
    logError('Nielsen init error', error);
  }
};
