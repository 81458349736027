import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';

import { setBaseUXProperties, trackEvent } from '##/utils/tracking/helpers';

const getContentTitle = (mediaPlayer, title) =>
  mediaPlayer?.analytics
    ? mediaPlayer.analytics.channelName || mediaPlayer.analytics.seriesTitle
    : title;

const getContentSubtitle = (mediaPlayer, title): string =>
  mediaPlayer ? mediaPlayer.title : title;

export const trackUxScreenEnter = (
  app: string,
  components,
  isAdBlockerActive: boolean,
  title: string,
) => {
  setBaseUXProperties(app, isAdBlockerActive);

  const mediaPlayer = components.find(
    (item) => item.component.type === 'mediaPlayer',
  );

  trackEvent(InfernoUniversalEvents.ux.screenEnter, {
    content_title: getContentTitle(mediaPlayer, title),
    content_subtitle: getContentSubtitle(mediaPlayer, title),
    referring_link: document.referrer,
  });
};
