import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const PlayIcon: React.FunctionComponent<IIcon> = ({
  width = 50,
  height = 50,
  fill = '#e7131a',
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    aria-hidden="true"
    data-icon="play"
    role="img"
    viewBox="0 0 46 50"
  >
    <path
      fill={fill}
      /* tslint:disable:max-line-length */
      d="M17.9,14.8l15.7,9.1c0.7,0.4,1,1.3,0.5,2.1c-0.1,0.2-0.3,0.4-0.5,0.5l-15.7,9.1c-0.7,0.4-1.6,0.2-2.1-0.5
        c-0.1-0.2-0.2-0.5-0.2-0.8V16.1c0-0.8,0.7-1.5,1.5-1.5C17.4,14.6,17.7,14.7,17.9,14.8z"
    />
  </svg>
);

export default React.memo(PlayIcon);
