import React, { ErrorInfo, ReactNode } from 'react';

import styles from './CatchComponentError.pcss';
import { logError } from '##/utils/trackJs';

interface IState {
  error: Error;
  errorInfo: {
    componentStack: string;
  } | null;
}
interface IProps {
  componentType: string;
}
export class CatchComponentError extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { componentType } = this.props;
    this.setState({
      error,
      errorInfo,
    });

    logError(
      `Error rendering component ${componentType}: ${errorInfo.componentStack}`,
    );
  }

  render(): ReactNode {
    const { logLevel } = window.swm;
    const { error, errorInfo } = this.state;

    // If running locally display the stack trace for debugging
    if (errorInfo) {
      return (
        logLevel === 'debug' && (
          <div className={styles.componentErrorContainer}>
            <h2>Stack Trace.</h2>
            <details className={styles.componentError}>
              {error?.toString()}
              <br />
              {errorInfo.componentStack}
            </details>
          </div>
        )
      );
    }

    // Render component fine if no error.
    return this.props.children;
  }
}
