import { DEFAULT_MARKET_DATA } from '##/utils/constants';
import { loadMarketData } from '##/utils/market';
import { ERROR, LOADING, RECEIVE_DATA, SET_MARKET_DATA } from './constants';

const initialState = {
  error: null,
  loading: false,
  data: loadMarketData() || DEFAULT_MARKET_DATA,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case RECEIVE_DATA:
      return { ...state, loading: false, data: action.payload };
    case SET_MARKET_DATA:
      return { ...state, loading: false, data: action.payload };
    case ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
