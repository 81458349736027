import React from 'react';
import AnimateHeight from 'react-animate-height';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { SubNavigationItem } from './SubNavigationItem';
import { SubNavigationLiveItem } from './SubNavigationLiveItem';

import { ISubNavigationSection } from '##/interfaces/INavigation';
import ArrowBack from '##/assets/img/back-to-show-page-arrow.svg';

import styles from './SubNavigation.pcss';

interface IDropdownSectionProps {
  sectionInfo: ISubNavigationSection;
  onSubNavigationItemClick: (event) => void;
}

export interface ISubNavigationProps {
  subNavigationSections: ISubNavigationSection[];
  onSubNavigationItemClick(event): void;
  isSubNavOpen: boolean;
  containerTitle?: string;
  containerType?: string;
  isMobile?: boolean;
  subLinkDestination?: string;
  subLinkText?: string;
}

export const SubNavigationSection: React.FC<IDropdownSectionProps> = ({
  sectionInfo: { title, items, menuHeading },
  onSubNavigationItemClick,
}): JSX.Element => {
  // Check if the section has any items with type gridNavItem
  const hasGridNavItems = items?.some((item) => item.type === 'gridNavItem');

  return (
    <div className={styles.subNavigationSection}>
      <div className={styles.sectionTitle}>
        <p>{title || menuHeading}</p>
      </div>
      {hasGridNavItems && (
        <div className={styles.gridContainer}>
          <div className={styles.sectionItemsLiveWrapper}>
            {items?.map((item, index) => (
              <SubNavigationLiveItem
                item={item}
                itemIndex={`${index + 1}/${items.length}`}
                onSubNavigationItemClick={onSubNavigationItemClick}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
      {!hasGridNavItems && (
        <div className={styles.subNavigationDropdownSectionItems}>
          {items?.map((item, index) => (
            <SubNavigationItem
              item={item}
              itemIndex={`${index + 1}/${items.length}`}
              onSubNavigationItemClick={onSubNavigationItemClick}
              key={index}
            />
          ))}
        </div>
      )
      }
    </div>
  );
}

export const SubNavigation: React.FC<ISubNavigationProps> = ({
  subNavigationSections,
  onSubNavigationItemClick,
  isSubNavOpen,
  isMobile = false,
  subLinkDestination,
  subLinkText,
}): JSX.Element => {
  const renderSections = () =>
    subNavigationSections.map((section, index) => (
      <SubNavigationSection
        key={index}
        sectionInfo={section}
        onSubNavigationItemClick={onSubNavigationItemClick}
      />
    ));

  const renderSubLink = () => (
    <div className={styles.subLinkContainer}>
      <Link
        to={subLinkDestination}
        className={clsx(styles.subLink, 'reset-link')}
      >
        <span>{subLinkText}</span>
        <img src={ArrowBack} alt={subLinkText} />
      </Link>
    </div>
  );

  if (isMobile) {
    return (
      <AnimateHeight
        duration={200}
        height={isSubNavOpen ? 'auto' : 0}
        className={styles.subNavigationDropdown}
      >
        {renderSections()}
        {subLinkDestination && renderSubLink()}
      </AnimateHeight>
    );
  }

  return (
    <div className={styles.subNavigation}>
      {renderSections()}
      {subLinkDestination && renderSubLink()}
    </div>
  );
};
