import infernoTracker from '##/utils/tracking/analytics-tracker';
import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';
import { v4 as uuid } from 'uuid';
import { IEvent, ITrackEvent } from '##/interfaces';
import { VideoJsPlayer } from 'video.js';

export const setBasePlayerProperties = (player) => {
  infernoTracker.setProperties({
    category: 'player',
    properties: {
      event_group: 'Player',
      player_screen_height: player.currentHeight(),
      player_screen_width: player.currentWidth(),
      player_stream_quality: 'auto' /* FIXME */,
      player_video_session_id: uuid(),
    },
  });
};
export const setBaseUXProperties = (
  pageEventName: string,
  isAdBlockerActive: boolean,
) => {
  infernoTracker.setProperties({
    category: 'ux',
    properties: {
      adblocker_used: isAdBlockerActive,
      deeplink: window.location.href,
      event_group: 'UX',
      screen_name: pageEventName, // ie. "broadcastPage", "showPage"...
      screen_path: window.location.pathname + window.location.search,
    },
  });
};

export const trackEvent = (event: IEvent, payload: ITrackEvent) => {
  infernoTracker.trackEvent({
    eventMetadata: event,
    eventProperties: payload,
  });
};

export const trackSignInRegisterEvent = ({
  action,
  type,
  descriptor,
  value,
}) => {
  trackEvent(InfernoUniversalEvents.ux.interaction, {
    ux_element_action: action,
    ux_element_descriptor: descriptor,
    ux_element_index: null,
    ux_element_type: type,
    ux_element_value: value,
  });
};

export const trackButtonEvent = ({
  action,
  type,
  descriptor,
  value,
  index,
  containerType,
  containerValue,
}) => {
  trackEvent(InfernoUniversalEvents.ux.interaction, {
    ux_element_action: action,
    ux_element_descriptor: descriptor,
    ux_element_index: index,
    ux_element_type: type,
    ux_element_value: value,
    ux_element_container_type: containerType,
    ux_element_container_value: containerValue,
  });
};

export const trackPlayerCCSettings = (
  player: VideoJsPlayer,
  type: string,
  descriptor: string | null,
  index: string | null,
  value: string | null,
): any => {
  const videoType = player.swmOptions.videoType || 'vod';

  trackEvent(InfernoUniversalEvents.ux.interaction, {
    ux_element_action: 'selected',
    ux_element_type: type,
    ux_element_descriptor: descriptor,
    ux_element_index: index,
    ux_element_value: value,
    ux_element_container_type: `${videoType}Player`,
    ux_element_container_value: player.swmOptions.title,
    ux_element_container_index: player.swmOptions.episodePlayerId,
  });
};
