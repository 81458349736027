/* eslint-disable react/button-has-type */
import { createBemClass } from '##/utils/bemClasses';
import clsx from 'clsx';
import React from 'react';

import './Button.pcss';

export type ButtonCssClasses =
  | 'green'
  | 'lightBlue'
  | 'noBackground'
  | 'redGradient'
  | 'dtRed'
  | 'resetBtn'
  | 'rounded'
  | 'small'
  | 'textWhite'
  | 'white'
  | 'noBorder'
  | 'whiteBordered'
  | 'smallerFontSize'
  | 'transparentBorder'
  | 'borderRadius1'
  | 'spinner'
  | string;

export interface IButtonProps {
  children?: JSX.Element | string;
  className?: string;
  cssClasses?: ButtonCssClasses[];
  disabled?: boolean;
  message?: string;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
  url?: string;
  type?: 'button' | 'reset' | 'submit';
  target?: string;
}

export const Button: React.SFC<IButtonProps> = ({
  children,
  className,
  cssClasses,
  disabled,
  message,
  onClick,
  url,
  type = 'button',
  target,
}) => {
  const BLOCK = 'Button';

  // This is code here is here because the implementation of cssClasses should be used with post css and this
  // code is simply here to not break anything, when migrating file to pcss, please implement cssClasses properly
  const bemCssClasses = {};
  /* istanbul ignore next */
  if (cssClasses) {
    cssClasses.forEach((className) => (bemCssClasses[className] = true));
  }

  const linkClass = createBemClass(BLOCK, bemCssClasses);
  const buttonClass = clsx(`${BLOCK}__element`, className);

  return (
    // FIXME: A button element inside an anchor element is invalid and likely to
    // cause issues with browser events!
    <a className={linkClass} href={url} onClick={onClick} target={target}>
      <button className={buttonClass} disabled={disabled} type={type}>
        {message || children}
      </button>
    </a>
  );
};
