import { lazy } from 'react';

/**
 * NOTE: we need to export the components below with this specific name so it matches
 * with capi component names so src\utils\renderComponent.tsx works properly
 */

const Banner = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Banner" */ '##/components/Banner'
    ),
);
const Carousel = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Carousel" */ '##/components/Carousel'
    ),
);
const ChannelShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ChannelShelf" */ '##/components/ChannelShelf'
    ),
);
const ChannelCarousel = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ChannelCarousel" */ '##/components/ChannelCarousel'
    ),
);
const ConnectDevice = lazy(
  () =>
    import(
      /* webpackChunkName: "ConnectDevice" */ '##/components/ConnectDevice'
    ),
);
const CookiePage = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "CookiePage" */ '##/components/CookiePage'
    ),
);
const DisplayAd = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "DisplayAd" */ '##/components/DisplayAd'
    ),
);
const EmailVerification = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "EmailVerification" */ '##/components/EmailVerification'
    ),
);
const EpisodeCard = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "EpisodeCard" */ '##/components/EpisodeCard'
    ),
);
const Footer = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Footer" */ '##/components/Footer'
    ),
);
const TopFeature = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "CtaTop" */ '##/components/ExperienceEvolution/TopFeature'
    ),
);
const ButtonPanel = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "HeroBanner" */ '##/components/ButtonPanel'
    ),
);
const IFrame = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "IFrame" */ '##/components/IFrame'
    ),
);
const ImageCard = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ImageCard" */ '##/components/ImageCard/ImageCard'
    ),
);
const ImageShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ImageShelf" */ '##/components/ImageShelf'
    ),
);
const InfoPanelFullWidth = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "InfoPanelFullWidth" */ '##/components/InfoPanelFullWidth'
    ),
);
const LayoutContainer = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LayoutContainer" */ '##/components/LayoutContainer'
    ),
);
const MarkdownPanel = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "MarkdownPanel" */ '##/components/MarkdownPanel'
    ),
);
const MediaShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "MediaShelf" */ '##/components/MediaShelf'
    ),
);
const InteractiveMediaShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "InteractiveMediaShelf" */ '##/components/InteractiveMediaShelf'
    ),
);
const Notification = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Notification" */ '##/components/Notification'
    ),
);
const Player = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Player" */ '##/components/Player'
    ),
);
const PromoShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "PromoShelf" */ '##/components/PromoShelf'
    ),
);
const Search = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Search" */ '##/components/Search'
    ),
);
const ResetPassword = lazy(
  () =>
    import(
      /*webpackChunkName: "ResetPassword" */ '##/components/ResetPassword'
    ),
);
const Shelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Shelf" */ '##/components/Shelf'
    ),
);
const NavigationShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Shelf" */ '##/components/ExperienceEvolution/NavigationShelf/'
    ),
);
const Tab = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "Tab" */ '##/components/Tab'
    ),
);
const FeatureShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "FeatureShelf" */ '##/components/ExperienceEvolution/FeatureShelf'
    ),
);
const ShowPageFeatureShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "ShowPageFeatureShelf" */ '##/components/ShowPage/ShowPageTopFeature'
    ),
);
const LiveChannelSwitch = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LiveChannelSwitch" */ '##/components/LiveChannelSwitch'
    ),
);
const UpcomingShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "UpcomingShelf" */ '##/components/UpcomingShelf'
    ),
);
const UnifiedNavigationShelf = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "LiveChannelSwitch" */ '##/components/UnifiedNavigationShelf'
      ),
);
const { config } = window.swm;

/* istanbul ignore next */
export const selectComponentExpEv = (componentName: string) => {
  const expEvComponentData = {
    topFeature: {
      version: 1,
      oldComponent: null,
      newComponent: TopFeature,
    },
  };

  const selector = expEvComponentData[componentName];

  return config?.features?.experienceEvolution?.[
    `version${selector.version}`
  ] && selector
    ? selector.newComponent
    : selector.oldComponent;
};

export type IGetComponentOptions = { pageType: string; componentCname: string };

/* istanbul ignore next */
export const getComponent = (componentName: string) => {
  switch (componentName) {
    case 'banner': {
      return Banner;
    }
    case 'carousel': {
      return Carousel;
    }
    case 'displayAd': {
      return DisplayAd;
    }
    case 'channelShelf': {
      return ChannelShelf;
    }
    case 'channelCarousel': {
      return ChannelCarousel;
    }
    case 'connectDevice': {
      return ConnectDevice;
    }
    case 'contentLinkedImage': {
      return ImageCard;
    }
    case 'emailVerification': {
      return EmailVerification;
    }
    case 'EpisodeCard': {
      return EpisodeCard;
    }
    case 'footer': {
      return Footer;
    }
    case 'imageShelf': {
      return ImageShelf;
    }
    case 'navigationShelf': {
      return NavigationShelf;
    }
    case 'infoPanelFullWidth': {
      return InfoPanelFullWidth;
    }
    case 'inputField': {
      return Search;
    }
    case 'layoutContainer': {
      return LayoutContainer;
    }
    case 'markdownPanel': {
      return MarkdownPanel;
    }
    case 'mediaPlayer': {
      return Player;
    }
    case 'featuredShowHeader': {
      return ShowPageFeatureShelf;
    }
    case 'mediaShelf': {
      return MediaShelf;
    }
    case 'unifiedNavigationShelf': {
      return UnifiedNavigationShelf;
    }
    case 'interactiveMediaShelf': {
      return InteractiveMediaShelf;
    }
    case 'notificationPanel': {
      return Notification;
    }
    case 'resetPassword': {
      return ResetPassword;
    }
    case 'SeriesCard': {
      return ImageCard;
    }
    case 'shelfContainer': {
      return Shelf;
    }
    case 'smartShelf': {
      return LiveChannelSwitch;
    }
    case 'promoTileShelf': {
      return PromoShelf;
    }
    case 'tab': {
      return Tab;
    }
    case 'iFrame': {
      return IFrame;
    }
    case 'ctaTop': {
      return selectComponentExpEv('topFeature');
    }
    case 'buttonPanel': {
      return ButtonPanel;
    }
    case 'webCookies': {
      return CookiePage;
    }
    case 'featureShelf': {
      return FeatureShelf;
    }
    case 'upcomingShelf': {
      return UpcomingShelf;
    }

    default:
      break;
  }
};
