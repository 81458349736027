import {
  TOGGLE_COOKIE_NOTICE,
  SET_IS_GDPR_COUNTRY,
  CookieNoticeActionType,
} from './types';

export function toggleCookieNotice(): CookieNoticeActionType {
  return {
    type: TOGGLE_COOKIE_NOTICE,
    payload: null,
  };
}

export function setIsGDPRCountry(
  isGDPRCountry: boolean,
): CookieNoticeActionType {
  return {
    payload: isGDPRCountry,
    type: SET_IS_GDPR_COUNTRY,
  };
}
