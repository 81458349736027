import React from 'react';
import { getIcon } from '../../utils/icons/getIcon';
import styles from './CloseProgressRing.pcss';
import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';
import { trackEvent } from '##/utils/tracking/helpers';

export interface ICloseProgressRing {
  stroke: number;
  progress: number;
  color: string;
  fillColor: string;
  icon: string;
  timeSec: number;
  onClose(showing: boolean, closeWithTimeout: boolean): void;
  alertMessage?: string;
}

export const CloseProgressRing: React.FC<ICloseProgressRing> = ({
  stroke,
  progress,
  color,
  fillColor,
  icon,
  timeSec,
  onClose,
  alertMessage,
}) => {
  const IconElement = getIcon(icon);
  const iconSize = 9;
  const radius = 11;

  const normalizedRadius: number = radius - stroke;
  const circumference: number = normalizedRadius * 2 * Math.PI;

  const strokeDashoffset: number =
    circumference - (progress / (timeSec - 0.2)) * circumference;

  return (
    <div
      className={styles.closeProgressRing}
      onClick={() => {
        onClose(false, true);
        trackEvent(InfernoUniversalEvents.ux.interaction, {
          ux_element_action: 'selected',
          ux_element_type: 'closeButton',
          ux_element_descriptor: 'close',
          ux_element_index: 'na',
          ux_element_value: 'internal routing',
          ux_element_container_type: 'flashAlert',
          ux_element_container_value: alertMessage,
        });
      }}
    >
      <span className={styles.trackCircle} />
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className={styles.indicatorCircle}
          fill={fillColor}
          stroke={color}
          strokeWidth={stroke}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={strokeDashoffset}
          r={normalizedRadius}
          cx={radius * -1}
          cy={radius}
        />
      </svg>
      <div className={styles.closeIcon}>
        <IconElement width={iconSize} height={iconSize} />
      </div>
    </div>
  );
};
