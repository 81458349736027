import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Component } from '@swm/types-component-api';

import {
  storeComponentById,
  storeComponentIdByType,
} from '##/state/components/actions';

import {
  shouldStoreComponentInRedux,
  ReduxStorableComponentsType,
} from '##/utils/capi';

export const useStoreComponentsInRedux = (items: Component[]) => {
  const dispatch = useDispatch();

  const storeComponentsInRedux = (item: Component) => {
    dispatch(storeComponentById(item as ReduxStorableComponentsType));

    dispatch(storeComponentIdByType(item));
  };

  useEffect(() => {
    items
      .filter((item) => shouldStoreComponentInRedux(item.type))
      .forEach(storeComponentsInRedux);
  }, []);
};
