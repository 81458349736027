import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const NewPlayIcon: React.FunctionComponent<IIcon> = ({
  width = 17,
  height = 27,
  fill = '#fff',
  className
}): JSX.Element => (
  <svg className={className} width={width} height={height} viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.25 3.89959V23.0998L13.4148 13.4997L3.25 3.89959ZM0.75 1.69566C0.75 0.335992 2.37578 -0.364782 3.36427 0.568786L15.8626 12.3728C16.5103 12.9845 16.5103 14.0149 15.8626 14.6265L3.36427 26.4306C2.37578 27.3641 0.75 26.6634 0.75 25.3037V1.69566Z" fill={fill} />
  </svg>
);

export default React.memo(NewPlayIcon);
