import { withFormik } from 'formik';
import { ChangePasswordForm } from './ChangePasswordForm';
import { FormValues } from './constants';
import { getChangePasswordSignInYupSchema } from '##/utils/formValidation';

export const EnhancedChangePasswordForm = withFormik<any, FormValues>({
  validationSchema: getChangePasswordSignInYupSchema(),
  mapPropsToValues: ({ oldPassword, newPassword, confirmPassword }) => ({
    oldPassword,
    newPassword,
    confirmPassword,
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.changePassword({ ...values, setSubmitting, isPasswordChange: true });
  },
  displayName: 'ChangePasswordForm',
})(ChangePasswordForm);
