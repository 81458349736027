export { playerName } from './types';

export {
  play,
  pause,
  registerCardClick,
  setVodPlayerVisible,
  toggleBackgroundPlayerAudio,
} from './actions';

export { default } from './reducer';
