/**
 * Get Url Parameter
 * @param  {string} query Parameter query
 * @param  {string=} url  Optional Url to parse
 * @return {string|boolean} Returns parameter value if present or boolean
 *
 * Docs:
 * http://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
 */
export const getUrlParam = (query, url = window.location.href) => {
  const queryString = query.replace(/[[]]/g, '\\$&');
  const regex = new RegExp(`[?&]${queryString}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) {
    return null;
  } else if (results[0] && !results[2]) {
    return true;
  }

  const val = decodeURIComponent(results[2].replace(/\+/g, ' '));

  if (val === 'undefined') {
    return undefined;
  }

  return val === 'true' ? true : val;
};
