import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const ExternalLinkIcon: React.FunctionComponent<IIcon> = (
  props: IIcon,
): JSX.Element => {
  const { className, width = 30, height = 30 } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      aria-hidden="true"
      data-icon="external link"
      role="img"
      viewBox="0 0 30 30"
    >
      <g fillRule="evenodd">
        <path
          fillRule="nonzero"
          /* tslint:disable-next-line max-line-length */
          d="M23 22.5a.5.5 0 0 0-.5.5v-.5h.5zm-.5-5.5H25v6a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h4.972v2.5H7.5v15h15V17zm-15 6a.5.5 0 0 0-.5-.5h.5v.5zm0-15.5H7a.5.5 0 0 0 .5-.5v.5zm-.5 0a.5.5 0 0 0 .5-.5v.5H7zm.5 0v15h15V17H25v6a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h4.972v2.5H7.5zm0 15.5a.5.5 0 0 0-.5-.5h.5v.5zm15.5-.5a.5.5 0 0 0-.5.5v-.5h.5z"
        />
        <path d="M22.5 12.5L25 15V7a2 2 0 0 0-2-2h-8l2.5 2.5h5v5z" />
        <path
          fillRule="nonzero"
          d="M16.384 15.384a1.25 1.25 0 0 1-1.768-1.768l8-8a1.25 1.25 0 0 1 1.768 1.768l-8 8z"
        />
      </g>
    </svg>
  );
};

export default React.memo(ExternalLinkIcon);
