import { ISchedule, IEpisodeContainer } from '@swm/types-component-api';
import { History } from 'history';

import { ReduxStorableComponentsType } from '##/utils/capi';
import { ComponentTypeAndId } from '##/interfaces/IComponentIdsByTypeObject';

import {
  CLEAR_COMPONENTS,
  IClearComponentsAction,
  IStoreComponentByIdAction,
  IStoreComponentIdByTypeAction,
  STORE_COMPONENT_BY_ID,
  STORE_COMPONENT_ID_BY_TYPE,
  UPDATE_VIDEO_COMPONENT,
  UPDATE_LIVE_DYNAMIC_DATA_COMPONENTS,
  IUpdateVideoComponentAction,
} from './types';
import { getDynamicDataSourceData } from '##/utils/dynamicDataSource';
import { getToken } from '##/utils/auth/authManager';
import { Dispatch, AnyAction } from 'redux';
import { isLoggedIn } from '##/utils/auth';
import { getSignInRoute } from '##/utils/queries';

export function clearComponents(): IClearComponentsAction {
  return {
    payload: [],
    type: CLEAR_COMPONENTS,
  };
}

export function storeComponentById(
  type: ReduxStorableComponentsType,
): IStoreComponentByIdAction {
  return {
    payload: type,
    type: STORE_COMPONENT_BY_ID,
  };
}

export function storeComponentIdByType(
  componentIdByType: ComponentTypeAndId,
): IStoreComponentIdByTypeAction {
  return {
    payload: componentIdByType,
    type: STORE_COMPONENT_ID_BY_TYPE,
  };
}

export function updateVideoComponent(
  url: string,
  history: History,
  episodeContainer: IEpisodeContainer,
): IUpdateVideoComponentAction {
  // Updating URL with the appropriate video

  const { restricted } = episodeContainer.playerData;

  history.push(url);

  if (restricted && !isLoggedIn()) {
    setTimeout(() => {
      const urlWithParams = getSignInRoute(location.search);
      history.push(urlWithParams);
    });
  }

  return {
    payload: episodeContainer,
    type: UPDATE_VIDEO_COMPONENT,
  };
}

export function updateDynamicDataComponents(
  schedules: ISchedule,
  history: History,
): (dispatch: Dispatch<AnyAction>) => Promise<void> {
  return async (dispatch) => {
    if (!schedules.sourceList) {
      return;
    }
    const results = await Promise.all(
      schedules.sourceList.map((source) =>
        getDynamicDataSourceData(source, () => getToken(dispatch)),
      ),
    );

    const response = Object.assign({}, ...results);
    const { restricted } = response.videoPlayer;

    if (restricted && !isLoggedIn()) {
      const urlWithParams = getSignInRoute(location.search);
      history.push(urlWithParams);
    }

    dispatch({
      payload: response,
      type: UPDATE_LIVE_DYNAMIC_DATA_COMPONENTS,
    });
  };
}
