import {
  SET_CHANNEL_ITEM_HOVER_INDEX,
  IChannelItemHoverIndexReducerState,
  ChannelItemHoverIndexActionType,
} from './types';

const initialState: IChannelItemHoverIndexReducerState = {
  channelItemHoverIndex: null,
};

export default (
  state = initialState,
  action: ChannelItemHoverIndexActionType,
): IChannelItemHoverIndexReducerState => {
  switch (action.type) {
    case SET_CHANNEL_ITEM_HOVER_INDEX:
      return {
        ...state,
        channelItemHoverIndex: action.payload,
      };
    default:
      return state;
  }
};
