import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import SearchIcon from '##/assets/img/icon_search.svg';
import MenuIcon from '##/assets/img/icon_user_menu.svg';
import WatchlistIcon from '##/assets/img/icon_watchlist.svg';
import { Link, useLocation } from 'react-router-dom';
import styles from './Header.pcss';
import { SignInWidget } from '##/components/SignInWidget';
import AnimateHeight from 'react-animate-height';
import clsx from 'clsx';
import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';
import { trackEvent } from '##/utils/tracking/helpers';
import { PATHS } from '##/utils/route';

export const PATH_SEARCH = '/search';
const PATH_WATCHLIST = '/shows';

interface IProps {
  toggleMobileMenu(): void;
  checkIfDropdownIsOpen(dropDownOpen: boolean): void;
  isLoggedIn: boolean;
}
export const HeaderControls: React.FC<IProps> = ({
  toggleMobileMenu,
  checkIfDropdownIsOpen,
  isLoggedIn,
}) => {
  const { pathname } = useLocation();
  let location = useLocation();
  const { pageLink, enabled } =
    window.swm && window.swm.config && window.swm.config.watchList;

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const toggleDropDown = () => {
    checkIfDropdownIsOpen(!dropDownOpen);
    setDropDownOpen(!dropDownOpen);
  };

  const closeDropDown = useCallback(() => {
    checkIfDropdownIsOpen(false);
    setDropDownOpen(false);
  }, []);

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)',
  });

  useEffect(closeDropDown, [location, closeDropDown]);

  useEffect(() => {
    if (dropDownOpen) {
      setDropDownOpen(false);
    }
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        divRef.current &&
        event.target &&
        !divRef.current.contains(event.target as Node)
      ) {
        checkIfDropdownIsOpen(false);
        setDropDownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    toggleMobileMenu();
    toggleDropDown();
  };

  const handleNavigationLinkClick = () => {
    trackEvent(InfernoUniversalEvents.ux.interaction, {
      ux_element_action: 'selected',
      ux_element_type: 'navItem',
      ux_element_descriptor: 'Watchlist',
      ux_element_index: '2/3',
      ux_element_value: '/watchlist',
      ux_element_container_type: 'routePage',
      ux_element_container_value: 'Nav - Web',
    });
  };

  const watchlistPageUrl = () => {
    if (isLoggedIn) {
      return '/watchlist';
    } else if (!isLoggedIn && location.pathname === '/watchlist') {
      return `watchlist?overlay=${PATHS.SIGN_IN}`;
    }
    return '/watchlist';
  };

  return (
    <div ref={divRef}>
      <div className={styles.headerControlsContainer}>
        <Link to={PATH_SEARCH} data-test-id="Link">
          <img
            className={clsx(styles.headerSearchIcon, {
              [styles.headerIconActive]: pathname === PATH_SEARCH,
            })}
            src={SearchIcon}
            alt="Search 7plus"
          />
        </Link>
        {enabled && pageLink && pageLink.enabled && pageLink.path && (
          <Link
            onClick={handleNavigationLinkClick}
            to={watchlistPageUrl}
            data-test-id="Link"
          >
            <img
              className={clsx(styles.headerWatchedListIcon, {
                [styles.headerIconActive]: pathname === PATH_WATCHLIST,
              })}
              src={WatchlistIcon}
              alt="watchlist icon"
            />
          </Link>
        )}

        <button
          aria-label="Show menu"
          className={styles.headerMenuButton}
          onClick={handleClick}
          type="button"
        >
          <img src={MenuIcon} alt="Navigation menu" />
        </button>
      </div>

      {isDesktopOrLaptop && (
        <div>
          <AnimateHeight
            className={styles.headerControlsDropdownMenu}
            duration={100}
            height={dropDownOpen ? 'auto' : 0}
          >
            <SignInWidget closeDropDown={closeDropDown} />
          </AnimateHeight>
        </div>
      )}
    </div>
  );
};
