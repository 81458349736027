import { connect } from 'react-redux';
import { deleteAllProfile } from '##/state/user/managers/user';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { userName } from '##/state/user/types';
import { setError, setStatusCode } from '##/state/user/actions';
import { EnhancedCloseYourAccountForm } from './EnhancedCloseYourAccountForm';

export const mapStateToProps = (state) => ({
  disableAccount: false,
  statusCode: state[userName].statusCode,
  errorCode: state[userName].errorCode,
  provider: state[userName].provider,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteAllProfile: (values) => dispatch(deleteAllProfile(values, ownProps)),
  setStatusCode: (statusCode) => dispatch(setStatusCode(statusCode)),
  setErrorCode: (error) => dispatch(setError(error)),
});

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(EnhancedCloseYourAccountForm);
