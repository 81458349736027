import camelCase from 'lodash/camelCase'

/**
 * Makes keys in Json object camelCase
 * @param  {Object} json object.
 * @return {string} json object.
 */

export const camelizeKeys = (obj: object) => {
    if (Array.isArray(obj)) {
      return obj.map(v => camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: camelizeKeys(obj[key]),
        }),
        {},
      );
    }
    return obj;
};