import React from 'react';
import clsx from 'clsx';
import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';
import { Button } from '##/components/Button';
import { ButtonLink } from '##/components/ButtonLink';
import { ILocation } from '##/interfaces/ILocation';
import { getRegisterRoute, getSignInRoute } from '##/utils/queries';
import { trackEvent } from '##/utils/tracking/helpers';
import { TextElement } from '##/components/TextElement';
import { IButtonItemType } from './constants';
import UserIcon from '##/assets/img/icon_white_user.svg';

import styles from './SignInWidget.pcss';

const REGISTER_TEXT = 'Register';
const SIGNIN_TEXT = 'Sign in';
const SIGNOUT_TEXT = 'Sign out';
const CONNECT_TEXT = 'Connect your TV';

interface IProps {
  closeDropDown?(): void;
  closeMenu(): void;
  history: History;
  isLoggedIn: boolean;
  location: ILocation;
  provider: string;
  onLogoutClick(history: History, provider: string): void;
  firstName?: string;
}

// curried function that handles the events and vibility of menu/dropdows
const onButtonClickHandler =
  ({ closeDropDown, closeMenu, text, index, url }) =>
  () => {
    trackEvent(InfernoUniversalEvents.ux.interaction, {
      ux_element_action: 'selected',
      ux_element_type: 'navItem',
      ux_element_descriptor: text,
      ux_element_index: index,
      ux_element_value: url,
      ux_element_container_type: 'navArea',
      ux_element_container_value: text,
    });

    // closes mobile menu
    closeMenu();

    // only passed in to desktop view so need to check that
    // it has been passed in before calling
    if (closeDropDown) {
      closeDropDown();
    }
  };

export const SignInWidget: React.FC<IProps> = ({
  closeDropDown,
  closeMenu,
  history,
  isLoggedIn,
  location: { search },
  onLogoutClick,
  provider,
  firstName,
}) => {
  const { enabled } = window.swm.config.signIn;

  const loggedInItems = [
    {
      background: 'transparent',
      className: styles.signInWidgetSignOutButton,
      cssClasses: ['noBackground', 'noBorder'],
      label: SIGNOUT_TEXT,
      onClick: () => {
        onLogoutClick(history, provider);
        if (closeDropDown) {
          closeDropDown();
        }
      },
      type: 'action',
    },
    {
      background: 'dt-red',
      className: styles.signInWidgetAccountButton,

      onClick: onButtonClickHandler({
        text: 'Account',
        closeMenu,
        closeDropDown,
        index: '2/3',
        url: '/manage-account',
      }),
      type: 'link',
      url: '/manage-account',
      children: (
        <div className={styles.redImageButtonContent}>
          <img src={UserIcon} alt="Red User Icon" />
          Account
        </div>
      ),
    },
    {
      background: 'transparent',
      className: styles.signInWidgetConnectButton,
      label: CONNECT_TEXT,
      onClick: onButtonClickHandler({
        text: CONNECT_TEXT,
        closeMenu,
        closeDropDown,
        index: '1/3',
        url: '/connect',
      }),
      type: 'link',
      url: '/connect',
    },
  ];

  const loggedOutItems = [
    {
      background: 'dt-red',
      className: styles.signInWidgetSignInButton,
      label: SIGNIN_TEXT,
      onClick: onButtonClickHandler({
        text: SIGNIN_TEXT,
        closeMenu,
        closeDropDown,
        index: '3/3',
        url: getSignInRoute(search),
      }),
      type: 'link',
      url: getSignInRoute(search),
    },
    {
      background: 'white',
      className: styles.signInWidgetRegisterButton,
      label: REGISTER_TEXT,
      onClick: onButtonClickHandler({
        text: REGISTER_TEXT,
        closeMenu,
        closeDropDown,
        index: '2/3',
        url: getRegisterRoute(search),
      }),
      type: 'link',
      url: getRegisterRoute(search),
    },
    {
      background: 'transparent',
      className: styles.signInWidgetConnectButton,
      label: CONNECT_TEXT,
      onClick: onButtonClickHandler({
        text: CONNECT_TEXT,
        closeMenu,
        closeDropDown,
        index: '1/3',
        url: getRegisterRoute(search),
      }),
      type: 'link',
      url: '/connect',
    },
  ];

  const renderButtons = (items: IButtonItemType[]): JSX.Element[] => {
    return items.map((item, index) => {
      if (item.type === 'action') {
        return item.disabled ? null : (
          <div key={`${item.label}-${index}`} className={styles.signOutWrapper}>
            <TextElement className={styles.firstName}>{firstName}</TextElement>
            <Button
              cssClasses={item.cssClasses}
              message={item.label}
              onClick={item.onClick}
              className={item.className}
            />
          </div>
        );
      }
      return (
        <ButtonLink
          key={`${item.label}-${index}`}
          background={item.background}
          url={item.url}
          onClick={item.onClick}
          className={item.className}
        >
          {item.label || item?.children}
        </ButtonLink>
      );
    });
  };

  return !enabled ? null : (
    <div className={styles.signInWidget}>
      <div
        className={clsx(styles.signInWidgetButtonContainer, {
          [styles.signInWidgetLoggedIn]: isLoggedIn,
        })}
      >
        {isLoggedIn
          ? renderButtons(loggedInItems)
          : renderButtons(loggedOutItems)}
      </div>
    </div>
  );
};
