import { HEADER_IS_FLOATING, HeaderActionTypes, IHeaderState } from './types';

const initialState: IHeaderState = {
  isFloatingHeader: false,
};

export function headerReducer(
  state = initialState,
  action: HeaderActionTypes,
): IHeaderState {
  switch (action.type) {
    case HEADER_IS_FLOATING:
      return {
        ...state,
        isFloatingHeader: action.payload,
      };
    default:
      return state;
  }
}
