import React from 'react';

import { Image } from '##/components/Image';
import {
  IBreakpoint,
  IImageSrcSetSizes,
  getDimension,
  getSrcSetSizes,
} from '##/utils/images';

export const imageWidthPerBreakpoint: IBreakpoint = {
  mobile1: 162,
  mobile2: 162,
  mobile3: 162,
  mobile4: 162,
  tablet: 162,
  desktop1: 244,
  desktop2: 244,
  desktop3: 244,
};

interface IErrorsType {
  BAD_REQUEST: {
    imageUrl: string;
  };
  NOT_FOUND: {
    imageUrl: string;
  };
  FORBIDDEN: {
    imageUrl: string;
  };
  UNAUTHORISED: {
    imageUrl: string;
  };
}

export interface IErrorImageProps {
  errorStatus: number;
  errors: IErrorsType;
}

const getImageUrl = (errorStatus: number, errors: IErrorsType) => {
  switch (errorStatus) {
    case 400:
      return errors.BAD_REQUEST.imageUrl;
    case 401:
      return errors.UNAUTHORISED.imageUrl;
    case 403:
      return errors.FORBIDDEN.imageUrl;
    case 404:
      return errors.NOT_FOUND.imageUrl;
    default:
      return '';
  }
};

export const ErrorImage: React.FC<IErrorImageProps> = ({
  errorStatus = null,
  errors,
}) => {
  const imageWidth = getDimension(imageWidthPerBreakpoint);
  const srcSetSizes: IImageSrcSetSizes[] = getSrcSetSizes({
    width: imageWidth,
  });
  const url = getImageUrl(errorStatus, errors);

  return (
    <Image
      url={url}
      srcSetSizes={srcSetSizes}
      width={imageWidth}
      alt={errorStatus ? errorStatus.toString() : ''}
    />
  );
};
