import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const FullscreenIcon: React.FunctionComponent<IIcon> = ({
  width = 22,
  height = 19,
  fill = '#B1A5A5',
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.16279C0 0.520599 0.518854 0 1.15889 0H5.37122V2.32558H2.31779V6.21262H0V1.16279Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.15889 20C0.518854 20 -2.07774e-08 19.4794 -4.64074e-08 18.8372L6.68326e-09 14.629L2.31779 14.629L2.31779 17.6744L5.37122 17.6744L5.37122 20L1.15889 20Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 1.16279C22 0.520599 21.4811 0 20.8411 0H16.6288V2.32558H19.6822V6.21262H22V1.16279Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8411 20C21.4811 20 22 19.4794 22 18.8372L22 14.2082L19.6822 14.2082L19.6822 17.6744L16.2458 17.6744L16.2458 20L20.8411 20Z"
      fill={fill}
    />
  </svg>
);

export default React.memo(FullscreenIcon);
