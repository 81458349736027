import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const LeftArrowIcon: React.FunctionComponent<IIcon> = ({
  width = 26,
  height = 23,
  fill = '#fff',
}): JSX.Element => (
  <svg
    width="26"
    height="23"
    viewBox="0 0 26 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.619 11.5001H2M2 11.5001L11.4245 2M2 11.5001L11.4245 21"
      stroke={fill}
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export default React.memo(LeftArrowIcon);
