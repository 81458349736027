import React from 'react';
import clsx from 'clsx';

import sectionStyles from '##/components/_Section.pcss';
import styles from './MediaSection.pcss';

interface IProps {
  children: JSX.Element | JSX.Element;
}

export const MediaSection: React.FC<IProps> = ({ children }) => (
  <section className={clsx(styles.mediaSection, sectionStyles.sectionPage)}>
    {children}
  </section>
);
