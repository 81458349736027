import { tokenExchange } from '##/utils/auth/authManager';
import {
  DEFAULT_FETCH_TIMEOUT,
  nodeEndpoints,
  REG_SOURCE,
  WEB_PLATFORM_ID,
} from '##/utils/constants';
import { GENERIC_ERROR_MESSAGE } from '##/utils/gigya/constants';
import { getGigyaToken } from '##/utils/gigya/login';
import axios from 'axios';
import { setAccountInfo } from '../gigya/setAccountInfo';
import {
  setStatusCode,
  setSignInSuccess,
  setSignOutSuccess,
} from '##/state/user/actions';
import { Dispatch } from 'redux';

/**
 * Send a request to the node server to get the users profile data.
 * @param {string} token Core auth2 service JWT.
 * @return {Promise<object|Error>}
 */
export async function getProfile(
  token,
  dispatch?: Dispatch,
  isSignIn?: boolean,
) {
  try {
    const res = await axios(nodeEndpoints.USER_PROFILE, {
      headers: {
        authorization: `Bearer ${token}`,
        platformid: WEB_PLATFORM_ID,
        regsource: REG_SOURCE,
      },
      method: 'get',
      timeout: DEFAULT_FETCH_TIMEOUT,
    });

    if (isSignIn) {
      dispatch(setStatusCode(res.status));
      if (res.status === 200) {
        dispatch(setSignOutSuccess(false));
        dispatch(setSignInSuccess(true));
      }
    }
    delete Object.assign(res.data, { postcode: res.data.postCode }).postCode;

    return res.data;
  } catch (err) {
    throw new Error(err || GENERIC_ERROR_MESSAGE);
  }
}

export async function deleteProfile(token) {
  try {
    const res = await axios(nodeEndpoints.DELETE_PROFILE, {
      headers: {
        authorization: `Bearer ${token}`,
        platformid: WEB_PLATFORM_ID,
        regsource: REG_SOURCE,
      },
      method: 'post',
      timeout: DEFAULT_FETCH_TIMEOUT,
    });

    return res;
  } catch (err) {
    throw new Error(err || GENERIC_ERROR_MESSAGE);
  }
}

export async function logoutFromAllDevices(token) {
  try {
    const res = await axios(nodeEndpoints.LOGOUT_FROM_ALL_DEVICES, {
      headers: {
        authorization: `Bearer ${token}`,
        platformid: WEB_PLATFORM_ID,
        regsource: REG_SOURCE,
      },
      method: 'post',
      timeout: DEFAULT_FETCH_TIMEOUT,
    });

    return res;
  } catch (err) {
    throw new Error(err || GENERIC_ERROR_MESSAGE);
  }
}

/**
 * Send a request to the node server to update the users profile data.
 * @param {string} token Core auth2 service JWT.
 * @param {object} data User profile data to update.
 * @return {Promise<void|{errors: object}|Error>}
 */
export async function updateProfile(token, data) {
  try {
    const res = await axios(nodeEndpoints.USER_PROFILE, {
      data: {
        ...data,
        platformId: WEB_PLATFORM_ID,
        regSource: REG_SOURCE,
      },
      headers: {
        authorization: `Bearer ${token}`,
      },
      method: 'post',
      timeout: DEFAULT_FETCH_TIMEOUT,
    });

    delete Object.assign(res.data, { postcode: res.data.postCode }).postCode;

    return res;
  } catch (err) {
    if (err.response?.data) {
      return err.response.data;
    }

    throw new Error(err || GENERIC_ERROR_MESSAGE);
  }
}

export async function changePassword(data) {
  try {
    const res = await setAccountInfo({
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    });

    return res;
  } catch (err) {
    if (err.response?.data) {
      return err.response.data;
    }

    throw new Error(err || GENERIC_ERROR_MESSAGE);
  }
}

/**
 * Same as the `updateProfile()` method but first exchange a Gigya JWT for an
 * auth2 service JWT. This is temporary until we switch to auth2 only.
 * @param {Object} data User profile data to update.
 */
export async function updateProfileWithGigya(data) {
  const gigyaToken = await getGigyaToken();
  const { token } = await tokenExchange(gigyaToken);
  return updateProfile(token, data);
}

export async function changePasswordWithGigya(data) {
  return changePassword(data);
}
