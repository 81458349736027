// adopted from Brightcove's player script bundle: bc.usingAdBlocker
import { browser } from '##/utils/userContext';

let promiseCache: Promise<boolean> | null = null;
const POLL_LIMIT = 3;
const POLL_INTERVAL = 50;

const detectUsingFetch = (): Promise<boolean> => {
  return fetch(
    "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js", {
      method: 'HEAD',
      mode: 'no-cors'
    })
    .then(() => false)
    .catch((err: Error) => err?.message === 'Resource blocked by content blocker');
}

const detectUsingBaitNode = (callback: (value: boolean) => void) => {
  const { body } = document;
  const baitNode = document.createElement('div');
  baitNode.className =
    'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text_ads_2 text-ads text-ad-links';
  baitNode.innerHTML = '&nbsp;';
  baitNode.setAttribute(
    'style',
    'height:10px!important;font-size:20px;color:transparent;position:absolute;bottom:0;left:-10000px',
  );

  let count = 0;
  const detect = () => {
    count += 1;
    // Have we detected an ad blocker or reached the polling limit?
    const detected =
      baitNode.offsetHeight === 0 ||
      !body.contains(baitNode) ||
      baitNode.style.display === 'none' ||
      baitNode.style.visibility === 'hidden';

    if (detected || count === POLL_LIMIT) {
      if (baitNode.parentNode) {
        baitNode.parentNode.removeChild(baitNode);
      }
      promiseCache = null;
      return callback(detected);
    }
    setTimeout(detect, POLL_INTERVAL);
  };

  body.appendChild(baitNode);
  detect();
};

export default function detectAdBlock(): Promise<boolean> {
  if (!promiseCache) {
    promiseCache = new Promise((resolve) => {
      // Check if the DOM is ready.
      if (
        document.readyState === 'complete' ||
        document.readyState !== 'loading'
      ) {
        detectUsingBaitNode(resolve);
      } else {
        document.addEventListener('DOMContentLoaded', () => {
          detectUsingBaitNode(resolve);
        });
      }
    }).then((blocked: boolean) => {
      if (!blocked && browser.safari) {
        return detectUsingFetch();
      }
      return blocked;
    });
  }
  return promiseCache;
}
