import { generateActionNames } from '##/utils/redux';

const { config } = window.swm;

export const SCOPE = 'SEARCH';

// TODO: stop using 'generateActionName' and do as `page` types/actions
//       for more details/info, please read explanation in src/state/page/types.ts
export const [UPDATE_RESULTS, UPDATE_TERM] = generateActionNames(
  SCOPE,
  'UPDATE_RESULTS',
  'UPDATE_TERM',
);

// Setting defaults if config.search cannot be found
export const CHARACTER_SEARCH_LIMIT = config.search
  ? config.search.characterSearchLimit
  : 3;
export const SEARCH_RESULTS_LIMIT = config.search
  ? config.search.searchResultsLimit
  : 30;
export const API_VERSION = config.search ? config.search.apiVersion : '';

export const API_VERSION_SEARCH = '4.7';
