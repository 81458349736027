import clsx from 'clsx';
import React, { useState, useCallback, useMemo } from 'react';
import { NavItem } from './NavItem';
import { useGetNavItemData } from '##/hooks';
import { useLocation } from 'react-router-dom';

import styles from './Navigation.pcss';
import { CatchComponentError } from '../CatchComponentError';

export interface INavigationProps {
  className?: string;
  toggleMobileMenu?(): void;
  isDesktop?: boolean;
}

export const Navigation: React.FunctionComponent<INavigationProps> = ({
  className,
  isDesktop,
  toggleMobileMenu,
}): JSX.Element => {
  const [activeNavigationIndex, setActiveNavigationIndex] = useState(-1);
  const items = useGetNavItemData();
  const { pathname } = useLocation();

  const handleActiveNavigationIndex = useCallback((index) => {
    setActiveNavigationIndex(index);
  }, []);

  const someNavItemTintable = useMemo(() => {
    // If it's home link we don't want other links to look faded
    return (
      items.findIndex(
        (item) => pathname === item.contentLink && pathname !== '/',
      ) > -1
    );
  }, [pathname, items]);

  return (
    <nav
      className={clsx(className, styles.navigation)}
      onClick={toggleMobileMenu}
    >
      {items.map((item, index) => {
        if (!item.contentLink) {
          return;
        }

        const navItemKey = `${item.title}-${index}`;

        return (
          <CatchComponentError componentType="NavItem" key={navItemKey}>
            <NavItem
              className={className}
              contentLink={item.contentLink}
              isDesktop={isDesktop}
              items={item.items}
              navigationIndex={index}
              trackingIndexString={`${index + 1}/${items.length}`}
              tintable={pathname === item.contentLink}
              someNavItemTintable={someNavItemTintable}
              title={item.title}
              predefinedIcon={item.predefinedIcon}
              activeNavigationIndex={activeNavigationIndex}
              handleActiveNavigationIndex={handleActiveNavigationIndex}
              type={item.type}
              pathname={pathname}
              subLinkDestination={item.subLinkDestination}
              subLinkText={item.subLinkText}
            />
          </CatchComponentError>
        );
      })}
    </nav>
  );
};
