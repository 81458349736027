import { ISearchReducerState } from './types';
import { UPDATE_TERM, UPDATE_RESULTS } from './constants';

const initialState: ISearchReducerState = {
  searchTerm: '',
  searchResults: [],
  isLoading: false,
};

// TODO: type the actions as it's done in 'page' reducer/actions
export default (state = initialState, action): ISearchReducerState => {
  switch (action.type) {
    case UPDATE_TERM:
      return { ...state, searchTerm: action.payload, isLoading: true };
    case UPDATE_RESULTS:
      return { ...state, searchResults: action.payload, isLoading: false };
    default:
      return state;
  }
};
