import {
  ISetRegistrationTokenAction,
  ISetRegistrationErrorAction,
  ISetDeviceRegistrationMessageAction,
  ISetEmailVerificationRequestAction,
  ISetDuplicateEmailAction,
  IIsOperatingAction,
  IRequestResetPasswordAction,
  ISetResetPasswordSuccessAction,
  IS_OPERATING,
  REQUEST_RESET_PASSWORD_BY_EMAIL,
  SET_DEVICE_REGISTRATION_MESSAGE,
  SET_DUPLICATE_EMAIL,
  SET_EMAIL_VERIFICATION_REQUEST,
  SET_REGISTRATION_ERROR_CODE,
  SET_REGISTRATION_TOKEN,
  SET_RESET_PASSWORD_BY_EMAIL_SUCCESS,
} from './types';

export function setRegistrationToken(
  registrationToken: string | null,
): ISetRegistrationTokenAction {
  return {
    payload: registrationToken,
    type: SET_REGISTRATION_TOKEN,
  };
}

export function setRegistrationError(
  errorCode: number | null,
): ISetRegistrationErrorAction {
  return {
    payload: errorCode,
    type: SET_REGISTRATION_ERROR_CODE,
  };
}

export function setDeviceRegistrationMessage(
  message: string,
): ISetDeviceRegistrationMessageAction {
  return {
    payload: message,
    type: SET_DEVICE_REGISTRATION_MESSAGE,
  };
}

export function setEmailVerificationRequest(
  emailVerificationRequest: boolean,
): ISetEmailVerificationRequestAction {
  return {
    payload: emailVerificationRequest,
    type: SET_EMAIL_VERIFICATION_REQUEST,
  };
}

export function setDuplicateEmail(
  duplicateEmail: boolean,
): ISetDuplicateEmailAction {
  return {
    payload: duplicateEmail,
    type: SET_DUPLICATE_EMAIL,
  };
}

export function isOperating(operating: boolean): IIsOperatingAction {
  return {
    payload: operating,
    type: IS_OPERATING,
  };
}

export function requestResetPassword(
  email: string,
): IRequestResetPasswordAction {
  return {
    payload: { email },
    type: REQUEST_RESET_PASSWORD_BY_EMAIL,
  };
}

export function setResetPasswordSuccess(
  isSuccess: boolean,
): ISetResetPasswordSuccessAction {
  return {
    payload: { isSuccess },
    type: SET_RESET_PASSWORD_BY_EMAIL_SUCCESS,
  };
}
