import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { InfernoUniversalEvents } from 'inferno-web-analytics-proxy';
import clsx from 'clsx';

import { Image } from '##/components/Image';
import { ISubNavigationSectionItem } from '##/interfaces/INavigation';
import { trackEvent } from '##/utils/tracking/helpers';
import { getSrcSetSizes } from '##/utils/images';

import styles from './SubNavigationLiveItem.pcss';

type SubNavigationLiveItemProps = {
  item: ISubNavigationSectionItem;
  itemIndex: string;
  onSubNavigationItemClick: (event) => void;
  containerType?: string;
  containerTitle?: string;
};

export const SubNavigationLiveItem: React.FC<SubNavigationLiveItemProps> = ({
  item,
  onSubNavigationItemClick,
  itemIndex,
  containerType,
  containerTitle,
}) => {
  const { contentLink, title, icon, iconBackgroundColour: channelColor } = item;
  const { url: iconUrl, alt: iconAlt } = icon;
  const iconQuality = 100;
  const iconHeight = 28;
  const srcSetSizes = getSrcSetSizes({ height: iconHeight });

  const clickHandler = useCallback((e) => {
    onSubNavigationItemClick(e);
    trackEvent(InfernoUniversalEvents.ux.interaction, {
      ux_element_action: 'selected',
      screen_path: item.contentLink,
      ux_element_type: item.type,
      ux_element_descriptor: item.title,
      ux_element_index: itemIndex,
      ux_element_value: item.contentLink,
      ux_element_container_type: containerType,
      ux_element_container_value: containerTitle,
    });
  }, []);

  const hexToRGBA = (hex, alpha) => {
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return (
    <Link
      to={contentLink}
      onClick={clickHandler}
      className={clsx(styles.subNavigationLiveItem, 'reset-link')}
    >
      <div
        className={styles.iconContainer}
        style={{ backgroundColor: `#${channelColor}` }}
      >
        <Image
          url={iconUrl}
          alt={iconAlt}
          quality={iconQuality}
          height={iconHeight}
          srcSetSizes={srcSetSizes}
        />
      </div>
      <div className={styles.titleContainer}>
        <div
          className={styles.gradient}
          style={{
            background: `linear-gradient(90deg, ${hexToRGBA(
              channelColor,
              0.28,
            )} 0%, rgba(0, 0, 0, 0.2) 23.08%, rgba(0, 0, 0, 0) 100%)`,
          }}
        />
        <span>{title}</span>
      </div>
    </Link>
  );
};
