/**
 * Dynamically inject script based on the URL passed in
 * @param  {callbackType(): void} callback Call the function
 * @param  {string} id Identifer for the script tag
 * @param  {string} src URL/Path of the script
 */

type CallbackType = () => void;

export const loadDynamicScript = (
  id: string,
  src: string,
  callback?: CallbackType,
): void => {
  // Prevents adding script to the DOM more than once
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  } else if (existingScript && callback) {
    callback();
  }
};
