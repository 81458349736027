import React from 'react';

import { Spacing } from '##/components/Spacing';

export interface ILinkWithSpacingProps {
  message: string;
  url: string;
}

export const LinkWithSpacing: React.FC<ILinkWithSpacingProps> = ({
  url,
  message,
}) => (
  <Spacing className="Spacing--lg Spacing--md-desktop" top bottom>
    <a className="ErrorPage__link" href={url}>
      {message}
    </a>
  </Spacing>
);
