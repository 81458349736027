import { IJwtData } from '##/utils/auth/authManager';

export const userName = 'USER';
export const SUCCESSFUL_REGISTRATION_MESSAGE = 'Your TV has been registered!';
export const LOGIN_TO_REGISTER_MESSAGE = 'Please sign in to connect your TV';
export const INVALID_CODE_MESSAGE = 'Incorrect code. Please try again';
export const DUPLICATE_EMAIL_STATUS_DETAIL = 'Email already exists';

export const CLEAR_USER_INFO = 'CLEAR_USER_INFO';
export const SET_AUTH_JWT = 'SET_AUTH_JWT';
export const SET_USER_INFO = 'SET_USER_INFO';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const SET_LOGIN_ERROR_CODE = 'SET_LOGIN_ERROR_CODE';
export const SET_PROFILE_INCOMPLETE = 'SET_PROFILE_INCOMPLETE';
export const SET_PROFILE_REQUIRES_PASSWORD = 'SET_PROFILE_REQUIRES_PASSWORD';
export const SET_ERROR = 'SET_ERROR';
export const IS_OPERATING = 'IS_OPERATING';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_STATUS_CODE = 'SET_STATUS_CODE';
export const UPDATE_STORE_FROM_LOCALSTORAGE = 'UPDATE_STORE_FROM_LOCALSTORAGE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SET_EMAIL_VERIFICATION_ERROR = 'SET_EMAIL_VERIFICATION_ERROR';
export const SET_RESET_PASSWORD_SUCCESS = 'SET_RESET_PASSWORD_SUCCESS';
export const SET_SIGN_IN_SUCCESS = 'SET_SIGN_IN_SUCCESS';
export const SET_SIGN_OUT_SUCCESS = 'SET_SIGN_OUT_SUCCESS';
export const SAVE_INVALID_CREDENTIALS = 'SAVE_INVALID_CREDENTIALS';
export const CLEAR_NOTIFICATION_PROMPT = 'CLEAR_NOTIFICATION_PROMPT';
export const SET_CHECK_EMAIL_TO_VERIFY = 'SET_CHECK_EMAIL_TO_VERIFY';
export const SET_CLOSE_ACCOUNT_SUCCESS = 'SET_CLOSE_ACCOUNT_SUCCESS';

export interface IErrorCode {
  errorCode: number;
}

export interface ISetError {
  type: typeof SET_ERROR;
  payload: number | null;
}

export interface ISetAuthResultAction {
  type: typeof SET_AUTH_JWT;
  payload: IJwtData;
}

export interface IClearUserInfoAction {
  type: typeof CLEAR_USER_INFO;
  payload: null;
}

export interface ISetUserInfoAction {
  type: typeof SET_USER_INFO;
  payload: IUserReducerState;
}

export interface IUpdateProfileAction {
  type: typeof UPDATE_USER_INFO;
  payload: Partial<IUserReducerState>;
}

export interface IClearNotificationPrompt {
  type: typeof CLEAR_NOTIFICATION_PROMPT;
  payload: null;
}

export interface ISetSignOutSuccessAction {
  type: typeof SET_SIGN_OUT_SUCCESS;
  payload: boolean | null;
}

export interface ISetSignInSuccessAction {
  type: typeof SET_SIGN_IN_SUCCESS;
  payload: boolean | null;
}

export interface ISetEmailVerificationError {
  type: typeof SET_EMAIL_VERIFICATION_ERROR;
  payload: boolean | null;
}

export interface ISetResetPasswordSuccess {
  type: typeof SET_RESET_PASSWORD_SUCCESS;
  payload: boolean | null;
}

export interface ISetLoginErrorAction {
  type: typeof SET_LOGIN_ERROR_CODE;
  payload: number | null;
}

export interface ISaveInvalidCredentialsAction {
  type: typeof SAVE_INVALID_CREDENTIALS;
  payload: {
    email: string;
    password: string;
  };
}

export interface ISetStatusCodeAction {
  type: typeof SET_STATUS_CODE;
  payload: number | null;
}

export interface ISetProfileIncomplete {
  type: typeof SET_PROFILE_INCOMPLETE;
  payload: boolean | null;
}

export interface IIsOperatingAction {
  type: typeof IS_OPERATING;
  payload: boolean;
}

export interface ISetIsLoggedIn {
  type: typeof SET_IS_LOGGED_IN;
  payload: boolean;
}

export interface IUpdateStoreFromLocalstorage {
  type: typeof UPDATE_STORE_FROM_LOCALSTORAGE;
}

export interface IChangePassword {
  type: typeof CHANGE_PASSWORD;
  payload: boolean | null;
}

export interface ISetCheckYourEmailToVerifyAction {
  type: typeof SET_CHECK_EMAIL_TO_VERIFY;
  payload: boolean;
}

export interface ISetCloseAccountSuccess {
  type: typeof SET_CLOSE_ACCOUNT_SUCCESS;
  payload: boolean;
}

export type UserActionTypes =
  | ISetAuthResultAction
  | ISetLoginErrorAction
  | ISetUserInfoAction
  | ISetStatusCodeAction
  | IClearUserInfoAction
  | ISetProfileIncomplete
  | ISetError
  | IUpdateProfileAction
  | IIsOperatingAction
  | ISetIsLoggedIn
  | IUpdateStoreFromLocalstorage
  | IChangePassword
  | ISetEmailVerificationError
  | ISetResetPasswordSuccess
  | ISetSignInSuccessAction
  | ISetSignOutSuccessAction
  | ISaveInvalidCredentialsAction
  | ISetCheckYourEmailToVerifyAction
  | IClearNotificationPrompt
  | ISetCloseAccountSuccess;

export interface IUserReducerState {
  authData: IJwtData | {};
  birthYear: number | null;
  email: string | null;
  emailVerificationRequired: boolean | null;
  errorCode: number | null;
  firstName: string | null;
  gender: string | null;
  id: string | null;
  imageUrl: string | null;
  isLoggedIn: boolean;
  isVerified: boolean | null;
  lastName: string | null;
  loginErrorCode: number | null;
  operationInProgress: boolean;
  postcode: number | null;
  profileIncomplete: boolean | null;
  provider: string | null;
  verificationRedirectURL?: string;
  hasFullProfileAccount: boolean | null;
  statusCode: number | null;
  isError: boolean | null;
  resetPassword: boolean | null;
  editProfile: boolean | null;
  changePassword: boolean | null;
  signInSuccess: boolean | null;
  signOutSuccess: boolean | null;
  invalidCredentials: {
    invalidEmail: string | null;
    invalidPassword: string | null;
  };
  checkYourEmail: boolean | null;
  closeAccount: boolean | null;
}
