export const registrationName = 'REGISTRATION';

export const IS_OPERATING: string = 'IS_OPERATING';
export const SET_DEVICE_REGISTRATION_MESSAGE: string =
  'SET_DEVICE_REGISTRATION_MESSAGE';
export const SET_DUPLICATE_EMAIL: string = 'SET_DUPLICATE_EMAIL';
export const SET_ERROR: string = 'SET_ERROR';
export const SET_REGISTRATION_ERROR_CODE: string =
  'SET_REGISTRATION_ERROR_CODE';
export const SET_REGISTRATION_TOKEN: string = 'SET_REGISTRATION_TOKEN';
export const SET_EMAIL_VERIFICATION_REQUEST: string =
  'SET_EMAIL_VERIFICATION_REQUEST';
export const REQUEST_RESET_PASSWORD_BY_EMAIL: string =
  'REQUEST_RESET_PASSWORD_BY_EMAIL';
export const SET_RESET_PASSWORD_BY_EMAIL_SUCCESS: string =
  'SET_RESET_PASSWORD_BY_EMAIL_SUCCESS';
export const SUCCESSFUL_REGISTRATION_MESSAGE: string =
  'Your TV has been registered!';
export const INVALID_CODE_MESSAGE: string = 'Incorrect code. Please try again';

export interface ISetRegistrationTokenAction {
  type: typeof SET_REGISTRATION_TOKEN;
  payload: string | null;
}

export interface ISetRegistrationErrorAction {
  type: typeof SET_REGISTRATION_ERROR_CODE;
  payload: number | null;
}

export interface ISetDeviceRegistrationMessageAction {
  type: typeof SET_DEVICE_REGISTRATION_MESSAGE;
  payload: string;
}

export interface ISetEmailVerificationRequestAction {
  type: typeof SET_EMAIL_VERIFICATION_REQUEST;
  payload: boolean | null;
}

export interface ISetDuplicateEmailAction {
  type: typeof SET_DUPLICATE_EMAIL;
  payload: boolean;
}

export interface IIsOperatingAction {
  type: typeof IS_OPERATING;
  payload: boolean;
}

export interface IRequestResetPasswordAction {
  type: typeof REQUEST_RESET_PASSWORD_BY_EMAIL;
  payload: { email: string };
}

export interface ISetResetPasswordSuccessAction {
  type: typeof SET_RESET_PASSWORD_BY_EMAIL_SUCCESS;
  payload: { isSuccess: boolean };
}

export type RegistrationActionTypes =
  | ISetRegistrationTokenAction
  | ISetRegistrationErrorAction
  | ISetDeviceRegistrationMessageAction
  | ISetEmailVerificationRequestAction
  | ISetDuplicateEmailAction
  | IIsOperatingAction
  | IRequestResetPasswordAction
  | ISetResetPasswordSuccessAction;

export interface IRegistrationReducerState {
  deviceRegistrationMessage: string;
  duplicateEmail: boolean;
  emailVerificationRequired: boolean | null;
  errorCode: number | null;
  operationInProgress: boolean;
  registrationErrorCode: string | null;
  registrationToken: string | null;
  resetPassword: {
    email: string | null;
    isRequesting: boolean;
    requestPasswordResetSuccess: boolean;
  };
}
