import React from 'react';
import clsx from 'clsx';
import styles from './FormGenderButton.pcss';

interface IGenderButtonItem {
  value: string;
  label: string;
}

interface IProps {
  value?: string;
  items: IGenderButtonItem[];
  required?: boolean;
  onChange(e: React.FormEvent<HTMLInputElement>): void;
  name: string;
}

interface IGenderButtonProps {
  value: string;
  label: string;
  name: string;
  onChange(e: React.FormEvent<HTMLInputElement>): void;
  required?: boolean;
  checked?: boolean;
}

export const GenderButton: React.FC<IGenderButtonProps> = ({
  value,
  label,
  name,
  onChange,
  required,
  checked,
}: IGenderButtonProps) => (
  <button
    type="button"
    className={clsx(styles.buttonGender, {
      [styles.selectedButtonGender]: checked,
    })}
  >
    <label htmlFor={value}>
      <input
        id={value}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
        required={required}
        checked={checked}
      />
      {label}
    </label>
  </button>
);

export const FormGenderButton: React.FC<IProps> = ({
  items,
  value,
  onChange,
  required,
  name,
}: IProps) => {
  return (
    <div role="radiogroup" className={styles.genderWrapper}>
      {items.map((item, index) => (
        <GenderButton
          name={name}
          label={item.label}
          value={item.value}
          key={index}
          checked={value === item.value}
          onChange={onChange}
          required={required}
        />
      ))}
    </div>
  );
};
