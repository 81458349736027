import { connect } from 'react-redux';
import { userName } from '##/state/user/types';
import { PersonalInfoSectionContent } from './PersonalInfoSectionContent';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import {
  setStatusCode,
  updateProfile,
  setChangePassword,
} from '##/state/user/actions';

export const mapStateToProps = (state) => ({
  statusCode: state[userName].statusCode,
  editProfile: state[userName].editProfile,
  changePassword: state[userName].changePassword,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setStatusCode: (statusCode) => dispatch(setStatusCode(statusCode)),
  updateProfile: (editProfile) => dispatch(updateProfile({ editProfile })),
  setChangePassword: (changePassword) =>
    dispatch(setChangePassword(changePassword)),
});

export default compose<any>(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(PersonalInfoSectionContent);
