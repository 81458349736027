import {
  IAdBlockerReducerState,
  AdBlockerActionType,
  SET_AD_BLOCKER_DISMISSED,
  SET_AD_BLOCKER_STATUS,
} from './types';

const initialState: IAdBlockerReducerState = {
  isAdBlockerOverlayDismissed: false,
  isAdBlockerActive: null,
};

export default (
  state = initialState,
  action: AdBlockerActionType,
): IAdBlockerReducerState => {
  switch (action.type) {
    case SET_AD_BLOCKER_STATUS:
      return {
        ...state,
        isAdBlockerActive: action.payload,
      };
    case SET_AD_BLOCKER_DISMISSED:
      return {
        ...state,
        isAdBlockerOverlayDismissed: action.payload,
      };
    default:
      return state;
  }
};
