import React, { FC } from 'react';
import clsx from 'clsx';

import styles from './AccountManagementPageSection.pcss';

interface IAccountManagementPageSectionProps {
  children: JSX.Element;
  className?: string;
}

export const AccountManagementPageSection: FC<
  IAccountManagementPageSectionProps
> = ({ children, className }) => (
  <div className={clsx(styles.accountManagementPageSection, className)}>
    {children}
    <div className={styles.triangle} />
  </div>
);
