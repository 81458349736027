import React, { FC, useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';

import { AccountManagementPageHeader } from './AccountManagementPageHeader';
import { AccountManagementPageSection } from './AccountManagementPageSection';
import { PersonalInfoSectionContent } from './PersonalInfoSectionContent';
import { HelpSectionContent } from './HelpSectionContent';
import { PATHS } from '##/utils/route';
import { Dialog } from '../Dialog';
import { CloseYourAccount } from './CloseYourAccount';

import styles from './AccountManagementPage.pcss';

interface AccountManagementPageProps {
  isLoggedIn: boolean;
}

export const AccountManagementPage: FC<AccountManagementPageProps> = ({
  isLoggedIn,
}) => {
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/manage-account');
    }
  }, [isLoggedIn]);

  return (
    <>
      {isLoggedIn ? (
        <div className={styles.accountManagementPage}>
          <AccountManagementPageHeader
            setShowCloseAccountModal={setShowCloseAccountModal}
          />
          <div className={styles.mainSection}>
            {/*Personal infomations section */}
            <div className={styles.personalInfoContainer}>
              <AccountManagementPageSection>
                <PersonalInfoSectionContent />
              </AccountManagementPageSection>
            </div>

            {/*Help section */}
            <AccountManagementPageSection className={styles.helpSection}>
              <HelpSectionContent />
            </AccountManagementPageSection>
          </div>
          <Dialog
            isOpen={showCloseAccountModal}
            wide
            canClose
            persistent
            onClose={() => {
              setShowCloseAccountModal(false);
            }}
            noFade
            blackTheme
          >
            <CloseYourAccount />
          </Dialog>
        </div>
      ) : (
        <Redirect to={`manage-account?overlay=${PATHS.SIGN_IN}`} />
      )}
    </>
  );
};
