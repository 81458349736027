import { withFormik } from 'formik';
import { getEditProfileYupSchema } from '##/utils/formValidation';
import { EditProfileForm } from './EditProfileForm';
import { FormValues } from './constants';

export const EnhancedEditProfileForm = withFormik<any, FormValues>({
  validationSchema: getEditProfileYupSchema(),

  mapPropsToValues: ({
    birthYear,
    email,
    firstName,
    gender,
    lastName,
    postcode,
  }) => ({
    birthYear,
    email,
    firstName,
    gender,
    lastName,
    postcode,
  }),
  handleSubmit: (values, { props, setSubmitting }) => {
    props.updateProfile({ ...values, setSubmitting, editProfile: true });
  },
  displayName: 'EditProfileForm',
})(EditProfileForm);
