import Logo from '##/assets/img/7Plus.svg';
import SearchIcon from '##/assets/img/icon_search.svg';
import WatchlistIcon from '##/assets/img/icon_watchlist.svg';
import { Navigation } from '##/components/Navigation';
import { SignInWidget } from '##/components/SignInWidget';
import clsx from 'clsx';
import React, { lazy, Suspense, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import ProgressBar from '##/components/ProgressBar';
import { Link } from 'react-router-dom';
import navigationStyles from '##/components/Navigation/Navigation.pcss';
import { HeaderControls } from './HeaderControls';
import { PATHS } from '##/utils/route';
import styles from './Header.pcss';

const SidebarMenu = lazy(
  () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "SidebarMenu" */ '##/components/SidebarMenu'
    ),
);

interface IProps {
  toggleMobileMenu(): void;
  mobileMenuVisible: boolean;
  showProgress: boolean;
  topOverlap?: boolean;
  isFloating?: boolean;
  pageType?: string;
  isLoggedIn?: boolean;
  showProgressBar?: boolean;
}

export const Header: React.FC<IProps> = ({
  toggleMobileMenu,
  mobileMenuVisible,
  showProgress = false,
  topOverlap,
  isFloating,
  pageType,
  isLoggedIn,
  showProgressBar,
}) => {
  const { pageLink, enabled: watchListEnabled } =
    window.swm && window.swm.config && window.swm.config.watchList;

  const [windowIsOnTop, setWindowIsOnTop] = useState(window.pageYOffset <= 0);
  const [dropDownIsOpen, setDropDownIsOpen] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(
    isFloating && window.pageYOffset <= 0,
  );

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1025px)',
  });

  const handleScroll = () => {
    if (window.pageYOffset <= 0 && !windowIsOnTop) {
      setWindowIsOnTop(true);
    } else if (window.pageYOffset > 0 && windowIsOnTop) {
      setWindowIsOnTop(false);
    }

    handleHeaderHidden();
  };

  const handleHeaderHidden = () => {
    if (window.pageYOffset <= 0 && isFloating) {
      setIsHeaderHidden(true);
    } else if (window.pageYOffset > 0 && isFloating) {
      setIsHeaderHidden(false);
    } else if (!isFloating) {
      setIsHeaderHidden(false);
    }
  };

  useEffect(handleHeaderHidden, [isFloating]);

  useEffect(() => {
    window.document.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.document.removeEventListener('scroll', handleScroll);
    };
  }, [windowIsOnTop, isFloating]);

  const watchlistPageUrl = () => {
    if (isLoggedIn) {
      return '/watchlist';
    } else if (!isLoggedIn && location.pathname === '/watchlist') {
      return `watchlist?overlay=${PATHS.SIGN_IN}`;
    }
    return '/watchlist';
  };

  return (
    <div
      className={clsx(styles.header, {
        [styles.headerTopOverlap]:
          (topOverlap || pageType === 'listingPage') &&
          windowIsOnTop &&
          !dropDownIsOpen,
        [styles.isHidden]: isHeaderHidden && isFloating,
      })}
    >
      <div
        className={clsx(styles.headerProgressContainer, {
          [styles.red]: !showProgressBar,
        })}
      >
        <ProgressBar showProgressBar={showProgressBar} />
      </div>
      <div className={styles.headerInner}>
        <div className={styles.headerNavigation}>
          <Link to="/">
            <img
              className={styles.headerSiteLogo}
              src={Logo}
              alt="7plus logo"
            />
          </Link>
          {isTabletOrMobile && mobileMenuVisible && (
            <Suspense fallback={null}>
              <SidebarMenu>
                <SignInWidget />
                <Navigation toggleMobileMenu={toggleMobileMenu} />
                <Link
                  to="/search"
                  className={clsx(
                    styles.headerMobileSearchLink,
                    navigationStyles.navItemSidebar,
                    'reset-link',
                  )}
                  onClick={() => {
                    toggleMobileMenu();
                  }}
                >
                  <div className={styles.headerMobileSearchIconContainer}>
                    <img
                      className={styles.headerMobileSearchIcon}
                      src={SearchIcon}
                      alt="Search icon"
                    />
                  </div>
                  Search
                </Link>
                {watchListEnabled && pageLink?.enabled && pageLink?.path && (
                  <Link
                    to={watchlistPageUrl}
                    className={clsx(
                      styles.headerMobileWatchlistLink,
                      navigationStyles.navItemSidebar,
                      'reset-link',
                    )}
                    onClick={() => {
                      toggleMobileMenu();
                    }}
                  >
                    <div className={styles.headerMobileWatchlistIconContainer}>
                      <img
                        className={styles.headerMobileWatchlistIcon}
                        src={WatchlistIcon}
                        alt="Watchlist icon"
                      />
                    </div>
                    My List
                  </Link>
                )}
              </SidebarMenu>
            </Suspense>
          )}

          <Navigation className={styles.navitem} isDesktop />
        </div>
        <HeaderControls
          toggleMobileMenu={toggleMobileMenu}
          checkIfDropdownIsOpen={(dropDownOpen) =>
            setDropDownIsOpen(dropDownOpen)
          }
          isLoggedIn={isLoggedIn}
        />
      </div>
    </div>
  );
};
