import { IIcon } from '##/interfaces/IIcon';
import React from 'react';

const RedAddIcon: React.FunctionComponent<IIcon> = ({
  opacity = 0.8,
  className,
}): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    className={className}
  >
    <g opacity={opacity}>
      <path
        d="M11.5747 6.67822L1.07284 6.67822"
        stroke="#E6001E"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6.32324 1.427L6.32324 11.9289"
        stroke="#E6001E"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default React.memo(RedAddIcon);
