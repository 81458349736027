import React, { FC, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';

import { AccountManagementPageSectionTitle } from '../AccountManagementPageSectionTitle';
import UserIcon from '##/assets/img/account-management-page/user-icon.svg';
import { BasicInfomationContainer } from '../BasicInformation';
import { EditProfileForm } from '../EditProfile';
import { Button } from '##/components/Button';
import { isLoggedIn } from '##/utils/auth';
import { PATHS } from '##/utils/route';
import { ChangePasswordForm } from '../ChangePassword';
import { NotificationPrompt } from '##/components/NotificationPrompt/NotificationPrompt';

import styles from './PersonalInfoSectionContent.pcss';

interface IProps {
  statusCode: number | string | null;
  setStatusCode?(statusCode: number | string | null): void;
  editProfile?: boolean;
  updateProfile?(editProfile: boolean): void;
  changePassword?: boolean;
  setChangePassword?(chnagePassword: boolean): void;
}

export const PersonalInfoSectionContent: FC<IProps> = ({
  statusCode,
  setStatusCode,
  editProfile,
  changePassword,
  updateProfile,
  setChangePassword,
}) => {
  useEffect(() => {
    return () => {
      setStatusCode(null);
      setChangePassword(false);
      updateProfile(false);
    };
  }, []);

  const [openEdit, setOpenEdit] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const setCancelEditForm = (cancel) => {
    setOpenEdit(!cancel);
  };

  const setOpenEditForm = () => {
    setOpenChangePassword(false);
    setOpenEdit((prevState) => !prevState);
    setChangePassword(false);
    updateProfile(false);
    setStatusCode(null);
  };

  const setCancelChangePasswordForm = (cancel) => {
    setOpenChangePassword(!cancel);
  };

  const setOpenChangePasswordForm = () => {
    setOpenEdit(false);
    setOpenChangePassword((prevState) => !prevState);
    setChangePassword(false);
    updateProfile(false);
    setStatusCode(null);
  };

  return (
    <div className={styles.personalInfoSectionContent}>
      <div className={styles.titleWrapper}>
        <AccountManagementPageSectionTitle
          title="Personal info"
          icon={UserIcon}
          alt="User Icon"
        />
      </div>
      <div
        className={clsx(styles.wrapper, {
          [styles.notLogged]: openEdit && !isLoggedIn(),
        })}
      >
        {openEdit &&
          (isLoggedIn() ? (
            <EditProfileForm
              cancelEditForm={(cancel) => setCancelEditForm(cancel)}
            />
          ) : (
            <Redirect to={`manage-account?overlay=${PATHS.SIGN_IN}`} />
          ))}

        {openChangePassword && (
          <ChangePasswordForm
            cancelChangePasswordForm={(cancel) =>
              setCancelChangePasswordForm(cancel)
            }
          />
        )}

        {!openEdit && !openChangePassword && <BasicInfomationContainer />}

        <div className={styles.buttonsContainer}>
          <div className={styles.whiteBorderedButtonWrapper}>
            <Button
              message="Change password"
              className={`${
                !openEdit && !openChangePassword && styles.changePasswordButton
              }`}
              onClick={() => setOpenChangePasswordForm()}
              disabled={openEdit || openChangePassword}
              cssClasses={
                openEdit || openChangePassword
                  ? [
                      'noBackground',
                      'whiteBorderedOpacityText',
                      'smallerFontSize',
                      'borderRadius1',
                    ]
                  : [
                      'noBackground',
                      'whiteBordered',
                      'smallerFontSize',
                      'borderRadius1',
                    ]
              }
            />
          </div>
          <Button
            className={`${
              !openEdit && !openChangePassword && styles.editDetailsButton
            }`}
            message="Edit details"
            onClick={() => setOpenEditForm()}
            disabled={openEdit || openChangePassword}
            cssClasses={
              openEdit || openChangePassword
                ? [
                    'dtRed',
                    'opacity7',
                    'smallerFontSize',
                    'opacityBorder',
                    'borderRadius1',
                  ]
                : [
                    'dtRed',
                    'opacity8',
                    'smallerFontSize',
                    'transparentBorder',
                    'borderRadius1',
                  ]
            }
          />
        </div>
      </div>
      {(editProfile || changePassword) && (
        <NotificationPrompt
          statusCode={statusCode}
          openEdit={openEdit}
          openChangePassword={openChangePassword}
          editProfile={editProfile}
          changePassword={changePassword}
        />
      )}
    </div>
  );
};
