import React from 'react';
import clsx from 'clsx';

import { TextElement } from '##/components/TextElement';

import styles from './Checkbox.pcss';

export interface CheckBoxProps {
  value: boolean;
  className?: string;
  name: string;
  label?: string;
  setCheckboxValue?: (checkboxValue: boolean) => void;
  handleChange: (value) => void;
}

export const Checkbox = ({
  value,
  className = '',
  name,
  label = '',
  handleChange,
}: CheckBoxProps): JSX.Element => {
  return (
    <div className={clsx(styles.checkbox, className)}>
      <label htmlFor={name} className={styles.label}>
        <input
          type="checkbox"
          id={name}
          checked={value}
          onChange={handleChange}
          className={styles.hidden}
        />
        <div
          className={clsx(styles.tickContainer, {
            [styles.checkedTickContainer]: value,
          })}
        />
      </label>
      {label && (
        <TextElement
          className={styles.labelText}
          cssClasses={['fw600', 'block', 'white']}
        >
          {label}
        </TextElement>
      )}
    </div>
  );
};
