import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TextElement } from '../TextElement';

import styles from './EmptyWatchlistPageContent.pcss';

export const EmptyWatchlistPageContent: FC = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 567px)',
  });

  return (
    <div className={styles.emptyWatchlistPageContentContainer}>
      <TextElement
        textType="title"
        cssClasses={['fw600', 'white']}
        className={styles.title}
      >
        Your watchlist is currently {isMobile && <br />} empty
      </TextElement>
      <TextElement
        textType="text"
        cssClasses={['fw300', 'white']}
        className={styles.text}
      >
        Look for the + to save shows you’d like to watch later.
      </TextElement>
    </div>
  );
};
