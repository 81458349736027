import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router';

import { PATHS } from '##/utils/route';
import { EmptyWatchlistPageContent } from '../EmptyWatchlistPageContent';

interface IFallbackLayoutProps {
  isLoggedIn: boolean;
}

export const FallbackLayout: FC<IFallbackLayoutProps> = ({ isLoggedIn }) => {
  let location = useLocation();
  let isWatchlistPage = location.pathname === '/watchlist';

  return (
    <div>
      {isWatchlistPage && (
        <>
          {isLoggedIn ? (
            <EmptyWatchlistPageContent />
          ) : (
            <Redirect to={`watchlist?overlay=${PATHS.SIGN_IN}`} />
          )}
        </>
      )}
    </div>
  );
};
